import React, {
    useEffect,
    useState,
    Fragment,
    useRef,
    forwardRef,
} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Container,
    Typography,
    Box,
    Select,
    MenuItem,
    InputLabel,
    Checkbox,
    CardMedia,
    FormGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Paper,
    Divider,
    TextareaAutosize,
    Grid,
    Modal,
    List,
    OutlinedInput,
    InputAdornment,
} from "@mui/material";
import { useStores } from "../../store";
import notCheck from './delete.svg'
import {MoonLoader} from "react-spinners";
import firebase from "../../services/firebase";
import {Login} from "@mui/icons-material";
import {green, red} from "@mui/material/colors";

const PaymentsModal = ({ paymentsModal, setPaymentsModal, order }) => {
    console.log("paymentsModal", paymentsModal, order);
    const {
        userStore: { user, setUser, changeUserInfo },
        companyInfoStore: { companyInfo },
        ordersStore: { orders, setorders, updateOrders },
        snackbarStore: { setMsg },
    } = useStores();
    console.log('orders', orders)

    const [isLoading, setIsLoading] = useState(false)

    const divStyleBlock = {
        border: "1px solid black",
        height: 40,
        borderRadius: 4,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderColor: "rgb(118, 118, 118)",
    };

    const totalAmount = +(order.deposit || 0) + +(order.payment || 0) + +(order.payment2 || 0)

    const updateField = async (field) => {
        setIsLoading(true)
        try {
            // обновить поле payment в обьекте заказа
            const updatesOrders = orders.map((originOrder) => {
                if (originOrder.uid === order.uid) {
                    return {...originOrder, [field]: !order[field]}
                } else return originOrder
            })
            await firebase.updateDocItem("orders", order.uid, {
                [field]: !order[field] ,
            });

            setorders(updatesOrders)
        } catch (e) {
            setMsg(e.message);
            console.log('ERROR-UPDATE PAYMENTS', e)
        }

        setIsLoading(false)
    }

    return (
        <Dialog
            open={paymentsModal}
            onClose={() =>
                setPaymentsModal(false)
            }
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    PAYMENTS
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <div>Deposit: {order.depositData}</div>
                <Grid container spacing={0} columns={12} sx={{ mb: 1 }}>
                    <Grid item md={3}>
                        <div style={{border: '1px solid black', borderRadius: 5, padding: 3, margin: 3}}>
                            Method: {order.depositMethod}
                        </div>
                    </Grid>
                    <Grid item md={3}>
                        <div style={{border: '1px solid black', borderRadius: 5, padding: 3, margin: 3}}>
                            Description: {order.depositDescription}
                        </div>
                    </Grid>
                    <Grid item md={3}>
                        <div style={{border: '1px solid black', borderRadius: 5, padding: 3, margin: 3}}>
                            Amount: {order.deposit} $
                        </div>
                    </Grid>
                    <Grid item md={3} sx={{display: 'flex'}}>
                        <div
                            onClick={async () => {
                                await updateField('depositIsVerified')
                            }}
                            style={{border: '1px solid black', borderRadius: 5, padding: 3, margin: 3,
                                display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                                backgroundColor: order.depositIsVerified ? green[400] : red[400],
                                width: '100%'
                            }}>
                            {isLoading ? (<MoonLoader color="blue" size={18} />) : null}
                            {order.depositIsVerified && !isLoading ? (<div>Checked</div>) : null}
                            {!order.depositIsVerified && !isLoading ? (<div>Not Checked</div>) : null}
                        </div>

                    </Grid>
                </Grid>


                <div>Payment: {order.paymentMethodData}</div>
                <Grid container spacing={0} columns={12} sx={{ mb: 1 }}>
                    <Grid item md={3}>
                        <div style={{border: '1px solid black', borderRadius: 5, padding: 3, margin: 3}}>
                            Method: {order.paymentMethod}
                        </div>
                    </Grid>
                    <Grid item md={3}>
                        <div style={{border: '1px solid black', borderRadius: 5, padding: 3, margin: 3}}>
                            Description: {order.paymentDescription}
                        </div>
                    </Grid>
                    <Grid item md={3}>
                        <div style={{border: '1px solid black', borderRadius: 5, padding: 3, margin: 3}}>
                            Amount: {order.payment} $
                        </div>
                    </Grid>
                    <Grid item md={3} sx={{display: 'flex'}}>
                        <div
                            onClick={async () => {
                                await updateField('paymentIsVerified')
                            }}
                            style={{border: '1px solid black', borderRadius: 5, padding: 3, margin: 3,
                                display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                                backgroundColor: order.paymentIsVerified ? green[400] : red[400],
                                width: '100%',
                            }}>
                            {isLoading ? (<MoonLoader color="blue" size={18} />) : null}
                            {order.paymentIsVerified && !isLoading ? (<div>Checked</div>) : null}
                            {!order.paymentIsVerified && !isLoading ? (<div>Not Checked</div>) : null}
                        </div>
                    </Grid>
                </Grid>

                <div>Payment2: {order.paymentMethodData2}</div>
                <Grid container spacing={0} columns={12} sx={{ mb: 1 }}>
                    <Grid item md={3}>
                        <div style={{border: '1px solid black', borderRadius: 5, padding: 3, margin: 3}}>
                            Method: {order.paymentMethod2}
                        </div>
                    </Grid>
                    <Grid item md={3}>
                        <div style={{border: '1px solid black', borderRadius: 5, padding: 3, margin: 3}}>
                            Description: {order.paymentDescription2}
                        </div>
                    </Grid>
                    <Grid item md={3}>
                        <div style={{border: '1px solid black', borderRadius: 5, padding: 3, margin: 3}}>
                            Amount: {order.payment2} $
                        </div>
                    </Grid>
                    <Grid item md={3} sx={{display: 'flex'}}>
                        <div
                            onClick={async () => {
                                await updateField('payment2IsVerified')
                            }}
                            style={{border: '1px solid black', borderRadius: 5, padding: 3, margin: 3,
                                display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                                backgroundColor: order.payment2IsVerified ? green[400] : red[400],
                                width: '100%',
                            }}>
                            {isLoading ? (<MoonLoader color="blue" size={18} />) : null}
                            {order.payment2IsVerified && !isLoading ? (<div>Checked</div>) : null}
                            {!order.payment2IsVerified && !isLoading ? (<div>Not Checked</div>) : null}
                        </div>

                    </Grid>
                </Grid>

                <div style={{textAlign: 'center'}}>TOTAL</div>
                <Grid container spacing={0} columns={3} sx={{ mb: 1 }}>
                    <Grid item md={3}>
                        <div style={{border: '1px solid black', borderRadius: 5, padding: 3, margin: 3, textAlign: 'end'}}>
                            {/*{totalAmount} $*/}
                            {order.total} $
                        </div>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setPaymentsModal(false);
                    }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PaymentsModal;
