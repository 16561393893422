// const axios = require('axios');
import axios from 'axios'
import * as AxiosLogger from 'axios-logger';
import fb from '../services/firebase'
// import { removeToken } from '../services/storageService';
// import RNRestart from 'react-native-restart';

// 'https://maps.googleapis.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=AIzaSyAPxV-8hQWQezog3yc0Ix6vFGchwSzoFXk',
const geocodeServer = 'https://maps.googleapis.com/maps/api/geocode';

 // TODO: спрятать ключ
export const apiKey = 'AIzaSyAPxV-8hQWQezog3yc0Ix6vFGchwSzoFXk'

const apiService = axios.create({
  baseURL: geocodeServer,
  responseType: 'json',
  // validateStatus: function (status) {
  //   if (status === 401) {
  //     if (apiService.getToken() !== 'Bearer null') {
  //       removeToken();
  //       apiService.setToken(null);
  //       RNRestart.Restart();
  //     }
  //   }
  //   return status >= 200 && status < 300;
  // },
});

// apiService.interceptors.response.use(AxiosLogger.responseLogger, AxiosLogger.errorLogger);


apiService.interceptors.response.use(
  AxiosLogger.requestLogger,
  AxiosLogger.errorLogger,
);


// apiService.interceptors.require.use(AxiosLogger.requestLogger, AxiosLogger.errorLogger);

// apiService.setToken = function (token) {
//   apiService.defaults.headers.common['Authorization'] = 'Bearer ' + token;
// };

// apiService.getToken = function () {
//   return apiService.defaults.headers.common['Authorization'];
// };

export default apiService;

export const getGeocode = (address) => {
  return apiService.get('/json', {
    params: {
      address,
      key: apiKey,
    },
  });
};

// axios.interceptors.request.use(async function (config) {
//   try {
//     const httpMetric = fb.perf().newHttpMetric(config.url, config.method);
//     config.metadata = { httpMetric };

//     // add any extra metric attributes, if required
//     // httpMetric.putAttribute('userId', '12345678');

//     await httpMetric.start();
//   } finally {
//     console.log('config', config);
//     return config;
//   }
// });

// axios.interceptors.response.use(
//   async function (response) {
//     try {
//       // Request was successful, e.g. HTTP code 200

//       const { httpMetric } = response.config.metadata;

//       // add any extra metric attributes if needed
//       // httpMetric.putAttribute('userId', '12345678');

//       httpMetric.setHttpResponseCode(response.status);
//       httpMetric.setResponseContentType(response.headers['content-type']);
//       await httpMetric.stop();
//     } finally {
//       console.log('response', response);
//       return response;
//     }
//   },
//   async function (error) {
//     try {
//       // Request failed, e.g. HTTP code 500

//       const { httpMetric } = error.config.metadata;

//       // add any extra metric attributes if needed
//       // httpMetric.putAttribute('userId', '12345678');

//       httpMetric.setHttpResponseCode(error.response.status);
//       httpMetric.setResponseContentType(error.response.headers['content-type']);
//       await httpMetric.stop();
//     } finally {
//       console.log('error', error);
//       // Ensure failed requests throw after interception
//       return Promise.reject(error);
//     }
//   },
// );
