import { collection, getDocs, query, where, doc, orderBy, startAt, endAt, } from "firebase/firestore";
import fbServices from "./firebase";

// filterKey, filterValue одиночный фильтр ключ поиск по значению поля
export const queryResolver = async (path, filterKey, filterValue) => {
  const queryRef = collection(fbServices.firestoreDb, path);

  const createQury = (filterKey) => {
    if (typeof filterKey === "object") {
      const argArrayParams = [];

      for (const key in filterKey) {
        argArrayParams.push(where(key, "==", filterKey[key]));
      }

      return query(queryRef, ...argArrayParams);
    } else {
      return filterKey && query(queryRef, where(filterKey, "==", filterValue));
    }
    // const qOrderDayWorker = query(
    //   queryRef,
    //   where("day", "==", startDateNewOrder.getDate()),
    //   where("workerUid", "==", order.workerUid)
    // );
    // const querySnapshot = await getDocs(qOrderDayWorker);
    // const orderDayWorker = [];

    // if (order.workerUid) {
    //   querySnapshot.forEach((doc) => {
    //     const item = doc.data();
    //     orderDayWorker.push({ ...item, uid: doc.id });
    //   });
    // }
  };

  const querySnapshot = await getDocs(createQury(filterKey) || queryRef);

  const result = [];

  querySnapshot.forEach((doc) => {
    const item = doc.data();
    result.push({ ...item, uid: item.uid ? item.uid : doc.id });
  });

  return result;
};

export const queryMultyResolver = async (path, options) => {
  const queryRef = collection(fbServices.firestoreDb, path);
  let queryConstraints = [];
  options.map((el) => {
    queryConstraints.push(where(el.field, el.operator, el.value));
  });

  const querySnapshot = await getDocs(query(queryRef, ...queryConstraints));
  const result = [];

  querySnapshot.forEach((doc) => {
    const item = doc.data();
    result.push({ ...item, uid: item.uid ? item.uid : doc.id });
  });

  return result;
};
