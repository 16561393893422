import { useEffect, useState, Fragment, useRef, forwardRef } from "react";
import { Paper, Grid, Box, Divider } from "@mui/material";
import WorkerAvatar from "./WorkerAvatar";
import WorkingItem from "./WorkingItem";
import { TimesLine, lineHeight, widthItem } from "./index";
import { useStores } from "../../store";
import { useLocation } from "react-router-dom";
import { pink, red, green, grey } from "@mui/material/colors";
import { cloneDeep, isEqual } from "lodash";

const WorkerLine = (props) => {
  const {
    userStore: { user, setUser, changeUserInfo },
    snackbarStore: { open, msg, setMsg, active, desactive },
    // workersStore: { workers, setWorkers },
    ordersStore: { orders, setorders, updateOrders },
    managersStore: { managers, setManagersStore },
    techniciansStore: { technicians, setTechniciansStore },
    companyInfoStore: { companyInfo },
  } = useStores();

  const curentLocation = useLocation();

  const { worker, workOrders, isPeriodLine } = props;

  const [ordersLine, setOrdersLine] = useState({});

  useEffect(() => {
    const workOrderLine = companyInfo.timesLine.reduce(
      (a, v) => ({ ...a, [v]: v }),
      {}
    );

    workOrders.forEach((item) => {
      if (Array.isArray(item.timeLine)) {
        item.timeLine.forEach((time) => {
          workOrderLine[time] = item;
        });
      } else {
        workOrderLine[item.timeLine] = item;
      }
    });

    if (isPeriodLine) {
        setOrdersLine(workOrders)
    } else {
        setOrdersLine(workOrderLine);
    }
  }, [workOrders]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
      <Box
        sx={{
          maxWidth: 170,
          minWidth: 170,
          height: widthItem,
        }}
      >
        <WorkerAvatar worker={worker} />
      </Box>
      {isPeriodLine ? (<div style={{minWidth: widthItem * 2}} />) : null}
      {companyInfo.timesLine.map((el, index) => {
        return (
          <Grid
            sx={{
              borderRadius: 2,
              // width: widthItem,
              // background: 'red',
            }}
            key={index}
            item
            xs={typeof el !== "string" ? el.timeLine.length : 1}
          >
            {!!Object.keys(ordersLine).length && (
              <WorkingItem
                timeline={el}
                orderView={ordersLine[el]}
                {...props}
              />
            )}
          </Grid>
        );
      })}
      {curentLocation.pathname.includes("payments") ? (
        <PaymentsResultLine ordersLine={ordersLine} worker={worker} isPeriodLine={isPeriodLine} />
      ) : null}
      {/*{curentLocation.pathname.includes("parts") ? (*/}
      {/*  <PaymentsResultLine ordersLine={ordersLine} isParts />*/}
      {/*) : null}*/}
      <Divider />
    </Box>
  );
};

export default WorkerLine;

const PaymentsResultLine = ({ ordersLine, isParts, worker, isPeriodLine }) => {
    const {
        companyInfoStore: { companyInfo },
    } = useStores();

  const lineOrders = Object.values(ordersLine).filter((orderValue) => typeof orderValue !== "string")
        .filter((obj, index, self) => index === self.findIndex((o) => o.number === obj.number))

  const total = lineOrders.reduce((acc, order) => {
    if (typeof order !== "string" && isParts ? (order.priceParts || 0) : order.total) {
      return (acc = +(isParts ? (order.priceParts || 0) : order.total) + acc);
    } else return acc;
  }, 0);

    const masterPaymentOrder = () => {
        return  lineOrders.reduce((acc, order) => {
            const partsAmountSale = order.priceParts?.reduce((acc, itemPart) => {
                return acc + (+itemPart.price || 0)
            }, 0) || 0;
            const techPercent = companyInfo && companyInfo.workersData && companyInfo.workersData[order.workerUid]?.salary;
            const masterParts = order.priceParts?.reduce((acc, itemPart) => {
                if (itemPart.isTech) {
                    return acc + (+itemPart.price || 0)
                } else {
                    return acc
                }
                return 0
            }, 0) || 0;
            // (тотал - зч мастера закупка - зч компании закупка) * (%техника/100) + зч закупка тхника
            const masterPaymentOrder = (((order.total || 0) - partsAmountSale) * ((+techPercent || 0) /100)) + masterParts
            return acc + Math.round(masterPaymentOrder)
        }, 0)
    }

    const amountHandler = (method) => {
        return lineOrders.reduce((acc, order) => {
            if (order.depositMethod === method) {
                return acc + +(order.deposit)
            }
            if (order.paymentMethod === method) {
                return acc + +(order.payment)
            }
            if (order.paymentMethod2 === method) {
                return acc + +(order.payment2)
            }
            return acc
        }, 0)
    }

  const itemStile = {
      maxWidth: widthItem,
      minWidth: widthItem,
      height: widthItem,
      backgroundColor: "#F2f2f2",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      border: 1,
      borderColor: grey[300],
      borderRadius: 2,
      textAlign: 'center',
  }

  const techPercent = companyInfo && companyInfo.workersData && companyInfo.workersData[worker.uid]?.salary || 40;

  return (
      <>
          <Box sx={itemStile}>
              <Box>Cash:</Box>
              <Box>{amountHandler('Cash')?.toFixed(2) || 0} $</Box>
          </Box>
          <Box sx={itemStile}>
              <Box>Web payment:</Box>
              <Box>{amountHandler('WebPayment')?.toFixed(2) || 0} $</Box>
          </Box>
          <Box sx={itemStile}>
              <Box>Zelle:</Box>
              <Box>{amountHandler('Zelle')?.toFixed(2) || 0} $</Box>
          </Box>
          <Box sx={itemStile}>
              <Box>Card:</Box>
              <Box>{amountHandler('Card')?.toFixed(2) || 0} $</Box>
          </Box>
          <Box sx={itemStile}>
              <Box>Check:</Box>
              <Box>{amountHandler('Check')?.toFixed(2) || 0} $</Box>
          </Box>
          <Box sx={itemStile}>
              <Box>Tech: {techPercent} %</Box>
              <Box>{masterPaymentOrder()} $</Box>
          </Box>

          <Box sx={itemStile}>
              <Box>TOTAL:</Box>
              <Box>{total?.toFixed(2) || 0} $</Box>
          </Box>
      </>
  );
};
