import { makeAutoObservable } from "mobx";

class CompanyInfoStore {
  companyInfo = {};

  constructor() {
    makeAutoObservable(this);
  }

  setCompanyInfoStore = (data) => {
    this.companyInfo = data;
  };
  updateCompanyInfoFieldStore = (key, value) => {
    this.companyInfo[key] = value
  }
  updateCompanyInfoBlock = (newBlock) => {
    this.companyInfo.infoBlock = {...(this.companyInfo?.infoBlock || {}), [newBlock.name]: newBlock}
  }
}

export default CompanyInfoStore;