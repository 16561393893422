import { useEffect, useState, Fragment, useRef, forwardRef } from "react";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import {
  collection,
  addDoc,
  doc,
  getDocs,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { useStores } from "../../store";
import fbServices from "../../services/firebase";
import LoadingComponent from "../../Common/LoadingComponent";
import DatePicker, { format } from "react-datepicker";
// import MyDatePicker from "./MyDatePicker";
// import WorkerAvatar from "./WorkerAvatar";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import IconButton from "@mui/material/IconButton";
// import WorkingItem from "./WorkingItem";
import { queryResolver } from "../../services/queryResolver";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
// import WorkerLine from "./WorkerLine";
// import AddOrderDialog from "./AddOrderDialog";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import useLocalStorage from "../../hooks/useLocalStorageHook";
import { pink, red, green, blue, grey, brown } from "@mui/material/colors";

import "react-datepicker/dist/react-datepicker.css";


const Payments = observer(() => {
  const {
    userStore: { user, setUser, changeUserInfo },
    snackbarStore: { open, msg, setMsg, active, desactive },
    workersStore: { workers, setWorkers },
    ordersStore: { orders, setorders, updateOrders },
  } = useStores();


  if (!workers.length) {
    return (
      <Paper
        sx={{
          // p: 2,
          display: "flex",
          flexDirection: "column",
          height: 240,
          // overflow: "hidden",
        }}
      >
        {' '}
      </Paper>
    );
  }

  return (
    <Box sx={{ m: 1, mt: 9 }}>123</Box>
  );
});

export default Payments;
