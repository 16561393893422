import { Box, Dialog, DialogContent, DialogTitle, Grid, Paper, Typography } from "@mui/material"
import { green, red } from "@mui/material/colors"
import { useState } from "react"
import { useStores } from "../../store"
import fbServices from "../../services/firebase";
import { dataBaseName } from "../../constants";
import { MoonLoader } from "react-spinners";

export const ManagerTechFilter = ({ managerItem }) => {

    const [isOpenDialog, setOpenDialog] = useState(false)

    const handleOpenTechList = () => setOpenDialog(true)

    return (
        <Grid item md={4}>
            <Paper
                onClick={handleOpenTechList}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    background: green[400],
                    cursor: 'pointer'
                }}
            >
                <Typography
                    sx={{ m: 1, fontSize: 13 }}
                    gutterBottom
                    variant="button"
                    component="div"
                >
                    TECH FILTER
                </Typography>
            </Paper>
            <TechList isOpenDialog={isOpenDialog} setOpenDialog={setOpenDialog} managerItem={managerItem} />
        </Grid>
    )
}

const TechList = ({ isOpenDialog, setOpenDialog, managerItem }) => {
    // console.log('managerItem', managerItem.uid);

    const {
        userStore: { user, setUser, changeUserInfo },
        snackbarStore: { open, msg, setMsg, active, desactive },
        // initScreenStore: { setInit, init },
        companyInfoStore: { companyInfo, setCompanyInfoStore },
        techniciansStore: { technicians, setTechniciansStore },
    } = useStores()

    const [activeTechniciansList, setActiveTechniciansList] = useState(companyInfo.workersData[managerItem.uid]?.techniciansList || [])
    const [isLoading, setIsLoading] = useState(false)

    const handleSetTech = async (technicianList) => {
        try {
            await fbServices.updateDocItem("companies", companyInfo.company, {
                [`workersData.${managerItem.uid}.techniciansList`]: technicianList
            });
            await updateCompanyInfo();
        } catch (error) {
            console.log('ERROR-handleSetTech', error);
        }
    }

    const updateCompanyInfo = async () => {
        const companyInfoSnap = await fbServices.getDocItem(
            dataBaseName.COMPANIES,
            companyInfo.company,
        );
        setCompanyInfoStore(companyInfoSnap)
    }

    return <Dialog
        open={isOpenDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth={true}
        maxWidth={"md"}
    >
        <DialogTitle>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                Technitian visibility for this manager
            </Box>
        </DialogTitle>
        <DialogContent
            // sx={{minHeight: '200px'}} 
            dividers>
            {technicians.map((technician) => {
                return (
                    <Box
                        key={technician.displayName || technician.email}
                        sx={{ display: 'flex', border: 1, borderRadius: 1, p: 1, m: 1, justifyContent: 'space-between' }}>
                        <Typography
                            sx={{ m: 1, fontSize: 13 }}
                            gutterBottom
                            variant="button"
                            component="div"
                        >
                            {technician.displayName || technician.email}
                        </Typography>
                        <Paper
                            onClick={async () => {
                                // handleSetTech(technician)
                                setIsLoading(true)
                                let newActiveTechList
                                if (activeTechniciansList.includes(technician.uid)) {
                                    newActiveTechList = activeTechniciansList.filter((item) => item !== technician.uid)
                                    setActiveTechniciansList(newActiveTechList)
                                } else {
                                    newActiveTechList = [...activeTechniciansList, technician.uid]
                                    setActiveTechniciansList(newActiveTechList)
                                }

                                try {
                                    await handleSetTech(newActiveTechList)
                                } catch (error) {
                                    console.log('ERROR-handleSetTech', error);
                                }
                                setIsLoading(false)
                            }}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                // background: red[400],
                                cursor: 'pointer',
                                minWidth: 150,
                                background: activeTechniciansList.includes(technician.uid) ? green[400] : red[400],
                            }}
                        >
                            {isLoading ? (<MoonLoader size={17} />) : (
                                <Typography
                                    sx={{ m: 1, fontSize: 13, pr: 2, pl: 2, textAlign: 'center' }}
                                    gutterBottom
                                    variant="button"
                                    component="div"
                                >
                                    {activeTechniciansList.includes(technician.uid) ? 'visible' : 'not visible'}

                                </Typography>
                            )}
                        </Paper>
                    </Box>
                )
            })}
        </DialogContent>
    </Dialog>
}