import React, {
    useEffect,
    useState,
    Fragment,
    useRef,
    forwardRef,
  } from "react";
  import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Container,
    Typography,
    Box,
    Select,
    MenuItem,
    InputLabel,
    Checkbox,
    CardMedia,
    FormGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Paper,
    Divider,
    TextareaAutosize,
    Grid,
    Modal,
    List,
    OutlinedInput,
    InputAdornment,
  } from "@mui/material";
  import IconButton from "@mui/material/IconButton";
  import SearchIcon from "@mui/icons-material/Search";
  import firebase from "../../services/firebase";
  import LoadingButton from "@mui/lab/LoadingButton";
  import { useStores } from "../../store";
  import { TimesLine } from "./index";
  import { getGeocode } from "../../services/apiService";
  import {
    collection,
    addDoc,
    doc,
    getDocs,
    getDoc,
    deleteDoc,
    updateDoc,
    setDoc,
    query,
    where,
    arrayUnion,
  } from "firebase/firestore";
  import fbServices from "../../services/firebase";
  import MyDatePicker from "./MyDatePicker";
  // import { async } from "@firebase/util";
  import { queryResolver } from "../../services/queryResolver";
  import { pink, red, green, blue, grey, brown } from "@mui/material/colors";
  import { customAlphabet } from "nanoid";
  import { orderStatuses } from "../../constants";
  import { useLocation } from "react-router-dom";
  import moment from "moment";

const SelectionDialog = ({
    searchRsault,
    isOpenSelectionDialog,
    setOpenSelectionDialog,
    setSearchItem,
  }) => {
    return (
      <Dialog
        open={isOpenSelectionDialog}
        onClose={() => setOpenSelectionDialog(false)}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "center" }}>Search</Box>
        </DialogTitle>
        <DialogContent dividers>
          {searchRsault.length ? (
            <Box sx={{ border: 1, borderRadius: 3, mt: 3, p: 2 }}>
              {searchRsault.map((searchItem) => {
                return (
                  <React.Fragment key={searchItem.number}>
                    <Box
                      sx={{
                        background: "#f1f1f1",
                        display: "flex",
                        justifyContent: "space-between",
                        "&:hover": {
                          background: "grey",
                        },
                      }}
                      onClick={() => {
                        setSearchItem(searchItem);
                        setOpenSelectionDialog(false);
                      }}
                    >
                      <Box>{searchItem.number}</Box>
                      <Box>{searchItem.brend}</Box>
                      <Box>{searchItem.type}</Box>
                      <Box>
                        {moment(searchItem.dateCreate.toDate()).format(
                          "MMM D YYYY"
                        )}
                      </Box>
                    </Box>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSelectionDialog(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  };

  export default SelectionDialog