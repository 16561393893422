import { makeAutoObservable } from "mobx";

class ManagersStore {
  managers = [];

  constructor() {
    makeAutoObservable(this);
  }

  setManagersStore = (data) => {
    this.managers = data;
  };
}

export default ManagersStore;