import { useEffect, useState, Fragment, useRef, forwardRef } from "react";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Container,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  TextareaAutosize,
  Switch,
  Stack,
  styled,
  FormControl,
} from "@mui/material";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import IconButton from "@mui/material/IconButton";
import { useStores } from "../../store";
import fbServices from "../../services/firebase";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  getDocs,
  getDoc,
} from "firebase/firestore";
import LoadingButton from "@mui/lab/LoadingButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import LoadingComponent from "../../Common/LoadingComponent";
import CommentIcon from "@mui/icons-material/Comment";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { dataBaseName, priorityList } from "../../constants";
import { queryResolver } from "../../services/queryResolver";
import { getCompanyWorkers } from "../../services/getCompanyWorkersService";
import firebase from "../../services/firebase";
import { observer } from "mobx-react-lite";

const Wiki = observer((props) => {
  console.log("Wiki", props);
  const {
    userStore: { user, setUser, changeUserInfo },
    snackbarStore: { open, msg, setMsg, active, desactive },
    companyInfoStore: {
      companyInfo,
      setCompanyInfoStore,
      updateCompanyInfoBlock,
    },
    managersStore: { managers, setManagersStore },
    techniciansStore: {
      technicians,
      setTechniciansStore,
      addTechnic,
      removeTechnic,
    },
  } = useStores();
  console.log('companyInfo', companyInfo.infoBlock);
  const [isDropDown, setIsDropDown] = useState(false);

  let infoBlockArr = [];
  const lowBlock = []
    const mediumBlock = []
    const highBlock = []
    
  for (const key in companyInfo.infoBlock) {
    console.log('key', key, companyInfo.infoBlock[key]);
    

    if (companyInfo.infoBlock[key].priority === priorityList.HIGH) {
      highBlock.push(companyInfo.infoBlock[key])
    } else if(companyInfo.infoBlock[key].priority === priorityList.MEDIUM) {
      mediumBlock.push(companyInfo.infoBlock[key])
    } else if(companyInfo.infoBlock[key].priority === priorityList.LOW) {
      lowBlock.push(companyInfo.infoBlock[key])
    }
    
  }
  infoBlockArr = [...highBlock, ...mediumBlock, ...lowBlock]
  console.log('infoBlockArr', infoBlockArr);

  return (
    <Box sx={{ mt: 10 }}>
      <Container maxWidth="lg">
        <Paper sx={{ p: 1 }}>
          <Typography sx={{ m: 2 }} gutterBottom variant="h4" component="div">
            DATA BASE
          </Typography>
          <Box
            sx={{
              border: 1,
              borderColor: "black",
              borderRadius: 2,
              pl: 1,
              pr: 1,
              m: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={() => setIsDropDown(!isDropDown)}
            >
              <Typography variant="subtitle2">+ Info Block</Typography>
              {isDropDown ? (
                <ExpandLessIcon sx={{ fontSize: 30, mr: 1 }} />
              ) : (
                <ExpandMoreIcon sx={{ fontSize: 30, mr: 1 }} />
              )}
            </Box>

            {isDropDown ? <InfoBlock /> : null}
          </Box>
          {infoBlockArr.length &&
            infoBlockArr.map((blok) => {
              return <InfoBlockItem data={companyInfo.infoBlock[blok.name]} />;
            })}
        </Paper>
      </Container>
    </Box>
  );
});

export default Wiki;

const InfoBlock = (props) => {
  const {
    userStore: { user, setUser, changeUserInfo },
    snackbarStore: { open, msg, setMsg, active, desactive },
    companyInfoStore: {
      companyInfo,
      setCompanyInfoStore,
      updateCompanyInfoBlock,
      updateCompanyInfoFieldStore,
    },
    managersStore: { managers, setManagersStore },
    techniciansStore: {
      technicians,
      setTechniciansStore,
      addTechnic,
      removeTechnic,
    },
  } = useStores();
  console.log("companyInfo.infoBlock", companyInfo.infoBlock);

  const [newBlock, setNewBlock] = useState({
    name: "",
    description: "",
    isOn: true,
    priority: priorityList.MEDIUM,
    create: new Date(),
    photos: [],
    ...(props.data || {}),
  });
  console.log("newBlock", newBlock);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e, field) => {
    setNewBlock({ ...newBlock, [field]: e.target.value });
  };

  return (
    <Box sx={{ mb: 1, mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          {!props.data ? (
            <TextField
            fullWidth
            disabled={!!props.data}
            // id={field.value}
            label={"Name"}
            variant="outlined"
            size="small"
            value={newBlock.name}
            onChange={(e) => handleChange(e, "name")}
          />
          ) : null}
          
        </Grid>
        <Grid item md={2}>
          <FormControl fullWidth size="small">
            <InputLabel id="Priority-select-label">Priority</InputLabel>
            <Select
              fullWidth
              labelId="Priority-select-label"
              // id="worker-select"
              value={newBlock.priority}
              label="Priority"
              onChange={(e) => handleChange(e, "priority")}
              // disabled={isDisabled}
            >
              <MenuItem value={undefined} disabled>
                <em>Priority</em>
              </MenuItem>
              {Object.values(priorityList).map((priority) => {
                return (
                  <MenuItem key={priority} value={priority}>
                    {priority}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Off</Typography>
            <AntSwitch
              checked={newBlock.isOn}
              inputProps={{ "aria-label": "ant design" }}
              onChange={(e) => {
                setNewBlock({ ...newBlock, isOn: e.target.checked });
              }}
            />
            <Typography>On</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            InputProps={{
              inputComponent: TextareaAutosize,
              minRows: 3,
            }}
            // id={field.value}
            label={"Description"}
            variant="outlined"
            size="small"
            value={newBlock.description}
            onChange={(e) => handleChange(e, "description")}
          />
        </Grid>

        <Grid item xs={6}>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            fullWidth
            sx={{ my: 1 }}
            onClick={async () => {
              if (props.data) {
                // сделать удаление
                delete companyInfo.infoBlock[newBlock.name];

                props.setIsDropDown(!props.isDropDown);

                await firebase.updateDocItem("companies", companyInfo.company, {
                  infoBlock: companyInfo.infoBlock,
                });
                updateCompanyInfoFieldStore("infoBlock", companyInfo.infoBlock);
              } else {
                setNewBlock({
                  name: "",
                  description: "",
                  isOn: true,
                  priority: priorityList.MEDIUM,
                  create: new Date(),
                  photos: [],
                });
              }
            }}
          >
            {props.data ? "Delete" : "Clear"}
          </LoadingButton>
        </Grid>
        <Grid item xs={6}>
          <LoadingButton
            loading={isLoading}
            disabled={!newBlock.name || !newBlock.description}
            onClick={async () => {
              setIsLoading(true);

              try {
                await firebase.updateDocItem("companies", companyInfo.company, {
                  [`infoBlock.${newBlock.name}`]: newBlock,
                });
                updateCompanyInfoBlock(newBlock);
                props.isDropDown && props.setIsDropDown(!props.isDropDown);
              } catch (error) {
                console.log("getManager-error", error.message, setMsg);
                setMsg(error.message);
              } finally {
                setIsLoading(false);
                !props.data &&
                  setNewBlock({
                    name: "",
                    description: "",
                    isOn: true,
                    priority: priorityList.MEDIUM,
                    create: new Date(),
                    photos: [],
                  });
              }
            }}
            variant="contained"
            fullWidth
            sx={{ my: 1 }}
          >
            {props.data ? "Update Info Block" : "Add Info Block"}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

const InfoBlockItem = (props) => {
  console.log("InfoBlockItem-props", props);
  const [isDropDown, setIsDropDown] = useState(false);
  return (
    <Box>
      <Box
        sx={{
          border: 1,
          borderColor: "black",
          borderRadius: 2,
          pl: 1,
          pr: 1,
          m: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onClick={() => setIsDropDown(!isDropDown)}
        >
          <Typography variant="subtitle2">{props.data.name}</Typography>
          <Box sx={{ display: "flex" }}>
            {/* <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Off</Typography>
            <AntSwitch
              checked={props.data.isOn}
              inputProps={{ "aria-label": "ant design" }}
              onChange={(e) => {
                // setNewBlock({ ...newBlock, isOn: e.target.checked });
              }}
            />
            <Typography>On</Typography>
          </Stack> */}
            {isDropDown ? (
              <ExpandLessIcon sx={{ fontSize: 30, mr: 1 }} />
            ) : (
              <ExpandMoreIcon sx={{ fontSize: 30, mr: 1 }} />
            )}
          </Box>
        </Box>

        {isDropDown ? (
          <InfoBlock
            {...props}
            setIsDropDown={setIsDropDown}
            isDropDown={isDropDown}
          />
        ) : null}
      </Box>
    </Box>
  );
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
