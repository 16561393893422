import { useState } from "react";
import {
    Paper,
    Typography,
} from "@mui/material";
import { useStores } from "../../store";
import fbServices from "../../services/firebase";
import { dataBaseName } from "../../constants";
import firebase from "../../services/firebase";
import {MoonLoader} from "react-spinners";
import {red, green, grey} from "@mui/material/colors";

const ManagerSettingItem = ({manager, title, linkName, isDisable}) => {
    const {
        companyInfoStore: { companyInfo, setCompanyInfoStore },
    } = useStores();

    const [isLoading, setIsLoading] = useState(false)

    const navLinkIsShow = (linkName) => {
        return companyInfo.workersData
            && companyInfo.workersData[manager.uid]
            && companyInfo.workersData[manager.uid][linkName]
    }

    const handleHideNavLink = async (linkName, value) => {
        await firebase.updateDocItem("companies", companyInfo.company, {
            [`workersData.${manager.uid}.${linkName}`]: value
        });
        await updateCompanyInfo();
    }

    const updateCompanyInfo = async () => {
        const companyInfoSnap = await fbServices.getDocItem(
            dataBaseName.COMPANIES,
            companyInfo.company,
        );
        setCompanyInfoStore(companyInfoSnap)
    }

    return (
        <Paper
            onClick={async () => {
                if (isDisable) return
                setIsLoading(true)
                try {
                    await handleHideNavLink(linkName, !navLinkIsShow(linkName))
                } catch (e) {
                    console.log('ERROR-handleHideNavLink', e)
                }
                setIsLoading(false)
            }}
            sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                cursor: 'pointer',
                background: isDisable ? grey[300] : navLinkIsShow(linkName) ? red[400] : green[400],
                minWidth: 137,
            }}
        >
            <Typography
                sx={{ m: 1, fontSize: 13 }}
                gutterBottom
                variant="button"
                component="div"
            >
                {title} :
            </Typography>
            {isLoading ? (<MoonLoader size={20} />) : (
                <Typography
                    sx={{m: 1, fontSize: 13}}
                    gutterBottom
                    variant="button"
                    component="div"
                >
                    {isDisable ? 'DISABLE' : navLinkIsShow(linkName) ? 'OFF' : 'ON'}
                </Typography>
            )}
        </Paper>
    )
}

export default ManagerSettingItem;
