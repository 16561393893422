import React, { useEffect, useState, Fragment, useRef, forwardRef } from "react";
import {
    Button,
    Paper,
    Grid,
    Typography,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Container,
    TextField, InputLabel, Select, MenuItem, FormControl,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { observer } from "mobx-react-lite";
import {
    collection,
    addDoc,
    doc,
    getDocs,
    getDoc,
    query,
    where,
} from "firebase/firestore";
import { useStores } from "../../store";
import fbServices from "../../services/firebase";
import LoadingComponent from "../../Common/LoadingComponent";
import DatePicker, { format } from "react-datepicker";
import MyDatePicker from "../AddOrder/MyDatePicker";
import WorkerAvatar from "./WorkerAvatar";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import IconButton from "@mui/material/IconButton";
import WorkingItem from "./WorkingItem";
import {
    // filterDoc,
    // getMoreDate,
    queryMultyResolver,
    queryResolver,
} from "../../services/queryResolver";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import WorkerLine from "./WorkerLine";
import AddOrderDialog from "../AddOrder";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import useLocalStorage from "../../hooks/useLocalStorageHook";
import { pink, red, green, blue, grey, brown } from "@mui/material/colors";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { getNotWorkersOrders } from "../../services/getNotWorkersOrders";
import {widthItem} from "./index";
// import PaymentsPeriodLine from "./PaymentsPeriodLine";

const WorkersHeader = ({
    startDate,
    setStartDate,
    line,
    addDayToCurrentDate,
}) => {
    // console.log('WorkersHeader', startDate)
    const {
        companyInfoStore: { companyInfo, setCompanyInfoStore },
    } = useStores();

    return (
        <Box sx={{ display: "flex" }}>
            <Box sx={{ px: 1, minWidth: 170 }}>
                {line === 1 && (
                    <MyDatePicker
                        startDate={new Date(startDate)}
                        setStartDate={setStartDate}
                    />
                )}

                {line !== 1 && (
                    <Paper sx={{ textAlign: "center", backgroundColor: brown[200] }}>
                        {addDayToCurrentDate(line - 1).toLocaleDateString("en-us", {

                            month: 'numeric',
                            day: "numeric",
                            year: 'numeric',

                            weekday: 'short',
                        })}
                    </Paper>
                )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                {companyInfo.timesLine.map((time, index) => {
                    return (
                        <Box key={index} sx={{ width: widthItem }}>
                            <Paper
                                sx={{
                                    textAlign: "center",
                                    backgroundColor: brown[200],
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Box>{time?.replace("50", "30")}</Box>
                            </Paper>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

export default WorkersHeader;
