import fbServices from "./firebase";
import moment from "moment";
import {queryMultyResolver} from "./queryResolver";
import {stores} from "../store";

export const getCompanyOrders = async ({startDate}) => {
    const companyInfo = stores.companyInfoStore.companyInfo
    const setorders = stores.ordersStore.setorders

    // const dayShowArray = Array.from({ length: dayCount }, (v, k) => {
    //     const curentDay = addDayToCurrentDate(k + 1);
    //     return curentDay;
    // });
    // setDayShowArray(dayShowArray);

    const companyOrders = [];
    const startShowDay = fbServices.Timestamp.fromDate(
        new Date(
            moment(startDate)
                .subtract(11, "days")
                .startOf("day")
                .format("YYYY-MM-DD")
        )
    );
    // все заказы от указанного дня и старше
    const options = [
        {
            field: "dateCreate",
            operator: ">",
            value: startShowDay,
        },
        {
            field: "company",
            operator: "==",
            value: companyInfo.company,
        },
    ];

    const orders = await queryMultyResolver(`orders`, options);
    companyOrders.push(...orders);
    setorders(companyOrders);
};
