import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import {
  getFirestore,
  connectFirestoreEmulator,
  getDoc,
  getDocs,
  collection,
  doc,
  setDoc,
  updateDoc,
  Timestamp,
  deleteField,
  collectionGroup,
  addDoc,
  onSnapshot,
  deleteDoc,
  where,
  query,
} from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
// import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyC2WMzcWK6D7r9vrgjWrTVFV2yH7XKdfio",
  authDomain: "myjobs-b7f54.firebaseapp.com",
  projectId: "myjobs-b7f54",
  storageBucket: "myjobs-b7f54.appspot.com",
  messagingSenderId: "90295824857",
  appId: "1:90295824857:web:a4d90591dd4160dfc169c8",
  measurementId: "G-PPY26WMGHB",
};
// firebase.analytics();

const app = initializeApp(firebaseConfig);

const auth = getAuth();
const firestoreDb = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);
// const perf = getPerformance(app);

// TODO: убрать перед deploy
// connectAuthEmulator(auth, "http://localhost:9099");
// connectFunctionsEmulator(functions, "localhost", 5001);
// connectFirestoreEmulator(firestoreDb, 'localhost', 8080);

const createUser = httpsCallable(functions, "api/createUser");

const getAllUsers = httpsCallable(functions, "api/getAllUsers");
const getCompanyUsers = httpsCallable(functions, "api/getCompanyUsers");

const createNewAdmin = httpsCallable(functions, "api/createNewAdmin");

const createSystemUser = httpsCallable(functions, "createSystemUser");

const createCompany = httpsCallable(functions, "createCompany");

const sendPush = httpsCallable(functions, "sendPush");

export default {
  signInWithEmailAndPassword: function (email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  },
  onAuthStateChanged: function (callback) {
    return onAuthStateChanged(auth, callback);
  },
  signOut: function () {
    return signOut(auth);
  },
  getCollection: async (collectionItem) => {
    const queryRef = collection(firestoreDb, collectionItem);
    const querySnapshot = await getDocs(queryRef);
    // console.log('querySnapshot', querySnapshot)
    // const snapshot = await firebase.firestore().collection(collection).get()
    return querySnapshot.docs.map(doc => doc.data());
  },
  getDocItem: async (path, key) => {
    const docRef = doc(firestoreDb, path, key);
    const value = await getDoc(docRef);
    // console.log("getDocItem", value);
    return value.data();
  },
  getItemInCollection: async (path, searchKey, searchValue) => {
    const queryRef = collection(firestoreDb, path);

      const qOrderDayWorker = query(
        queryRef,
        where(searchKey, "==", searchValue))

      const querySnapshot = await getDocs(qOrderDayWorker);
      const items = []
      querySnapshot.forEach((order) => {
        if (order.exists()) {
          items.push({...order.data(), id: order.id})
        }
      })
    return items
  },
  getInerCollection: async (path, parentKey, key) => {
    const parentCollectionRef = collection(firestoreDb, path);
    const parentDocumentRef = doc(parentCollectionRef, parentKey);
    const nestedCollectionRef = collection(parentDocumentRef, key);

    const value = await getDocs(nestedCollectionRef);

    const dataArray = [];

    value.forEach((doc) => {
      // console.log(doc.id, " => ", doc.data());
      const item = doc.data();
      dataArray.push({ id: doc.id, ...item });
    });
    // console.log("getDocItem", value);
    return dataArray;
  },
  setDocItem: async (path, key, item) => {
    const docRef = doc(firestoreDb, path, key);
    await setDoc(docRef, item);
  },
  setInerCollection: async (path, parentKey, key, item) => {
    const parentCollectionRef = collection(firestoreDb, path);
    const parentDocumentRef = doc(parentCollectionRef, parentKey);
    const nestedCollectionRef = collection(parentDocumentRef, key);

    await addDoc(nestedCollectionRef, item);
  },
  updateDocItem: async (path, key, item) => {
    const docRef = doc(firestoreDb, path, key);
    await updateDoc(docRef, item);
  },
  updateInerItem: async (path, parentKey, key, item) => {
    // console.log('path, parentKey, key, item', path, parentKey, key, item);
    const parentCollectionRef = collection(firestoreDb, path);
    const parentDocumentRef = doc(parentCollectionRef, parentKey);
    const nestedCollectionRef = collection(parentDocumentRef, key);
    const nestedDocumentRef = doc(nestedCollectionRef, item.id);
    // console.log('nestedDocumentRef', nestedDocumentRef);

    // await addDoc(nestedCollectionRef, item);
    await updateDoc(nestedDocumentRef, item);
  },
  deleteInerItem: async (path, parentKey, key, item) => {
    const parentCollectionRef = collection(firestoreDb, path);
    const parentDocumentRef = doc(parentCollectionRef, parentKey);
    const nestedCollectionRef = collection(parentDocumentRef, key);
    const nestedDocumentRef = doc(nestedCollectionRef, item.id);

    await deleteDoc(nestedDocumentRef)
  },
  // deleteDocField: async (path, key, field) => {
  //   const docRef = doc(firestoreDb, path, key);
  //   await updateDoc(docRef, {[field]: deleteField()});
  // },
  // auth,
  createUser,
  getAllUsers,
  firestoreDb,
  createNewAdmin,
  getCompanyUsers,
  storage,
  createUserWithEmailAndPassword: function (email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  },
  createSystemUser,
  createCompany,
  // perf,
  Timestamp,
  sendPush,
};
