import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from "@mui/lab/LoadingButton";
import {useState} from "react";

export default function AlertDialog({close, action, isOpen}) {
    const [isLoading, setIsLoading] = useState(false)

    return (
            <Dialog
                open={isOpen}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete an order ?"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={close}>NO</Button>
                    {/*{isLoading ? () : ()}*/}
                    {/*<Button onClick={action} autoFocus>*/}
                    {/*    YES*/}
                    {/*</Button>*/}
                    <LoadingButton
                        loading={isLoading}
                        onClick={async () => {
                            setIsLoading(true)
                            try {
                                await action()
                            } catch (e) {
                                console.log('ERROR-action', e)
                            }
                            setIsLoading(false)
                        }}
                    >YES</LoadingButton>
                </DialogActions>
            </Dialog>
    );
}
