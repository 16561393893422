import { useEffect, useState, Fragment, useRef, forwardRef } from "react";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { useStores } from "../../store";
import fbServices from "../../services/firebase";
import {
  collection,
  doc,
  setDoc,
} from "firebase/firestore";
import LoadingButton from "@mui/lab/LoadingButton";
import { times, times30 } from "../Auth/SignUp";
import { blue } from '@mui/material/colors';
import ManagerSettingItem from './ManagerSettingItem';
import firebase from "../../services/firebase";
import { ZipCodesField } from "./ZipCodesField";
import { red, green, grey } from "@mui/material/colors";
import { JobFilters } from "./JobFilters";

const CompanyInfo = () => {
  const {
    userStore: { user, setUser, changeUserInfo },
    snackbarStore: { open, msg, setMsg, active, desactive },
    // initScreenStore: { setInit, init },
    companyInfoStore: { companyInfo, setCompanyInfoStore },
  } = useStores();

  // console.log("companyInfo", companyInfo);

  const [isOpenJobFilters, setOpenJobFilters] = useState(false);
  const [companySettings, setCompanySettings] = useState({
    ...companyInfo,
  });
  const [isLoading, setIsLoading] = useState(false);

  // TODO: настроить подписку на обьект профиля компании
  const handleChange = (value, name) => {
    setCompanySettings({ ...companySettings, [name]: value });
  };

  const handleChangeTime = (timeObj = {}) => {
    console.log("timeObj", timeObj);
    // console.log('timesLine, time', timesLine, time);
    const patternTime = (timeObj.worksPerHour || companySettings.worksPerHour) === 1 ? times : times30;
    const start = patternTime.findIndex(
      (time) => time === (timeObj.startWT || companySettings.startWT)
    );
    const end = patternTime.findIndex((time) => time === (timeObj.endWT || companySettings.endWT));
    const timesLine = timeObj.timesLine ? timeObj.timesLine : patternTime.slice(start, end);
    setCompanySettings({ ...companySettings, ...timeObj, timesLine });
  };

  return (
    <>
      <Paper sx={{ pl: 2, pr: 2 }}>
        <Typography sx={{ m: 2 }} gutterBottom variant="h4" component="div">
          Company Settings
        </Typography>
        <Divider />
        <Grid container spacing={1} columns={3} sx={{ mb: 1, mt: 2 }}>
          <Grid item md={1}>
            <TextField
              value={companySettings.company}
              onChange={(e) => handleChange(e.target.value, "company")}
              label="Company Name"
              fullWidth
              required
              hiddenLabel
              size="small"
              disabled={true}
              variant="outlined"
            />
          </Grid>
          <Grid item md={1}>
            <TextField
              value={companySettings.firstName}
              onChange={(e) => handleChange(e.target.value, "firstName")}
              label="First Name"
              fullWidth
              required
              hiddenLabel
              size="small"
              // disabled={true}
              variant="outlined"
            />
          </Grid>
          <Grid item md={1}>
            <TextField
              value={companySettings.lastName}
              onChange={(e) => handleChange(e.target.value, "lastName")}
              label="Last Name"
              fullWidth
              required
              hiddenLabel
              size="small"
              // disabled={true}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} columns={6} sx={{ mb: 1, mt: 1 }}>
          <Grid item md={4}>
            <TextField
              value={companySettings.companyAddress}
              onChange={(e) => handleChange(e.target.value, "companyAddress")}
              label="Company Address"
              fullWidth
              required
              hiddenLabel
              size="small"
              // disabled={true}
              variant="outlined"
            />
          </Grid>
          <Grid item md={1}>
            <TextField
              value={companySettings.companyZip}
              onChange={(e) => handleChange(e.target.value, "companyZip")}
              label="Company Zip"
              fullWidth
              required
              hiddenLabel
              size="small"
              // disabled={true}
              variant="outlined"
            />
          </Grid>
          <Grid item md={1}>
            <TextField
              value={companySettings.tax}
              onChange={(e) => handleChange(e.target.value, "tax")}
              label="TAX"
              fullWidth
              required
              hiddenLabel
              size="small"
              // disabled={true}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} columns={3} sx={{ mb: 1, mt: 1 }}>
          <Grid item md={1}>
            <TextField
              value={companySettings.phoneNumber}
              onChange={(e) => handleChange(e.target.value, "phoneNumber")}
              label="Phone Number"
              fullWidth
              required
              hiddenLabel
              size="small"
              // disabled={true}
              variant="outlined"
            />
          </Grid>
          <Grid item md={1}>
            <TextField
              value={companySettings.email}
              onChange={(e) => handleChange(e.target.value, "email")}
              label="Email"
              fullWidth
              required
              hiddenLabel
              size="small"
              disabled={true}
              variant="outlined"
            />
          </Grid>
          <Grid item md={1}>
            <Paper
            onClick={() => setOpenJobFilters(true)}
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                background: green[400],
                cursor: 'pointer'
              }}
            >
              <Typography
                sx={{ m: 1, fontSize: 13 }}
                gutterBottom
                variant="button"
                component="div"
              >
                JOB FILTERS
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        <JobFilters isOpenDialog={isOpenJobFilters} setOpenDialog={setOpenJobFilters} />

        <TimesLineEditeBlock
          companySettings={companySettings}
          handleChangeTime={handleChangeTime}
          handleChange={handleChange}
        />

        <ZipCodesField />

        {/* //TODO: сделать удаление коллекций компании  */}

        <Grid columns={14} container spacing={1} sx={{ pb: 2, display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={2}>
            <ManagerSettingItem manager={user} title='MAP' linkName='map' isDisable />
          </Grid>
          <Grid item xs={2}>
            <ManagerSettingItem manager={user} title='STOCK' linkName='stock' />
          </Grid>
          <Grid item xs={2}>
            <ManagerSettingItem manager={user} title='PARTS' linkName='parts' isDisable />
          </Grid>
          <Grid item xs={2}>
            <ManagerSettingItem manager={user} title='PAYMENTS' linkName='payments' />
          </Grid>
          <Grid item xs={2}>
            <ManagerSettingItem manager={user} title='WI-KI' linkName='wiki' isDisable />
          </Grid>
          <Grid item xs={2}>
            <ManagerSettingItem manager={user} title='INVOISE' linkName='invoise' isDisable />
          </Grid>
          <Grid item xs={2}>
            <ManagerSettingItem manager={user} title='SA' linkName='superAdmin' />
          </Grid>
          <Grid item xs={2}>
            <ManagerSettingItem manager={user} title='API' linkName='apiPage' />
          </Grid>
        </Grid>

        <LoadingButton
          loading={isLoading}
          fullWidth
          size="small"
          variant="contained"
          sx={{ mb: 2 }}
          onClick={async () => {
            setIsLoading(true)
            // setOpenDialog(true);
            console.log('!!!!!!!', companySettings);
            // setDocItem
            try {
              await firebase.setDocItem('companies', companySettings.company, companySettings)
            } catch (error) {
              setMsg(error.message);
            }
            setIsLoading(false)

          }}
        >
          UPDATE
        </LoadingButton>

      </Paper>

      {/* <Dialog
        open={isOpenDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            Edite Company Info
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={companyInfo?.company}
                onChange={(e) => handleChange(e.target.value, "company")}
                variant="standard"
                label="Company Name"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={companyInfo?.companyAddress}
                onChange={(e) => handleChange(e.target.value, "companyAddress")}
                variant="standard"
                label="Company Address"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={companyInfo?.companyZip}
                onChange={(e) => handleChange(e.target.value, "companyZip")}
                variant="standard"
                label="Company ZIP Code"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={companyInfo?.phone}
                onChange={(e) => handleChange(e.target.value, "phone")}
                variant="standard"
                label="Phone"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={companyInfo?.timeFrames}
                onChange={(e) => handleChange(e.target.value, "timeFrames")}
                variant="standard"
                label="Time Frames 8.00AM-12.00PM"
                fullWidth
              />
            </Grid>
            ...COMPANY LOGO ?? работа с сохр\добавл фото ? обрезка ?
            <Divider variant="middle" light />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button
            onClick={async () => {
              // изменение коллекции Company Info
              try {
                const companyInfoRef = collection(
                  fbServices.db,
                  `${user.company}Info`
                );
                const newcompanyInfo = await setDoc(
                  doc(companyInfoRef, `${user.company}Info`),
                  { ...companyInfo }
                );
                setOpenDialog(false);
                // setInit();
              } catch (error) {
                console.log("Company Info-error", error);
              }
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default CompanyInfo;

const TimesLineEditeBlock = ({
  companySettings,
  handleChangeTime,
  handleChange,
}) => {
  const patternTime = companySettings.worksPerHour === 1 ? times : times30;
  const start = patternTime.findIndex(
    (time) => time === companySettings.startWT
  );
  const end = patternTime.findIndex((time) => time === companySettings.endWT);
  const timesLine = patternTime.slice(start, end);

  const handleChangeTime2 = (data) => {
    const findTime = companySettings.timesLine.indexOf(data)
    const ownTimeLine = [...companySettings.timesLine]

    if (findTime !== -1) {
      ownTimeLine.splice(findTime, 1);
      handleChangeTime({ timesLine: ownTimeLine })
    } else {
      const findTimeOrigin = timesLine.indexOf(data)
      ownTimeLine.splice(findTimeOrigin, 0, data);
      handleChangeTime({ timesLine: ownTimeLine })
    }
  }

  return (
    <>
      <Grid container spacing={1} columns={20} sx={{ alignItems: 'center', marginBottom: 1, }}>
        <Grid item md={2}>
          <FormControl
            fullWidth
            size="small"
            variant="standard"
          // sx={{ minWidth: 80 }}
          >
            <InputLabel id="startWT">Start hour</InputLabel>
            <Select
              labelId="startWT"
              id="demo-simple-select-standard"
              value={companySettings.startWT}
              onChange={(e) => {
                // handleChange(e.target.value, "startWT");
                // updateTimesLine(timesLine);
                handleChangeTime({
                  // timesLine,
                  startWT: e.target.value,
                });
              }}
              label="Start of working hours"
            >
              {patternTime.map((time) => {
                return (
                  <MenuItem key={time} value={time}>
                    {time.replace("5", "3")}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <FormControl
            fullWidth
            size="small"
            variant="standard"
            sx={{ minWidth: 80 }}
          >
            <InputLabel id="endWT">End hour</InputLabel>
            <Select
              labelId="endWT"
              id="demo-simple-select-standard"
              value={companySettings.endWT}
              onChange={(e) => {
                // handleChange(e.target.value, "endWT");
                // updateTimesLine(timesLine);
                handleChangeTime({
                  // timesLine,
                  endWT: e.target.value,
                });
              }}
              label="End of working hours"
            >
              {patternTime.map((time) => {
                return (
                  <MenuItem key={time} value={time}>
                    {time.replace("5", "3")}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={1}>
          <FormControl
            fullWidth
            size="small"
            variant="standard"
          // sx={{ minWidth: 120 }}
          >
            <InputLabel id="worksPerHour">Jobs</InputLabel>
            <Select
              labelId="worksPerHour"
              id="demo-simple-select-standard"
              value={companySettings.worksPerHour}
              onChange={(e) => {
                // handleChange(e.target.value, "worksPerHour");
                // updateTimesLine(timesLine);
                handleChangeTime({
                  // timesLine,
                  worksPerHour: e.target.value,
                });
              }}
              label="End of working hours"
            >
              {[1, 2].map((time) => {
                return (
                  <MenuItem key={time} value={time}>
                    {time}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={15}>
          <Box
            sx={{
              // m: 1,
              // border: 1,
              // borderColor: "#767676",
              // borderRadius: 5,
              // p: 1,
              display: "flex",
              flexWrap: "wrap",
              // justifyContent: 'space-between',
              // minHeight: 150,
            }}
          >
            {timesLine.map((time) => {
              const isCurrentTime = companySettings.timesLine.includes(time);
              // console.log('time', time, isCurrentTime);
              return (
                <Box
                  key={time}
                  sx={{
                    margin: '1px',
                    border: 1,
                    borderColor: "#767676",
                    padding: '3px',
                    cursor: "pointer",
                    // flexGrow: 1,
                    // maxHeight: 40,
                    minWidth: 50,
                    textAlign: "center",
                    borderRadius: 1,
                    backgroundColor: isCurrentTime ? blue[700] : 'white',
                    "&:hover": {
                      background: "grey",
                      // primary
                    },
                  }}
                  onClick={() => handleChangeTime2(time)}
                >
                  {time.replace("50", "30")}
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
