import { userWorkerStatus } from "../constants";
import firebase from "./firebase";
import { queryResolver } from "./queryResolver";

export const getCompanyWorkers = async (
  company = {},
  setTechniciansStore,
  setManagersStore,
  setMsg
) => {
  let adminEmail = "";

  for (const worker in company.workers) {
    if (company.workers[worker] === userWorkerStatus.ADMIN) adminEmail = worker;
  }
  try {
    const manager = await queryResolver(
      "users",
      `companiesJobs.${company.company}`,
      userWorkerStatus.MANAGER
    );
    const technician = await queryResolver(
      "users",
      `companiesJobs.${company.company}`,
      userWorkerStatus.TECHNICIAN
    );
    const admin = adminEmail && await firebase.getDocItem("users", adminEmail);

    admin && company.adminAsTechnician && technician.push(admin);

    setTechniciansStore(technician);
    setManagersStore(manager);
  } catch (error) {
    setMsg(error.message);
    console.log("getCompanyWorkers-err", error);
  }
};
