import { useState, Fragment } from "react";
import {
  Paper,
  Grid,
  Typography,
  Box,
  TextField,
  InputLabel,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useStores } from "../../store";
import LoadingButton from "@mui/lab/LoadingButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { dataBaseName, userWorkerStatus } from "../../constants";
import SearchIcon from "@mui/icons-material/Search";
import firebase from "../../services/firebase";
import { queryResolver } from "../../services/queryResolver";
import { observer } from "mobx-react-lite";
import { getCompanyWorkers } from "../../services/getCompanyWorkersService";
import ManagerSettingItem from "./ManagerSettingItem";
import fbServices from "../../services/firebase";
import WorkerSalary from "./WorkerSalary";
import {red, green, grey} from "@mui/material/colors";
import { ManagerZipCheckBlock } from "./ManagerZipCheckBlock";
import { ManagerTechFilter } from "./ManagerTechFilter";
import { ManagerZipFilter } from "./ManagerZipFilter";

const managerFieldsConfig = [
  {
    name: "Name",
    value: "firstName",
    grid: 6,
    isSimpleField: true,
  },
  {
    name: "Last Name",
    value: "lastName",
    grid: 6,
    isSimpleField: true,
  },
  {
    name: "Email",
    value: "email",
    grid: 12,
  },
  {
    name: "Password *",
    value: "password",
    grid: 6,
    isSimpleField: true,
    isPassword: "password",
  },
  {
    name: "Confirm Password *",
    value: "confirmPassword",
    grid: 6,
    isSimpleField: true,
    isPassword: "password",
  },
  {
    name: "Phone number",
    value: "phoneNumber",
    keyboardType: "numeric",
    grid: 12,
  },
  {
    name: "Address",
    value: "address",
    grid: 8,
    isSimpleField: true,
  },
  {
    name: "Zip Code",
    value: "zip",
    grid: 4,
    isSimpleField: true,
  },
];

// const menagerSettingFields = [
//   {
//     name: "Address",
//     value: "address",
//     grid: 12,
//     isSimpleField: true,
//   },
// ]

const WorkerList = observer(({ isWorker }) => {
  const {
    userStore: { user, setUser, changeUserInfo },
    snackbarStore: { open, msg, setMsg, active, desactive },
    companyInfoStore: { companyInfo, updateCompanyInfoFieldStore },
    managersStore: { managers, setManagersStore },
    techniciansStore: {
      technicians,
      setTechniciansStore,
      addTechnic,
      removeTechnic,
    },
  } = useStores();

  const [isDropDown, setIsDropDown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [workerData, setWorkerData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    zip: "",
    address: "",
  });

  const handleChange = (event, name) => {
    setWorkerData({ ...workerData, [name]: event.target.value });
  };

  const workerList = isWorker ? technicians || [] : managers || [];

  return (
    <>
      <Paper sx={{ mt: 2, pb: 1 }}>
        <Typography sx={{ m: 2 }} gutterBottom variant="h4">
          {isWorker ? "Technicians" : "Managers"} Settings
        </Typography>
        {workerList.map((manager) => {
          return (
            <div key={manager.email}>
              <ManagerItem manager={manager} isWorker={isWorker} />
            </div>
          );
        })}
        <Box
          sx={{
            border: 1,
            borderColor: "black",
            borderRadius: 2,
            pl: 1,
            pr: 1,
            m: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => setIsDropDown(!isDropDown)}
          >
            <Typography variant="subtitle2">
              + {isWorker ? "Technicians" : "Manager"}
            </Typography>
            {isDropDown ? (
              <ExpandLessIcon sx={{ fontSize: 30, mr: 1 }} />
            ) : (
              <ExpandMoreIcon sx={{ fontSize: 30, mr: 1 }} />
            )}
          </Box>
          {isDropDown ? (
            <Box sx={{ mb: 1, mt: 1 }}>
              <Grid container spacing={2}>
                {managerFieldsConfig.map((field, index) => {
                  return (
                    <Grid key={index} item xs={field.grid}>
                      {field.isSimpleField ? (
                        <TextField
                          fullWidth
                          id={field.value}
                          label={field.name}
                          variant="outlined"
                          size="small"
                          // disabled={field.isPassword}
                          type={field.isPassword}
                          value={workerData[field.value]}
                          onChange={(e) => handleChange(e, field.value)}
                        />
                      ) : (
                        <FormControl variant="outlined" fullWidth size="small">
                          <InputLabel htmlFor={field.value}>
                            {field.name}
                          </InputLabel>
                          <OutlinedInput
                            id={field.value}
                            fullWidth
                            value={workerData[field.value]}
                            onChange={(e) => handleChange(e, field.value)}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  // aria-label="toggle password visibility"
                                  onClick={async (e) => {
                                    if (field.value === "email") {
                                      try {
                                        if (
                                          Object.keys(
                                            companyInfo.workers
                                          ).includes(
                                            workerData[
                                            field.value.toLowerCase()
                                            ]
                                          )
                                        ) {
                                          throw new Error(
                                            "Such a user already works for you"
                                          );
                                        }
                                        const existingUser =
                                          await firebase.getDocItem(
                                            "users",
                                            workerData.email.toLowerCase()
                                          );
                                        if (!existingUser) {
                                          setMsg("There is no such user");
                                        } else {
                                          setWorkerData({
                                            ...workerData,
                                            ...existingUser,
                                          });
                                        }
                                      } catch (error) {
                                        console.log(
                                          "getManagerForEmailphone-error",
                                          error.message
                                        );
                                        setMsg(error.message);
                                      }
                                    } else if (field.value === "phoneNumber") {
                                      try {
                                        const existingUser =
                                          await queryResolver(
                                            "users",
                                            "phoneNumber",
                                            workerData.phoneNumber
                                          );
                                        if (
                                          !existingUser ||
                                          existingUser.length === 0
                                        ) {
                                          setMsg("There is no such user");
                                        } else {
                                          setWorkerData({
                                            ...workerData,
                                            ...existingUser[0],
                                          });
                                        }
                                      } catch (error) {
                                        console.log(
                                          "getManagerForEmailphone-error",
                                          error.message
                                        );
                                        setMsg(error.message);
                                      }
                                    }
                                  }}
                                  // onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label={field.name}
                          />
                        </FormControl>
                      )}
                    </Grid>
                  );
                })}
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    fullWidth
                    sx={{ my: 1 }}
                    onClick={() => {
                      setWorkerData({
                        firstName: "",
                        lastName: "",
                        email: "",
                        password: "",
                        confirmPassword: "",
                        phoneNumber: "",
                        zip: "",
                        address: "",
                      });
                    }}
                  >
                    Clear
                  </LoadingButton>
                </Grid>
                <Grid item xs={6}>
                  <LoadingButton
                    loading={isLoading}
                    disabled={!workerData.email || !workerData.password}
                    onClick={async () => {
                      setIsLoading(true);
                      // проверить есть ли пользователь в системе
                      try {
                        if (user.email === workerData.email)
                          throw new Error("You are the owner of the company");

                        const existingUser = await firebase.getDocItem(
                          "users",
                          workerData.email.toLowerCase()
                        );

                        if (!existingUser) {
                          const systemUser = await firebase.createSystemUser({
                            ...workerData,
                            email: workerData.email.toLowerCase(),
                            companiesJobs: {
                              [companyInfo.company]: isWorker
                                ? "technician"
                                : "manager",
                            },
                          });
                          if (systemUser.data?.errorInfo) {
                            throw new Error(systemUser.data?.errorInfo.message);
                          }
                        } else {
                          setWorkerData({ ...workerData, ...existingUser });
                        }
                        // добавить пользователя в компанию
                        const updatedCompany = await firebase.updateDocItem(
                          "companies",
                          companyInfo.company,
                          {
                            workers: {
                              ...(companyInfo.workers ?? {}),
                              [workerData.email.toLowerCase()]: isWorker
                                ? "technician"
                                : "manager",
                            },
                          }
                        );
                        // добавить компанию к пользователю
                        const updatedUser = await firebase.updateDocItem(
                          "users",
                          workerData.email.toLowerCase(),
                          {
                            companiesJobs: {
                              ...(workerData.companiesJobs ?? {}),
                              [companyInfo.company]: isWorker
                                ? "technician"
                                : "manager",
                            },
                          }
                        );
                        getCompanyWorkers(
                          companyInfo.company,
                          setTechniciansStore,
                          setManagersStore,
                          setMsg
                        );
                      } catch (error) {
                        console.log("getManager-error", error.message, setMsg);
                        setMsg(error.message);
                      } finally {
                        setIsLoading(false);
                        setWorkerData({
                          firstName: "",
                          lastName: "",
                          email: "",
                          password: "",
                          confirmPassword: "",
                          phoneNumber: "",
                          zip: "",
                          address: "",
                        });
                      }
                    }}
                    variant="contained"
                    fullWidth
                    sx={{ my: 1 }}
                  >
                    Add {isWorker ? "Technician" : "Manager"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </Box>
        {isWorker ? (
          <Box sx={{ ml: 2 }}>
            <FormControlLabel
              value=""
              checked={companyInfo?.adminAsTechnician || false}
              onChange={async (e) => {
                // обновить поле в стор (чтоб не перезапрашивать с базы)
                updateCompanyInfoFieldStore(
                  "adminAsTechnician",
                  e.target?.checked
                );
                e.target?.checked
                  ? addTechnic(user)
                  : removeTechnic(user.email);

                // обновить настройку в компании в базе данных
                await firebase.updateDocItem("companies", companyInfo.company, {
                  adminAsTechnician: e.target?.checked,
                });
              }}
              control={
                <Checkbox
                  size="small"
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Admin as Technician"
              labelPlacement="end"
            />
          </Box>
        ) : null}
      </Paper>
    </>
  );
});

export default WorkerList;

const ManagerItem = ({ manager, isWorker }) => {
  // console.log('ManagerItem',manager, isWorker );
  const {
    userStore: { user, setUser, changeUserInfo },
    snackbarStore: { open, msg, setMsg, active, desactive },
    // initScreenStore: { setInit, init },
    companyInfoStore: { companyInfo, setCompanyInfoStore },
    managersStore: { managers, setManagersStore },
    techniciansStore: { technicians, setTechniciansStore },
  } = useStores();

  const [isLoading, setIsLoading] = useState(false);
  const [isDropDown, setIsDropDown] = useState(false);
  const [managerItem, setManagerItem] = useState(manager);

  const handleChange = (event, name) => {
    setManagerItem({ ...managerItem, [name]: event.target.value });
  };

  return (
    <Box
      sx={{
        border: 1,
        borderColor: "black",
        borderRadius: 2,
        pl: 1,
        pr: 1,
        mx: 3,
        my: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={() => setIsDropDown(!isDropDown)}
      >
        <Typography variant="subtitle2">
          {managerItem.firstName} / {managerItem.lastName} : {managerItem.email}
        </Typography>
        {isDropDown ? (
          <ExpandLessIcon sx={{ fontSize: 30, mr: 1 }} />
        ) : (
          <ExpandMoreIcon sx={{ fontSize: 30, mr: 1 }} />
        )}
      </Box>
      {isDropDown ? (
        <Box sx={{ mb: 1, mt: 1 }}>
          <Grid container spacing={2}>
            {managerFieldsConfig.map((field, index) => {
              const isPhoneField = field.name === 'Phone number';
              if (isPhoneField && isWorker) {
                return (
                  <Fragment key={index}>
                    <Grid key={index} item xs={8}>
                      <TextField
                        fullWidth
                        disabled
                        type={field.isPassword}
                        id={field.value}
                        label={field.name}
                        variant="outlined"
                        size="small"
                        value={managerItem[field.value]}
                        onChange={(e) => handleChange(e, field.value)}
                      />
                    </Grid>
                    <WorkerSalary managerItem={managerItem} />
                  </Fragment>
                )
              }
              if (isPhoneField && !isWorker) {
                return (  
                  <Fragment key={index}>
                    <Grid key={index} item xs={4}>
                      <TextField
                        fullWidth
                        disabled
                        type={field.isPassword}
                        id={field.value}
                        label={field.name}
                        variant="outlined"
                        size="small"
                        value={managerItem[field.value]}
                        onChange={(e) => handleChange(e, field.value)}
                      />
                    </Grid>
                    <ManagerTechFilter managerItem={managerItem} />
                    <ManagerZipFilter managerItem={managerItem} />
                  </Fragment>
                )
              }
              return (
                <Grid key={index} item xs={field.grid}>
                  <TextField
                    fullWidth
                    disabled
                    type={field.isPassword}
                    id={field.value}
                    label={field.name}
                    variant="outlined"
                    size="small"
                    value={managerItem[field.value]}
                    onChange={(e) => handleChange(e, field.value)}
                  />
                </Grid>
              );
            })}
          </Grid>

          {isWorker ? null : (
            <Grid columns={14} container spacing={1} sx={{ mt: 1 }}>
              <Grid item xs={2}>
                <ManagerSettingItem manager={manager} title='MAP' linkName='map' isDisable />
              </Grid>
              <Grid item xs={2}>
                <ManagerSettingItem manager={manager} title='STOCK' linkName='stock' />
              </Grid>
              <Grid item xs={2}>
                <ManagerSettingItem manager={manager} title='PARTS' linkName='parts' isDisable />
              </Grid>
              <Grid item xs={2}>
                <ManagerSettingItem manager={manager} title='PAYMENTS' linkName='payments' />
              </Grid>
              <Grid item xs={2}>
                <ManagerSettingItem manager={manager} title='WI-KI' linkName='wiki' isDisable />
              </Grid>
              <Grid item xs={2}>
                <ManagerSettingItem manager={manager} title='SETTINGS' linkName='settings' />
              </Grid>
              <Grid item xs={2}>
                <ManagerSettingItem manager={manager} title='INVOISE' linkName='invoise' isDisable />
              </Grid>
            </Grid>
          )}

          {/* {isWorker && companyInfo.zipPack ? null : (
            не забыть удалить все что глубже
            <ManagerZipCheckBlock manager={manager} />
          )} */}

          <LoadingButton
            loading={isLoading}
            disabled={
              manager.companiesJobs[companyInfo.company] ===
              userWorkerStatus.ADMIN
            }
            variant="contained"
            fullWidth
            sx={{ my: 1 }}
            onClick={async () => {
              try {
                setIsLoading(true);
                // удалить компанию у пользователя
                delete managerItem.companiesJobs[companyInfo.company];
                // удалить пользователя в компании
                delete companyInfo.workers[managerItem.email];
                // обновить поле работников в компании
                await firebase.updateDocItem("companies", companyInfo.company, {
                  workers: {
                    ...(companyInfo.workers ?? {}),
                  },
                });

                // обновить поле компаний в работнике
                await firebase.updateDocItem("users", managerItem.email, {
                  companiesJobs: {
                    ...(managerItem.companiesJobs ?? {}),
                  },
                });
                // setInit();
              } catch (error) {
                setMsg(error.message);
                console.log("удаление менеджера-error", error);
              } finally {
                await getCompanyWorkers(
                  companyInfo.company,
                  setTechniciansStore,
                  setManagersStore,
                  setMsg
                );
                setIsLoading(false);
              }
            }}
          >
            Delete Employee
          </LoadingButton>

        </Box>
      ) : null}
    </Box>
  );
};
