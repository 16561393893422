import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";

const LoadingComponent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "40vh",
        width: '100vw',
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress size={100} />
    </Box>
  );
};

export default LoadingComponent;
