import { makeAutoObservable } from "mobx";

class TechniciansStore {
  technicians = [];

  constructor() {
    makeAutoObservable(this);
  }

  setTechniciansStore = (data) => {
    this.technicians = data;
  };

  addTechnic = (technic) => {
    this.technicians.push(technic);
  };

  removeTechnic = (email) => {
    this.technicians = this.technicians.filter((technic) => technic.email !== email)
  }
}

export default TechniciansStore;
