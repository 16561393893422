import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  forwardRef,
} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Container,
  Typography,
  Box,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  CardMedia,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Paper,
  Divider,
  TextareaAutosize,
  Grid,
  Modal,
  List,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import firebase from "../../services/firebase";
import LoadingButton from "@mui/lab/LoadingButton";
import { useStores } from "../../store";
import { TimesLine } from "./index";
import { getGeocode } from "../../services/apiService";
import {
  collection,
  addDoc,
  doc,
  getDocs,
  getDoc,
  deleteDoc,
  updateDoc,
  setDoc,
  query,
  where,
  arrayUnion,
} from "firebase/firestore";
import fbServices from "../../services/firebase";
import MyDatePicker from "./MyDatePicker";
// import { async } from "@firebase/util";
import { queryResolver } from "../../services/queryResolver";
import { pink, red, green, blue, grey, brown } from "@mui/material/colors";
import { customAlphabet } from "nanoid";
import { orderStatuses } from "../../constants";
import { useLocation } from "react-router-dom";
import moment from "moment";
import OrdersPhoto from "./OrdersPhoto";
import SelectionDialog from "./SelectionDialog";
import DescriptionComponent from "./DescriptionComponent";
import AlertDialog from "../../Common/AlertDialog";
import {getCompanyOrders} from "../../services/getCompanyOrders";
import useLocalStorage from "../../hooks/useLocalStorageHook";
import {getNotWorkersOrders} from "../../services/getNotWorkersOrders";
import TextModal from "../../Common/TextModal";

const MainInfoComponent = ({
  handleChange,
  isDisabled,
  order,
  setOrder,
  isSearch,
  isOpenSelectionDialog,
  setOpenSelectionDialog,
  searchRsault,
  setSearchItem,
  setSearchRsault,
  setOpenDialog,
  setInit,
  init,
}) => {
  const {
    userStore: { user, setUser, changeUserInfo },
    companyInfoStore: { companyInfo },
    techniciansStore: { technicians, setTechniciansStore },
  } = useStores();

  const [openAlert, setOpenAlert] = useState(false);
  const [openTextModal, setOpenTextModal] = useState(false)

  const [startDate] = useLocalStorage("startDate", new Date());

  const handleOpenDescription = () => {
    console.log('handleOpenDescription')
    setOpenTextModal(true)
  }

  return (
    <>
      <SelectionDialog
        isOpenSelectionDialog={isOpenSelectionDialog}
        setOpenSelectionDialog={setOpenSelectionDialog}
        searchRsault={searchRsault}
        setSearchItem={setSearchItem}
      />
      <Grid container spacing={1} columns={3} sx={{ mb: 1 }}>
        <Grid item md={1}>
          <TextField
            value={order.type}
            onChange={(e) => handleChange(e, "type")}
            variant="outlined"
            label="Appliance Tipe"
            required
            fullWidth
            size="small"
            disabled={isDisabled}
          />
        </Grid>
        <Grid item md={1}>
          <TextField
            value={order.brend}
            onChange={(e) => handleChange(e, "brend")}
            label="Appliance Brend"
            fullWidth
            // required
            hiddenLabel
            size="small"
            disabled={isDisabled}
            variant="outlined"
          />
        </Grid>

        <Grid item md={1}>
          <TextField
            value={order.serialNumber}
            onChange={(e) => handleChange(e, "serialNumber")}
            // variant="standard"
            variant="outlined"
            label="Model#" // Appliance S/N
            fullWidth
            size="small"
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} columns={3} sx={{ mb: 1 }}>
        <Grid item md={1}>
          <TextField
            value={order.clientName}
            onChange={(e) => handleChange(e, "clientName")}
            variant="outlined"
            label="Client Name"
            fullWidth
            size="small"
            disabled={isDisabled}
          />
        </Grid>
        <Grid item md={1}>
          {isSearch ? (
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel htmlFor={"phoneNumber"}>Phone number</InputLabel>
                <OutlinedInput
                    disabled={isDisabled}
                    id={"phoneNumber"}
                    fullWidth
                    value={order.phoneNumber}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline" : {
                        borderColor : "black",
                        borderWidth: 2,
                      },
                      "&:hover > .MuiOutlinedInput-notchedOutline" : {
                        borderColor : "black"
                      },
                    }}
                    onChange={(e) => handleChange(e, "phoneNumber")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                            // aria-label="toggle password visibility"
                            onClick={async (e) => {
                              if (!order.phoneNumber) return
                              if (isSearch) {
                                const srRsaultPhoneNumber = await queryResolver(
                                    "orders",
                                    "phoneNumber",
                                    order.phoneNumber
                                );
                                setSearchRsault(
                                    srRsaultPhoneNumber.filter(
                                        (item) => companyInfo.company === item.company
                                    )
                                );
                                setOpenSelectionDialog(true);
                              } else {
                                const existingCustomer = await firebase.getDocItem(
                                    "customers",
                                    order.phoneNumber
                                );
                                if (existingCustomer) {
                                  setOrder({
                                    ...order,
                                    adress: existingCustomer.adress,
                                    zipСode: existingCustomer.zipСode,
                                    clientName: existingCustomer.clientName,
                                  });
                                }
                              }
                            }}
                            edge="end"
                        >
                          <SearchIcon color='error' />
                        </IconButton>
                      </InputAdornment>
                    }
                    label={"phoneNumber"}
                />
              </FormControl>
          ) : (
              <TextField
                  value={order.phoneNumber}
                  onChange={(e) => handleChange(e, "phoneNumber")}
                  variant="outlined"
                  label="Phone Number"
                  fullWidth
                  size="small"
                  disabled={isDisabled}
              />
          )}
        </Grid>
        <Grid item md={1}>
          <TextField
            value={order.srf}
            onChange={(e) => handleChange(e, "srf")}
            variant="outlined"
            label="SRF"
            fullWidth
            size="small"
            disabled={isDisabled}
          />
        </Grid>
        {/* <Grid item md={1}>
          <TextField
            value={order.houseCost}
            onChange={(e) => handleChange(e, "houseCost")}
            variant="outlined"
            label="House cost"
            fullWidth
            size="small"
            disabled={isDisabled}
          />
        </Grid> */}
      </Grid>
      <Grid container spacing={1} columns={3} sx={{ mb: 1 }}>
        <Grid item md={2}>
          {isSearch ? (
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel htmlFor={"adress"}>Address</InputLabel>
              <OutlinedInput
                disabled={isDisabled}
                id={"adress"}
                fullWidth
                value={order.adress}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline" : {
                    borderColor : "black",
                    borderWidth: 2,
                  },
                  "&:hover > .MuiOutlinedInput-notchedOutline" : {
                    borderColor : "black"
                  },
                }}
                onChange={(e) => handleChange(e, "adress")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      // aria-label="toggle password visibility"
                      onClick={async (e) => {
                        if (!order?.adress) return
                        if (isSearch) {
                          const srRsaultPhoneNumber = await queryResolver(
                            "orders",
                            "adress",
                            order.adress
                          );
                          setSearchRsault(
                            srRsaultPhoneNumber.filter(
                              (item) => companyInfo.company === item.company
                            )
                          );
                          setOpenSelectionDialog(true);
                        }
                      }}
                      edge="end"
                    >
                      <SearchIcon color='error' />
                    </IconButton>
                  </InputAdornment>
                }
                label={"phoneNumber"}
              />
            </FormControl>
          ) : (
            <TextField
              value={order.adress}
              onChange={(e) => handleChange(e, "adress")}
              variant="outlined"
              label="Adress"
              fullWidth
              size="small"
              disabled={isDisabled}
            />
          )}

          {/* <TextField
            value={order.adress}
            onChange={(e) => handleChange(e, "adress")}
            variant="outlined"
            label="Adress"
            fullWidth
            size="small"
            disabled={isDisabled}
          /> */}
        </Grid>
        <Grid item md={1}>
          <TextField
            value={order.zipСode}
            onChange={(e) => handleChange(e, "zipСode")}
            variant="outlined"
            label="Zip code"
            fullWidth
            size="small"
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} columns={3} sx={{ mb: 0 }}>
        <Grid item md={2}>
          <TextField
            sx={{ mb: 1 }}
            value={order.jobDescription}
            onChange={(e) => handleChange(e, "jobDescription")}
            variant="outlined"
            label="Job Description"
            fullWidth
            size="small"
            disabled={isDisabled}
            multiline
          />
          <TextField
            sx={{ mb: 1 }}
            value={order.fromTechnishion}
            onChange={(e) => handleChange(e, "fromTechnishion")}
            variant="outlined"
            label="From technishion"
            fullWidth
            size="small"
            disabled={isDisabled}
            multiline
          />
          {/* <TextField
            sx={{ mb: 1 }}
            value={order.feedbackAboutCastomer}
            onChange={(e) => handleChange(e, "feedbackAboutCastomer")}
            variant="outlined"
            label="Feedback about castomer"
            fullWidth
            size="small"
            disabled={isDisabled}
            multiline
          /> */}
          <Grid container spacing={1} columns={4}>
            <Grid item md={3}>
              {isSearch ? (
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel htmlFor={"needPartsInfo"}>Parts for order</InputLabel>
                    <OutlinedInput
                        disabled={isDisabled}
                        id={"needPartsInfo"}
                        fullWidth
                        value={order.needPartsInfo}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline" : {
                            borderColor : "black",
                            borderWidth: 2,
                          },
                          "&:hover > .MuiOutlinedInput-notchedOutline" : {
                            borderColor : "black"
                          },
                        }}
                        onChange={(e) => handleChange(e, "needPartsInfo")}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                                // aria-label="toggle password visibility"
                                onClick={async (e) => {
                                  if (!order.needPartsInfo) return
                                  if (isSearch) {
                                    const srRsaultNeedPartsInfo = await queryResolver(
                                        "orders",
                                        "needPartsInfo",
                                        order.needPartsInfo
                                    );
                                    setSearchRsault(
                                        srRsaultNeedPartsInfo.filter(
                                            (item) => companyInfo.company === item.company
                                        )
                                    );
                                    setOpenSelectionDialog(true);
                                  }
                                }}
                                edge="end"
                            >
                              <SearchIcon color='error' />
                            </IconButton>
                          </InputAdornment>
                        }
                        label={"phoneNumber"}
                    />
                  </FormControl>
              ) : (<TextField
                  value={order.needPartsInfo}
                  onChange={(e) => handleChange(e, "needPartsInfo")}
                  variant="outlined"
                  label="Parts for order"
                  fullWidth
                  size="small"
                  disabled={isDisabled}
                  multiline
              />)}

            </Grid>
            <Grid item md={1}>
              <TextField
                // sx={{ mb: 1 }}
                value={order.deposit}
                onChange={(e) => handleChange(e, "deposit")}
                variant="outlined"
                label="Deposit"
                fullWidth
                size="small"
                disabled={isDisabled}
                multiline
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={1}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            variant="contained"
            disabled={!order.workerUid}
            size="small"
            sx={{ height: "95%", width: "48%" }}
            onClick={handleOpenDescription}
          >
            SEND MESSAGE TO TECH
          </Button>
          {openTextModal ? (
              <TextModal openTextModal={openTextModal} setOpenTextModal={setOpenTextModal} order={order} />
          ) : null}
          <Button
            variant="contained"
            size="small"
            sx={{ height: "95%", width: "48%" }}
            onClick={() => setOpenAlert(true)}
          >
            DELETE ORDER
          </Button>
          <AlertDialog isOpen={openAlert} close={() => setOpenAlert(false)} action={async () => {
            const companyOrdersRef = doc(
                fbServices.firestoreDb,
                `orders/${order.uid}`
            );
            const res = await deleteDoc(companyOrdersRef);
            // setInit(!init)

            // отправить push мастеру или хозяину(если нет мастера или таймлайна)
            const admin =
                companyInfo.email &&
                (await firebase.getDocItem("users", companyInfo.email));
            const ownerToken = admin?.notifToken;
            const technicianToken = technicians.find(
                (technician) => technician.uid === order?.workerUid
            );

            await fbServices.sendPush({
              notification: {
                title: `ORDER DELETED: ${order.number}`,
                body: `COMPANY: ${order.company} STATUS: ${order.status} TYPE: ${order.type} BRAND: ${order.brend} ADDRESS: ${order.adress}`,
              },
              token: technicianToken ? technicianToken.notifToken : ownerToken,
            })

            await getCompanyOrders({startDate});
            await getNotWorkersOrders();
            setOpenDialog(false);
          }} />
        </Grid>
      </Grid>
      {/* <Grid item md={1}>
        <TextField
          value={order.jobDescription}
          onChange={(e) => handleChange(e, "jobDescription")}
          variant="outlined"
          label="Job Description"
          fullWidth
          size="small"
          disabled={isDisabled}
        />
      </Grid> */}
      {/* <Grid item md={1}>
          <TextField
            value={order.fromTechnishion}
            onChange={(e) => handleChange(e, "fromTechnishion")}
            variant="outlined"
            label="From technishion"
            fullWidth
            size="small"
            disabled={isDisabled}
          />
        </Grid> */}
      {/* <Grid item md={1}>
        <TextField
          value={order.feedbackAboutCastomer}
          onChange={(e) => handleChange(e, "feedbackAboutCastomer")}
          variant="outlined"
          label="Feedback about castomer"
          fullWidth
          size="small"
          disabled={isDisabled}
        />
      </Grid> */}
       {/*<DescriptionComponent order={order} setOrder={setOrder} />*/}
    </>
  );
};

export default MainInfoComponent;
