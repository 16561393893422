import { Box, Dialog, DialogContent, DialogTitle, Grid, Paper, Typography } from "@mui/material"
import { blue, green } from "@mui/material/colors"
import { useState } from "react"
import { useStores } from "../../store"
import fbServices from "../../services/firebase";
import { dataBaseName } from "../../constants";
import { MoonLoader } from "react-spinners";

export const ManagerZipFilter = ({ managerItem }) => {
    const [isOpenDialog, setOpenDialog] = useState(false)

    const handleOpenTechList = () => setOpenDialog(true)

    return (
        <Grid item md={4}>
            <Paper
                onClick={handleOpenTechList}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    background: green[400],
                    cursor: 'pointer'
                }}
            >
                <Typography
                    sx={{ m: 1, fontSize: 13 }}
                    gutterBottom
                    variant="button"
                    component="div"
                >
                    ZIP FILTER
                </Typography>
            </Paper>
            <ZipList isOpenDialog={isOpenDialog} setOpenDialog={setOpenDialog} managerItem={managerItem} />
        </Grid>
    )
}

const ZipList = ({ isOpenDialog, setOpenDialog, managerItem }) => {
    const {
        userStore: { user, setUser, changeUserInfo },
        snackbarStore: { open, msg, setMsg, active, desactive },
        // initScreenStore: { setInit, init },
        companyInfoStore: { companyInfo, setCompanyInfoStore },
        techniciansStore: { technicians, setTechniciansStore },
    } = useStores()

    const zipPack = companyInfo?.zipPack?.split(',') || []

    const [activeZipCode, setActiveZipCode] = useState(companyInfo.workersData[managerItem.uid]?.activeZipPack || [])
    const [isLoading, setIsLoading] = useState(false)
    const [activeLoading, setActiveLoading] = useState('')

    const handleSetActiveZipCodeManager = async (value) => {
        await fbServices.updateDocItem("companies", companyInfo.company, {
            [`workersData.${managerItem.uid}.activeZipPack`]: value
        });
        await updateCompanyInfo();
    }

    const updateCompanyInfo = async () => {
        const companyInfoSnap = await fbServices.getDocItem(
            dataBaseName.COMPANIES,
            companyInfo.company,
        );
        setCompanyInfoStore(companyInfoSnap)
    }

    return <Dialog
        open={isOpenDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth={true}
        maxWidth={"md"}
    >
        <DialogTitle>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                ZIP CODE FILTERS
            </Box>
        </DialogTitle>
        <DialogContent
            // sx={{minHeight: '200px'}} 
            dividers>
            <Grid container spacing={1} columns={8} sx={{ alignItems: 'center', marginBottom: 1, }}>
                {zipPack.map((zipItem) => {
                    return (
                        <Grid key={zipItem} item md={1}>
                            <Box
                                onClick={async () => {
                                    if (isLoading) return
                                    setIsLoading(true)
                                    setActiveLoading(zipItem)
                                    let newActiveZipCode
                                    if (activeZipCode.includes(zipItem)) {
                                        newActiveZipCode = activeZipCode.filter((item) => item !== zipItem)
                                        setActiveZipCode(newActiveZipCode)
                                    } else {
                                        newActiveZipCode = [...activeZipCode, zipItem]
                                        setActiveZipCode(newActiveZipCode)
                                    }

                                    try {
                                        await handleSetActiveZipCodeManager(newActiveZipCode)
                                    } catch (error) {
                                        console.log('ERROR-handleSetActiveZipCodeManager', error);
                                    }
                                    setIsLoading(false)
                                    setActiveLoading('')
                                }}
                                sx={{
                                    margin: '1px',
                                    border: 1,
                                    borderColor: "#767676",
                                    padding: '3px',
                                    cursor: "pointer",
                                    // flexGrow: 1,
                                    // maxHeight: 40,
                                    minWidth: 50,
                                    textAlign: "center",
                                    borderRadius: 1,
                                    background: activeZipCode.includes(zipItem) ? blue[700] : 'white',
                                    // backgroundColor: ,// isCurrentTime ? blue[700] : 'white',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    "&:hover": {
                                        background: "grey",
                                        // primary
                                    },
                                }}
                            >
                                {isLoading && (zipItem === activeLoading) ? <MoonLoader size={17} /> : zipItem}
                                {/* {zipItem} */}
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>

        </DialogContent>
    </Dialog>
}