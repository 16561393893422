import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  forwardRef,
} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Container,
  Typography,
  Box,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  CardMedia,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Paper,
  Divider,
  TextareaAutosize,
  Grid,
  Modal,
  List,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import firebase from "../../services/firebase";
import LoadingButton from "@mui/lab/LoadingButton";
import { useStores } from "../../store";
import { TimesLine } from "./index";
import { getGeocode } from "../../services/apiService";
import {
  collection,
  addDoc,
  doc,
  getDocs,
  getDoc,
  deleteDoc,
  updateDoc,
  setDoc,
  query,
  where,
  arrayUnion,
} from "firebase/firestore";
import fbServices from "../../services/firebase";
import MyDatePicker from "./MyDatePicker";
// import { async } from "@firebase/util";
import { queryResolver } from "../../services/queryResolver";
import { pink, red, green, blue, grey, brown } from "@mui/material/colors";
import { customAlphabet } from "nanoid";
import { orderStatuses } from "../../constants";
import { useLocation } from "react-router-dom";
import moment from "moment";

const OrdersPhoto = ({ photoLinksParts, photoLinksJob }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");

  const phonoBlock = (photoLinks) => {
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {Array.isArray(photoLinks) &&
          photoLinks.map((item, index) => {
            return (
              <Paper
                onClick={() => {
                  setSelectedItem(item);
                  setOpenDialog(true);
                }}
                key={index}
                sx={{ padding: 1 }}
              >
                <CardMedia
                  component="img"
                  alt="img"
                  image={item}
                  sx={{
                    width: 170,
                    height: 170,
                    borderRadius: 1,
                    margin: 1,
                  }}
                />
              </Paper>
            );
          })}

        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          fullWidth={true}
          // maxWidth={"md"}
            maxWidth={'xl'}
        >
          <DialogContent dividers>
            <Paper
              sx={{
                padding: 1,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <CardMedia
                component="img"
                alt="img"
                image={selectedItem}
                sx={{
                  // width: 1400,
                  // height: 1400,
                  borderRadius: 1,
                  margin: 1,
                }}
              />
            </Paper>
          </DialogContent>
        </Dialog>
      </Box>
    );
  };

  return (
    <>
      {photoLinksParts?.length ? (
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Box>Photo parts</Box>
          {phonoBlock(photoLinksParts)}
        </Box>
      ) : null}

      {photoLinksJob?.length ? (
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Box>Photo jobs</Box>
          {phonoBlock(photoLinksJob)}
        </Box>
      ) : null}
    </>
  );
};

export default OrdersPhoto;
