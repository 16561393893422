import { useEffect, useState, Fragment, useRef, forwardRef } from "react";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Container,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Popover,
} from "@mui/material";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import IconButton from "@mui/material/IconButton";
import { useStores } from "../../store";
import fbServices from "../../services/firebase";
import { collection, addDoc, doc, setDoc, getDocs } from "firebase/firestore";
import LoadingButton from "@mui/lab/LoadingButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import GoogleMapReact from "google-map-react";
import { apiKey } from "../../services/apiService";
import { queryResolver } from "../../services/queryResolver";
import MyDatePicker from "../AddOrder/MyDatePicker";
import { observer } from "mobx-react-lite";
import RoomIcon from "@mui/icons-material/Room";
import { pink, red, green } from "@mui/material/colors";
import WorkerAvatar from "../Workers/WorkerAvatar";
import AddOrderDialog from "../AddOrder";

const Maps = observer((props) => {
  // console.log("Maps", props);
  const {
    userStore: { user, setUser, changeUserInfo },
    snackbarStore: { setMsg },
    workersStore: { updateWorkers, workers, setWorkers },
    ordersStore: { orders, setorders, updateOrders },
  } = useStores();

  const [startDate, setStartDate] = useState(new Date());

  const [isOpenDialog, setOpenDialog] = useState(false);
  const [init, setInit] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   console.log("workers", workers);
  // }, [workers]);

  useEffect(async () => {
    // console.log("useEffect", startDate);
    try {
      // setIsLoading(true);
      // if (user.company && !workers.length) {
      await getCompanyWorkers();
      await getCompanyOrders();
      // }
    } catch (error) {
      setMsg(error.message);
    } finally {
      // setIsLoading(false);
    }
    // console.log("workers", workers);
  }, [startDate, init]);

  const getCompanyWorkers = async () => {
    const workersCompany = await queryResolver(
      `${user.company}Users`,
      "role",
      "worker"
    );
    setWorkers(workersCompany);
    // console.log("workersCompany", workersCompany);
  };

  const getCompanyOrders = async () => {
    const orders = await queryResolver(
      `${
        user.company
      }Orders/${startDate.getFullYear()}/${startDate.getMonth()}`,
      "day",
      startDate.getDate()
    );
    setorders(orders);
    // console.log("orders", orders);
  };

  return (
    <Box sx={{ mt: 9 }}>
      <Container maxWidth="xl">
        <AddOrderDialog
          // {...props}
          isOpenDialog={isOpenDialog}
          setOpenDialog={setOpenDialog}
          setInit={setInit}
          init={init}
        />
        <MapsButtons
          startDate={startDate}
          setStartDate={setStartDate}
          isOpenDialog={isOpenDialog}
          setOpenDialog={setOpenDialog}
        />
        <MyMaps />
      </Container>
    </Box>
  );
});

export default Maps;

const MapsButtons = (props) => {
  const { setOpenDialog, isOpenDialog } = props;
  const {
    userStore: { user, setUser, changeUserInfo },
    snackbarStore: { setMsg },
    workersStore: { updateWorkers },
    ordersStore: { orders, setorders, updateOrders },
  } = useStores();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        mb: 1,
      }}
    >
      {/* <Typography sx={{ m: 2 }}>Заказов всех: {orders.length}</Typography>
      <Typography sx={{ m: 2 }}>
        Заказов с адрессом:{" "}
        {orders.filter((order) => order.latitude && order.longitude).length}
      </Typography> */}
      <Box sx={{ mr: 2 }}>
        <MyDatePicker {...props} />
      </Box>

      <ListWorker />
      <ListOrders />
      <Button
        onClick={() => setOpenDialog(true)}
        variant="contained"
        sx={{ mr: 2 }}
        size="small"
      >
        ADD ORDER
      </Button>
      {/* <Button size='small' variant="contained" sx={{ m: 2 }}>
        CONFURM
      </Button> */}
    </Box>
  );
};

const MyMaps = observer(() => {
  const {
    userStore: { user, setUser, changeUserInfo },
    snackbarStore: { setMsg },
    workersStore: { updateWorkers, workersMapSelect },
    ordersStore: { orders, setorders, updateOrders, ordersMapSelect },
  } = useStores();

  // TODO сделать центровку по маркерам и zoom
  const [mapState, setMapState] = useState({
    center: {
      lat: 26.4042654,
      lng: -80.1427929,
      // lat: orders.length
      //   ? orders.filter((order) => order.latitude && order.longitude)[0]
      //       ?.latitude
      //   : 26.4042654,
      // lng: orders.length
      //   ? orders.filter((order) => order.latitude && order.longitude)[0]
      //       ?.longitude
      //   : -80.1427929,
    },
    zoom: 11,
  });

  useState(() => {
    // console.log(
    //   "orders",
    //   orders.filter((order) => order.latitude && order.longitude)
    // );
    if (orders.filter((order) => order.latitude && order.longitude).length) {
      setMapState({
        center: {
          lat: orders.filter((order) => order.latitude && order.longitude)[0]
            .latitude,
          lng: orders.filter((order) => order.latitude && order.longitude)[0]
            .longitude,
        },
        zoom: 11,
      });
    }
  }, [orders]);

  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <GoogleMapReact
        // * YOUR KEY HERE */
        bootstrapURLKeys={{ key: apiKey }}
        defaultCenter={mapState.center}
        defaultZoom={mapState.zoom}
        yesIWantToUseGoogleMapApiInternals
      >
        {ordersMapSelect
          .filter((order) => order.latitude && order.longitude)
          .map((order) => {
            return (
              <Marker
                key={order.uid}
                lat={order.latitude}
                lng={order.longitude}
                order={order}
              />
            );
          })}
        {workersMapSelect
          .filter((worker) => worker.latitude && worker.longitude)
          .map((worker, i) => {
            return (
              <Marker
                key={worker.uid}
                lat={worker.latitude}
                lng={worker.longitude}
                worker={worker}
              />
            );
          })}
      </GoogleMapReact>
    </div>
  );
});

const Marker = (props) => {
  const { order, worker } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
    {/*TODO: Позиция маркера в точку */}
      <Box sx={{ position: "relative", top: -50, left: -25 }}>
        <IconButton
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          color="inherit"
          onClick={() => {
            // setIsOpen(true);
          }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          // sx={{border: 1}}
        >
          <RoomIcon
            sx={{ color: order ? green[400] : red[400] }}
            fontSize="large"
          />
        </IconButton>
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {order && (
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
              height: 150, // lineHeight,
              // overflow: "hidden",
              justifyContent: "center",
              minWidth: 250,
              border: 0.5,
              // TODO контроль статусов по цвету
              backgroundColor: order?.status === "new" ? "green" : "",
            }}
          >
            <Box sx={{ m: 2 }}>
              <Typography gutterBottom variant="h5" component="div">
                {order.uid}
              </Typography>
              <Typography
                component={"span"}
                variant="body2"
                color="text.secondary"
                noWrap
              >
                Brend: {order.brend}
                <Divider />
                Type: {order.type}
                <Divider />
                Address: {order.adress}
                <Divider />
                Description: {order.description}
              </Typography>
            </Box>
          </Paper>
        )}
        {worker && (
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
              height: 150, // lineHeight,
              // overflow: "hidden",
              justifyContent: "center",
              minWidth: 250,
              border: 0.5,
              // TODO контроль статусов по цвету
              backgroundColor: order?.status === "new" ? "green" : "",
            }}
          >
            <WorkerAvatar worker={worker} />
          </Paper>
        )}
      </Popover>
    </>
  );
};

const ListWorker = () => {
  const {
    userStore: { user, setUser, changeUserInfo },
    snackbarStore: { setMsg },
    workersStore: {
      updateWorkers,
      workers,
      setWorkers,
      workersMapSelect,
      setWorkerMapSelect,
    },
    ordersStore: { orders, setorders, updateOrders },
  } = useStores();

  const [selectedWorker, setSelectedWorker] = useState("ALL WORKERS");

  // useEffect(() => {
  //   console.log("workersMapSelect", workersMapSelect);
  // }, [workersMapSelect]);

  useEffect(() => {
    if (selectedWorker === "ALL WORKERS") {
      setWorkerMapSelect(workers);
    }
  }, [workers]);

  const handleChange = (event) => {
    setSelectedWorker(event.target.value);
    setWorkerMapSelect(
      event.target.value === "ALL WORKERS"
        ? workers
        : workers.filter((worker) => event.target.value === worker.displayName)
    );
  };

  return (
    <Box
      sx={{
        mr: 2,
        width: 200,
      }}
    >
      {/* <InputLabel id="worker-select">Worker</InputLabel> */}
      <Select
        size="small"
        // displayEmpty
        fullWidth
        labelId="worker-select"
        id="worker-select"
        value={selectedWorker}
        label="Worker"
        onChange={handleChange}
      >
        <MenuItem value={"ALL WORKERS"}>
          <em>ALL WORKERS</em>
        </MenuItem>
        {workers.map((worker) => {
          return (
            <MenuItem key={worker.uid} value={worker.displayName}>
              {worker.displayName}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};

const ListOrders = () => {
  const {
    userStore: { user, setUser, changeUserInfo },
    snackbarStore: { setMsg },
    workersStore: {
      updateWorkers,
      workers,
      setWorkers,
      workersMapSelect,
      setWorkerMapSelect,
    },
    ordersStore: {
      orders,
      setorders,
      updateOrders,
      ordersMapSelect,
      setOrdersMapSelect,
    },
  } = useStores();

  // console.log("orders", orders);
  const [selectedWorker, setSelectedWorker] = useState("ALL ORDERS");

  useEffect(() => {
    if (selectedWorker === "ALL ORDERS") {
      setOrdersMapSelect(
        orders.filter((order) => order.latitude && order.longitude)
      );
    }
  }, [orders]);

  // useEffect(() => {
  //   console.log("ordersMapSelect", ordersMapSelect);
  // }, [ordersMapSelect]);

  const handleChange = (event) => {
    setSelectedWorker(event.target.value);
    let orerListView = [];
    if (event.target.value === "ALL ORDERS") {
      orerListView = orders.filter(
        (order) => order.latitude && order.longitude
      );
    } else if (event.target.value === "ПУСТЫШКИ") {
      orerListView = orders.filter(
        (order) => order.latitude && order.longitude && !order.worker
      );
    } else {
      orerListView = orders.filter((order) => event.target.value === order.uid);
    }
    setOrdersMapSelect(orerListView);
    // setOrdersMapSelect(
    //   event.target.value === "ALL ORDERS"
    //     ? orders.filter((order) => order.latitude && order.longitude)
    //     : orders.filter((order) => event.target.value === order.uid)
    // );
  };
  return (
    <Box
      sx={{
        mr: 2,
        width: 200,
      }}
    >
      {/* <InputLabel id="worker-select">Orders List</InputLabel> */}
      <Select
        size="small"
        // displayEmpty
        fullWidth
        labelId="worker-select"
        id="worker-select"
        value={selectedWorker}
        label="Worker"
        onChange={handleChange}
      >
        <MenuItem value={"ALL ORDERS"}>
          <em>ALL ORDERS</em>
        </MenuItem>
        <MenuItem value={"ПУСТЫШКИ"}>
          <em>ПУСТЫШКИ</em>
        </MenuItem>
        {orders
          .filter((order) => order.latitude && order.longitude)
          .map((order) => {
            return (
              <MenuItem key={order.uid} value={order.uid}>
                {order.uid}
              </MenuItem>
            );
          })}
      </Select>
    </Box>
  );
};
