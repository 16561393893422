import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LoadingButton from "@mui/lab/LoadingButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useStores } from "../../store";
import { collection, addDoc, doc, setDoc } from "firebase/firestore";
import DatePicker from "react-datepicker";

import fbServices from "../../services/firebase";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
// import { getDoc } from "../../services/queryResolver";

const fieldsConfig = [
  {
    name: "Name",
    value: "firstName",
  },
  {
    name: "Last Name",
    value: "lastName",
  },
  {
    name: "Company Address",
    value: "companyAddress",
  },
  {
    name: "Company Zip Code",
    value: "companyZip",
  },
  {
    name: "Company Phone number",
    value: "phoneNumber",
    keyboardType: "numeric",
  },
  {
    name: "Company Name",
    value: "company",
  },
  {
    name: "Email *",
    value: "email",
  },
  {
    name: "Password *",
    value: "password",
  },
  {
    name: "Confirm Password *",
    value: "confirmPassword",
  },
];

export const times = [
  '0.00',// "12.00 AM",
  '1.00',// "01.00 AM",
  '2.00',// "02.00 AM",
  '3.00',// "03.00 AM",
  '4.00',// "04.00 AM",
  '5.00',// "05.00 AM",
  '6.00',// "06.00 AM",
  '7.00',// "07.00 AM",
  '8.00',// "08.00 AM",
  '9.00',// "09.00 AM",
  '10.00',// "10.00 AM",
  '11.00',// "11.00 AM",
  '12.00',// "12.00 PM",
  '13.00',// "01.00 PM",
  '14.00',// "02.00 PM",
  '15.00',// "03.00 PM",
  '16.00',// "04.00 PM",
  '17.00',// "05.00 PM",
  '18.00',// "06.00 PM",
  '19.00',// "07.00 PM",
  '20.00',// "08.00 PM",
  '21.00',// "09.00 PM",
  '22.00',// "10.00 PM",
  '23.00',// "11.00 PM",
];

export const times30 = [
  '0.00',// "12.00 AM",
  '0.50',// "12.30 AM",
  '1.00',// "01.00 AM",
  '1.50',// "01.30 AM",
  '2.00',// "02.00 AM",
  '2.50',// "02.30 AM",
  '3.00',// "03.00 AM",
  '3.50',// "03.30 AM",
  '4.00',// "04.00 AM",
  '4.50',// "04.30 AM",
  '5.00',// "05.00 AM",
  '5.50',// "05.30 AM",
  '6.00',// "06.00 AM",
  '6.50',// "06.30 AM",
  '7.00',// "07.00 AM",
  '7.50',// "07.30 AM",
  '8.00',// "08.00 AM",
  '8.50',// "08.30 AM",
  '9.00',// "09.00 AM",
  '9.50',// "09.30 AM",
  '10.00',// "10.00 AM",
  '10.50',// "10.30 AM",
  '11.00',// "11.00 AM",
  '11.50',// "11.30 AM",
  '12.00',// "12.00 PM",
  '12.50',// "12.30 PM",
  '13.00',// "01.00 PM",
  '13.50',// "01.30 PM",
  '14.00',// "02.00 PM",
  '14.50',// "02.30 PM",
  '15.00',// "03.00 PM",
  '15.50',// "03.30 PM",
  '16.00',// "04.00 PM",
  '16.50',// "04.30 PM",
  '17.00',// "05.00 PM",
  '17.50',// "05.30 PM",
  '18.00',// "06.00 PM",
  '18.50',// "06.30 PM",
  '19.00',// "07.00 PM",
  '19.50',// "07.30 PM",
  '20.00',// "08.00 PM",
  '20.50',// "08.30 PM",
  '21.00',// "09.00 PM",
  '21.50',// "09.30 PM",
  '22.00',// "10.00 PM",
  '22.50',// "10.30 PM",
  '23.00',// "11.00 PM",
  '23.50',// "11.30 PM",
];

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignUp({ goBack }) {
  const {
    snackbarStore: { setMsg },
  } = useStores();

  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    company: "",
    phoneNumber: "",
    companyZip: "",
    companyAddress: "",
    startWT: "",
    endWT: "",
    worksPerHour: 1,
  });
  console.log("user", user);

  const handleChange = (event, name) => {
    setUser({ ...user, [name]: event.target.value });
  };

  const [startTime, setStartTime] = useState("");

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              {fieldsConfig.map((field, i) => {
                return (
                  <Grid key={i} item xs={12}>
                    <TextField
                      size="small"
                      value={user[field.value]}
                      onChange={(e) => handleChange(e, field.value)}
                      variant="standard"
                      label={field.name}
                      fullWidth
                      required={field.required}
                    />
                  </Grid>
                );
              })}
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="standard"
                  sx={{ minWidth: 120 }}
                >
                  <InputLabel id="startWT">Start of working hours</InputLabel>
                  <Select
                    labelId="startWT"
                    id="demo-simple-select-standard"
                    value={user.startWT}
                    onChange={(e) => handleChange(e, "startWT")}
                    label="Start of working hours"
                  >
                    {times.map((time) => {
                      return (
                        <MenuItem key={time} value={time}>
                          {time}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="standard"
                  sx={{ minWidth: 120 }}
                >
                  <InputLabel id="endWT">End of working hours</InputLabel>
                  <Select
                    labelId="endWT"
                    id="demo-simple-select-standard"
                    value={user.endWT}
                    onChange={(e) => handleChange(e, "endWT")}
                    label="End of working hours"
                  >
                    {times.map((time) => {
                      return (
                        <MenuItem key={time} value={time}>
                          {time}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="standard"
                  sx={{ minWidth: 120 }}
                >
                  <InputLabel id="worksPerHour">Works per hour</InputLabel>
                  <Select
                    labelId="worksPerHour"
                    id="demo-simple-select-standard"
                    value={user.worksPerHour}
                    onChange={(e) => handleChange(e, "worksPerHour")}
                    label="End of working hours"
                  >
                    {[1, 2].map((time) => {
                      return (
                        <MenuItem key={time} value={time}>
                          {time}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {/* works per hour */}
            </Grid>
            <LoadingButton
              loading={isLoading}
              onClick={async () => {
                setIsLoading(true);
                try {
                  const patternTime = user.worksPerHour === 1 ? times : times30
                  const start = patternTime.findIndex((time) => time === user.startWT);
                  const end = patternTime.findIndex((time) => time === user.endWT);
                  const timesLine = patternTime.slice(start, end);

                  const authUser = await fbServices.createCompany({...user, timesLine, email: user.email.toLowerCase(), adminAsTechnician: true});
                  if (authUser.data?.errorInfo) {
                    throw new Error(authUser.data?.errorInfo.message);
                  }
                  if (typeof authUser.data === "string") {
                    throw new Error(authUser.data);
                  }
                  await fbServices.signInWithEmailAndPassword(
                    user.email.toLowerCase(),
                    user.password
                  );
                } catch (error) {
                  setMsg(error.message);
                  setIsLoading(false);
                }
                setIsLoading(false);
              }}
              variant="contained"
              fullWidth
              sx={{ my: 5 }}
            >
              Create User (admin)
            </LoadingButton>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link
                  href="#"
                  variant="body2"
                  onClick={() => {
                    goBack(false);
                  }}
                >
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
