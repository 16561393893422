import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  forwardRef,
  memo,
} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Container,
  Typography,
  Box,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  CardMedia,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Paper,
  Divider,
  TextareaAutosize,
  Grid,
  Modal,
  List,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import firebase from "../../services/firebase";
import LoadingButton from "@mui/lab/LoadingButton";
import { useStores } from "../../store";
import { TimesLine } from "./index";
import { getGeocode } from "../../services/apiService";
import {
  collection,
  addDoc,
  doc,
  getDocs,
  getDoc,
  deleteDoc,
  updateDoc,
  setDoc,
  query,
  where,
  arrayUnion,
} from "firebase/firestore";
import fbServices from "../../services/firebase";
import MyDatePicker from "./MyDatePicker";
// import { async } from "@firebase/util";
import { queryResolver } from "../../services/queryResolver";
import { pink, red, green, blue, grey, brown } from "@mui/material/colors";
import { customAlphabet } from "nanoid";
import { orderStatuses } from "../../constants";
import { useLocation } from "react-router-dom";
import moment from "moment";
import LoadingComponent from "../../Common/LoadingComponent";

const TimeLineCheckBox = (props) => {
  const {
    companyInfoStore: { companyInfo, setCompanyInfoStore },
  } = useStores();
  // console.log("TimeLineCheckBox-companyInfo", props, companyInfo.timesLine);

  const {
    timeLineDayWorker,
    setTimeLineDayWorker,
    disabledTimeSlot,
    // startedTimeOrder,
  } = props;

  const startedCheckTime = Object.keys(timeLineDayWorker).filter(
    (time) =>
      typeof timeLineDayWorker[time] === "boolean" &&
      timeLineDayWorker[time] !== disabledTimeSlot[time]
  )[0];
  // console.log('startedCheckTime', startedCheckTime);
  // console.log('parseFloat', parseFloat(startedCheckTime));

  const [tempDisabledSlot, setTempDisabledSlot] = useState(disabledTimeSlot);
  // const [lastValueTimeSlot, setLastValueTimeSlot] = useState(
  //   parseFloat(
  //     typeof startedTimeOrder === "object"
  //       ? startedTimeOrder.timeLine[0]
  //       : typeof startedTimeOrder === "string"
  //       ? startedTimeOrder
  //       : Object.keys(disabledTimeSlot)[0]
  //   )
  // );

  // useEffect(() => {
  //   if (!startedTimeOrder) {
  //     setTimeLineDayWorker({
  //       ...timeLineDayWorker,
  //       [Object.keys(disabledTimeSlot)[0]]: true,
  //     });
  //   }

  //   const temp = {};

  //   for (const key in tempDisabledSlot) {
  //     temp[key] = true;
  //   }

  //   if (startedTimeOrder && typeof startedTimeOrder === "string") {
  //     temp[startedTimeOrder] = false;
  //     temp[
  //       `${(
  //         parseFloat(startedTimeOrder) -
  //         1 / companyInfo.worksPerHour
  //       ).toFixed(2)}`
  //     ] = false;
  //     temp[
  //       `${(
  //         parseFloat(startedTimeOrder) +
  //         1 / companyInfo.worksPerHour
  //       ).toFixed(2)}`
  //     ] = false;
  //   }
  //   if (startedTimeOrder && typeof startedTimeOrder === "object") {
  //     temp[startedTimeOrder.timeLine[0]] = false;
  //     temp[
  //       `${(
  //         parseFloat(startedTimeOrder.timeLine[0]) -
  //         1 / companyInfo.worksPerHour
  //       ).toFixed(2)}`
  //     ] = false;
  //     temp[
  //       startedTimeOrder.timeLine[startedTimeOrder.timeLine.length - 1]
  //     ] = false;
  //     temp[
  //       `${(
  //         parseFloat(
  //           startedTimeOrder.timeLine[startedTimeOrder.timeLine.length - 1]
  //         ) +
  //         1 / companyInfo.worksPerHour
  //       ).toFixed(2)}`
  //     ] = false;
  //   }

  //   if (!startedTimeOrder) {
  //     temp["9.00"] = false;
  //     temp["8.00"] = false;
  //     temp["10.00"] = false;
  //     setLastValueTimeSlot(9);
  //   }
  //   setTempDisabledSlot(temp);
  // }, []);

  const handleChange = (event, name) => {
    setTimeLineDayWorker({
      ...timeLineDayWorker,
      [name]: !timeLineDayWorker[name],
    });
    // if (lastValueTimeSlot < parseFloat(name)) {
    //   setTempDisabledSlot({
    //     ...tempDisabledSlot,
    //     [`${(parseFloat(name) + 1 / companyInfo.worksPerHour).toFixed(
    //       2
    //     )}`]: false,
    //     [`${(parseFloat(name) - 1 / companyInfo.worksPerHour).toFixed(
    //       2
    //     )}`]: true,
    //   });
    // } else if (lastValueTimeSlot > parseFloat(name)) {
    //   setTempDisabledSlot({
    //     ...tempDisabledSlot,
    //     [`${(parseFloat(name) + 1 / companyInfo.worksPerHour).toFixed(
    //       2
    //     )}`]: true,
    //     [`${(parseFloat(name) - 1 / companyInfo.worksPerHour).toFixed(
    //       2
    //     )}`]: false,
    //   });
    // } else if (lastValueTimeSlot === parseFloat(name)) {
    //   setTempDisabledSlot({
    //     ...tempDisabledSlot,
    //     [`${(parseFloat(name) + 1 / companyInfo.worksPerHour).toFixed(2)}`]:
    //       !tempDisabledSlot[
    //         `${(parseFloat(name) + 1 / companyInfo.worksPerHour).toFixed(2)}`
    //       ],
    //     [`${(parseFloat(name) - 1 / companyInfo.worksPerHour).toFixed(2)}`]:
    //       !tempDisabledSlot[
    //         `${(parseFloat(name) - 1 / companyInfo.worksPerHour).toFixed(2)}`
    //       ],
    //   });
    // }
    // setLastValueTimeSlot(parseFloat(name));
  };

  // const handleChange = () => {}

  return (
    <>
      <FormGroup
        sx={{ display: "flex", justifyContent: "center" }}
        aria-label="position"
        row
      >
        {companyInfo.timesLine.map((el) => {
          return (
            <FormControlLabel
              sx={{ width: "5%" }}
              key={el}
              disabled={!!disabledTimeSlot[el]} // || tempDisabledSlot[el]}
              checked={!!timeLineDayWorker[el]}
              onChange={(e) => handleChange(e, el)}
              control={
                <Checkbox
                  size="small"
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={el}
              labelPlacement="top"
            />
          );
        })}
        {/* {Object.keys(timeLineDayWorker).map((el) => {
          return (
            <FormControlLabel
              sx={{ width: "10%" }}
              key={el}
              disabled={!!disabledTimeSlot[el] || tempDisabledSlot[el]}
              checked={!!timeLineDayWorker[el]}
              onChange={(e) => handleChange(e, el)}
              control={
                <Checkbox
                  size="small"
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={el}
              labelPlacement="top"
            />
          );
        })} */}
      </FormGroup>
    </>
  );
};

export default memo(TimeLineCheckBox);

// const MyComponent = React.memo(function MyComponent(props) {
//     /* render using props */
//   });
