import React, { useEffect, useState, Fragment, useRef, forwardRef } from "react";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Container,
  TextField, InputLabel, Select, MenuItem, FormControl,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { observer } from "mobx-react-lite";
import {
  collection,
  addDoc,
  doc,
  getDocs,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { useStores } from "../../store";
import fbServices from "../../services/firebase";
import LoadingComponent from "../../Common/LoadingComponent";
import DatePicker, { format } from "react-datepicker";
import MyDatePicker from "../AddOrder/MyDatePicker";
import WorkerAvatar from "./WorkerAvatar";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import IconButton from "@mui/material/IconButton";
import WorkingItem from "./WorkingItem";
import {
  filterDoc,
  getMoreDate,
  queryMultyResolver,
  queryResolver,
} from "../../services/queryResolver";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import WorkerLine from "./WorkerLine";
import AddOrderDialog from "../AddOrder";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import useLocalStorage from "../../hooks/useLocalStorageHook";
import { pink, red, green, blue, grey, brown } from "@mui/material/colors";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { getNotWorkersOrders } from "../../services/getNotWorkersOrders";
import PaymentsPeriodLine from "./PaymentsPeriodLine";
import WorkersHeader from "./WorkersHeader";
import { getCompanyOrders } from "../../services/getCompanyOrders";
import { ApiLeads } from "./ApiLeads";

const widthBoardConst = "100%";
export const widthItem = 90;

export const lineHeight = 70; // высота строки по работникам

const Workers = observer((props) => {
  const curentLocation = useLocation();

  const {
    userStore: { user, setUser, changeUserInfo },
    snackbarStore: { open, msg, setMsg, active, desactive },
    ordersStore: { orders, setorders, updateOrders },
    managersStore: { managers, setManagersStore },
    techniciansStore: { technicians, setTechniciansStore },
    companyInfoStore: { companyInfo, setCompanyInfoStore },
  } = useStores();
  // console.log('user', user);
  // console.log('companyInfo', companyInfo.workersData[user.uid].techniciansList);
  // console.log('orders', orders);
  // console.log('technicians', technicians);
  const techniciansList = companyInfo.workersData[user.uid].techniciansList || []

  const readyTechnicians = techniciansList?.length ? technicians.filter((technician) => techniciansList.includes(technician.uid)) : technicians
  // console.log('readyTechnicians', readyTechnicians);
  const [techniciansFilter, setTechniciansFilter] = useState({ worker: '' })
  // console.log('techniciansFilter', techniciansFilter);

  // const [dayShowArray, setDayShowArray] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [init, setInit] = useState(false);

  const [isOpenDialog, setOpenDialog] = useState(false);
  const [isSearchDialog, setIsSearchDialog] = useState(false);
  const [dayCount, setDayCount] = useLocalStorage("dayCount", 3);
  const [dayCountAfter, setDayCountAfter] = useLocalStorage("dayCountAfter", 0);
  const [startDate, setStartDate] = useLocalStorage("startDate", new Date());

  // console.log('new Date(startDate)', new Date(startDate))

  useEffect(async () => {
    try {
      setIsLoading(true);
      await getCompanyOrders({ startDate });
    } catch (error) {
      console.log("!!error", error);
      setMsg(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [startDate, init]);

  const addDayToCurrentDate = (days) => {
    let currentDate = new Date(startDate);
    return new Date(currentDate.setDate(currentDate.getDate() + days));
  };

  const addDayToCurrentDateBefore = (days) => {
    let currentDate = new Date(startDate);
    return new Date(currentDate.setDate(currentDate.getDate() - days));
  };

  // const getCompanyOrders = async () => {
  //   const dayShowArray = Array.from({ length: dayCount }, (v, k) => {
  //     const curentDay = addDayToCurrentDate(k + 1);
  //     return curentDay;
  //   });
  //   setDayShowArray(dayShowArray);
  //
  //   const companyOrders = [];
  //   const startShowDay = fbServices.Timestamp.fromDate(
  //     new Date(
  //       moment(startDate)
  //         .subtract(11, "days")
  //         .startOf("day")
  //         .format("YYYY-MM-DD")
  //     )
  //   );
  //   // все заказы от указанного дня и старше
  //   const options = [
  //     {
  //       field: "dateCreate",
  //       operator: ">",
  //       value: startShowDay,
  //     },
  //     {
  //       field: "company",
  //       operator: "==",
  //       value: companyInfo.company,
  //     },
  //   ];
  //
  //   const orders = await queryMultyResolver(`orders`, options);
  //   companyOrders.push(...orders);
  //   setorders(companyOrders);
  // };

  if (!technicians.length) {
    return (
      <Paper
        sx={{
          pt: 20,
          display: "flex",
          flexDirection: "column",
          height: 240,
          // overflow: "hidden",
        }}
      >
        {' '}
      </Paper>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <>
        {isOpenDialog ? (
          <AddOrderDialog
            isOpenDialog={isOpenDialog}
            setOpenDialog={setOpenDialog}
            setInit={setInit}
            isSearch={isSearchDialog}
          />
        ) : null}

        <Box sx={{ m: 1, mt: 9, display: "flex" }}>
          <Box sx={{ minWidth: 160 }}></Box>
          {curentLocation.pathname === "/" && (
            <Button
              variant="contained"
              onClick={() => {
                setIsSearchDialog(false);
                setOpenDialog(true);
              }}
              fullWidth
              size="small"
              sx={{
                width: 154,
                minWidth: 154,
              }}
            >
              Add Order
            </Button>
          )}
          <Box
            sx={{
              display: "flex",
              width: "300px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                dayCountAfter > -10 && setDayCountAfter(dayCountAfter - 1);
              }}
              sx={{ minWidth: 25, ml: 1 }}
            >
              -
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                dayCountAfter < -1 && setDayCountAfter(dayCountAfter + 1);
              }}
              sx={{ minWidth: 25, ml: 1 }}
            >
              +
            </Button>
            <Box
              sx={{
                border: 1,
                height: "100%",
                width: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 1,
              }}
            >
              <Typography sx={{ fontSize: 25 }}>{dayCountAfter + 1}</Typography>
            </Box>
            <Typography sx={{ marginLeft: 1 }}> DAYS </Typography>
            <Box
              sx={{
                border: 1,
                height: "100%",
                width: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 1,
              }}
            >
              <Typography sx={{ fontSize: 25 }}>{dayCount}</Typography>
            </Box>
            <Button
              variant="contained"
              onClick={() => {
                dayCount < 15 && setDayCount(dayCount + 1);
              }}
              sx={{ minWidth: 25, ml: 1 }}
            >
              +
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                dayCount > 1 && setDayCount(dayCount - 1);
              }}
              sx={{ minWidth: 25, ml: 1, mr: 1 }}
            >
              -
            </Button>
          </Box>

          <Button
            variant="contained"
            onClick={() => {
              setIsSearchDialog(true);
              setOpenDialog(true);
            }}
            fullWidth
            size="small"
            sx={{
              width: 154,
              minWidth: 154,
            }}
          >
            Search
          </Button>
          <Box sx={{ width: 154, minWidth: 154, marginLeft: 1 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="filter-worker-select-label">FILTER</InputLabel>
              <Select
                fullWidth
                labelId="filter-worker-select-label"
                id="filter-worker-select"
                value={techniciansFilter.worker}
                label="FILTER"
                onChange={(e) => {
                  setTechniciansFilter({ worker: e.target.value })
                }}
                sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                    borderWidth: 2,
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                    borderWidth: 2,
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                    borderWidth: 2,
                  },
                }}
              >
                <MenuItem value={''}>
                  <em>All TECH</em>
                </MenuItem>
                {readyTechnicians.map((worker) => {
                  return (
                    <MenuItem key={worker.uid} value={worker.displayName}>
                      {worker.displayName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          {/*<Button*/}
          {/*    variant="contained"*/}
          {/*    onClick={async () => {*/}
          {/*        const message = {*/}
          {/*            notification: {*/}
          {/*                title: '`$FooCorp` up 1.43% on the day',*/}
          {/*                body: 'FooCorp gained 11.80 points to close at 835.67, up 1.43% on the day.'*/}
          {/*            },*/}
          {/*            token: 'dyQoub2CQ4mow9rdFl1LXd:APA91bGSKJ02b71zYnw6QQWGdR0YYKuw-FTpz7HqPi23lpxgZVTGhDUcBQmObSnOpLy8a-V40LB913KY5V3C58J4nv7tK4c6JT-hiL-0fX1CH4jW68RIjxS87axJoHj22EG1O2uk0Rlh'*/}
          {/*        };*/}
          {/*        try {*/}
          {/*            const temp = await fbServices.sendPush(message)*/}
          {/*            console.log('temp', temp)*/}
          {/*        } catch (e) {*/}
          {/*            console.log('ERROR-sendPush', e)*/}
          {/*        }*/}

          {/*    }}*/}
          {/*    fullWidth*/}
          {/*    size="small"*/}
          {/*    sx={{*/}
          {/*        width: 154,*/}
          {/*        minWidth: 154,*/}
          {/*    }}*/}
          {/*>*/}
          {/*    TEST PUSH*/}
          {/*</Button>*/}

        </Box>
        {Array.from({ length: dayCountAfter * -1 }, (v, k) => k + 1)
          .reverse()
          .map((line) => {
            if (line === 1) {
              return null;
            }
            return (
              <Box key={line}>
                <WorkersHeader
                  startDate={startDate}
                  setStartDate={setStartDate}
                  line={line}
                  addDayToCurrentDate={addDayToCurrentDateBefore}
                />
                {readyTechnicians.filter((tech) => {
                  if (techniciansFilter.worker === '') return true
                  return techniciansFilter.worker === tech.displayName;
                }).map((technician) => {
                  const filtredOrder = orders.filter(
                    (order) =>
                      order.workerUid === technician.uid &&
                      order.day ===
                      addDayToCurrentDateBefore(line - 1).getDate() &&
                      order.month ===
                      addDayToCurrentDateBefore(line - 1).getMonth() &&
                      order.year ===
                      addDayToCurrentDateBefore(line - 1).getFullYear()
                  );
                  if (isLoading) {
                    return <LoadingComponent key={technician.uid} />;
                  } else {
                    if (line === 1) {
                      return (
                        <WorkerLine
                          {...props}
                          key={technician.uid}
                          worker={technician}
                          startDate={new Date(startDate)}
                          workOrders={filtredOrder}
                          setInit={setInit}
                          init={init}
                        />
                      );
                    } else {
                      return (
                        <WorkerLine
                          {...props}
                          key={technician.uid}
                          worker={technician}
                          startDate={addDayToCurrentDateBefore(line - 1)}
                          workOrders={filtredOrder}
                          setInit={setInit}
                          init={init}
                        />
                      );
                    }
                  }
                })}
              </Box>
            );
          })}

        {Array.from({ length: dayCount }, (v, k) => k + 1).map((line) => {
          return (
            <Box key={line}>
              <WorkersHeader
                startDate={startDate}
                setStartDate={setStartDate}
                line={line}
                addDayToCurrentDate={addDayToCurrentDate}
              />
              {readyTechnicians.filter((tech) => {
                if (techniciansFilter.worker === '') return true
                return techniciansFilter.worker === tech.displayName;
              }).map((technician) => {
                const filtredOrder = orders.filter(
                  (order) =>
                    order.workerUid === technician.uid &&
                    order.day === addDayToCurrentDate(line - 1).getDate() &&
                    order.month === addDayToCurrentDate(line - 1).getMonth() &&
                    order.year === addDayToCurrentDate(line - 1).getFullYear()
                );
                if (isLoading) {
                  return <LoadingComponent key={technician.uid} />;
                } else {
                  if (line === 1) {
                    return (
                      <WorkerLine
                        {...props}
                        key={technician.uid}
                        worker={technician}
                        startDate={new Date(startDate)}
                        workOrders={filtredOrder}
                        setInit={setInit}
                        init={init}
                      />
                    );
                  } else {
                    return (
                      <WorkerLine
                        {...props}
                        key={technician.uid}
                        worker={technician}
                        startDate={addDayToCurrentDate(line - 1)}
                        workOrders={filtredOrder}
                        setInit={setInit}
                        init={init}
                      />
                    );
                  }
                }
              })}
            </Box>
          );
        })}

        {curentLocation.pathname === "/" && (
          <Box sx={{ height: lineHeight * 2 }}></Box>
        )}

        {curentLocation.pathname === '/payments' && (
          <PaymentsPeriodLine
            dayCount={dayCount}
            dayCountAfter={dayCountAfter}
            addDayToCurrentDateBefore={addDayToCurrentDateBefore}
            addDayToCurrentDate={addDayToCurrentDate}
            techniciansFilter={techniciansFilter}
          />
        )}

        {curentLocation.pathname === "/" && (
          <FooterOrders setInit={setInit} init={init} />
        )}
      </>
    </DndProvider>
  );
});

export default Workers;

const FooterOrders = observer((props) => {
  const {
    userStore: { user, setUser, changeUserInfo },
    companyInfoStore: { companyInfo, setCompanyInfoStore },
    ordersStore: { notWorkersOrders },
  } = useStores();

  const start = moment()
    .subtract(60, "days")
    .startOf("day")
    .format("YYYY-MM-DD");

  const end = moment().add(60, "days").startOf("day").format("YYYY-MM-DD");

  const [footerHeight, setFooterHeight] = useState(false);
  // const [notWorkersOrdersTwoMonth, setNotWorkersOrdersTwoMonth] = useState([]);
  const [startDate, setStartDate] = useState(new Date(start));
  const [endDate, setEndDate] = useState(new Date(end));

  const [isMonth, setIsMonth] = useState(false);

  // const getAllNotWorkerOrders = async () => {
  //   // TODO: получить заказы без мастера или с пустым таймлайном не старше 60 дней от указанной даты
  //
  //   const companyOrders = [];
  //   const startShowDay = fbServices.Timestamp.fromDate(
  //     new Date(moment(startDate).startOf("day"))
  //   );
  //   const endShowDay = fbServices.Timestamp.fromDate(
  //     new Date(moment(endDate).endOf("day"))
  //   );
  //   // все заказы от указанного дня и старше
  //   const options = [
  //     {
  //       field: "dateCreate",
  //       operator: ">",
  //       value: startShowDay,
  //     },
  //     {
  //       field: "dateCreate",
  //       operator: "<",
  //       value: endShowDay,
  //     },
  //     {
  //       field: "company",
  //       operator: "==",
  //       value: companyInfo.company,
  //     },
  //     {
  //       field: "workerUid",
  //       operator: "==",
  //       value: "",
  //     },
  //   ];
  //
  //   const orders = await queryMultyResolver(`orders`, options);
  //   companyOrders.push(...orders);
  //
  //   setNotWorkersOrdersTwoMonth(companyOrders);
  // };

  useEffect(() => {
    // getAllNotWorkerOrders();

    getNotWorkersOrders();
  }, [startDate, endDate]);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "box",
    drop: () => {
      console.log('DROP')
      // getAllNotWorkerOrders()
      getNotWorkersOrders()
      return { ...props, isClearFooter: true };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <Box
      ref={drop}
      data-testid="dustbin"
      sx={{
        position: "fixed",
        bottom: 0,
        backgroundColor: "#FEFBFB",
        width: "100%",
        // height: footerHeight + 40,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pr: 2,
          pl: 2,
          borderTop: 1,
        }}
        onMouseOver={() => setFooterHeight(lineHeight)}
      // onMouseOut={() => setFooterHeight(0)}
      >
        <Typography sx={{ color: 'red' }}>NEW JOBS</Typography>
        {footerHeight ? (
          <IconButton
            sx={{ padding: 0 }}
            color="inherit"
            onClick={() => {
              setFooterHeight(false);
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        ) : (
          <IconButton
            sx={{ padding: 0 }}
            color="inherit"
            onClick={() => {
              setFooterHeight(true);
            }}
          >
            <ExpandLessIcon />
          </IconButton>
        )}
      </Box>
      {footerHeight ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              // mb: 1,
              flexWrap: "wrap",
            }}
          >
            {/* TODO переключастель дат */}
            {/* <Grid item xs={1} sx={{ marginRight: 1 }}>
            <Box>
              <Typography noWrap sx={{ fontSize: 12, maxWidth: "100%" }}>
                From:
              </Typography>

              <MyDatePicker
                startDate={new Date(startDate)}
                setStartDate={setStartDate}
              />
            </Box>

            <Box>
              <Typography noWrap sx={{ fontSize: 12, maxWidth: "100%" }}>
                To:
              </Typography>

              <MyDatePicker
                startDate={new Date(endDate)}
                setStartDate={setEndDate}
              />
            </Box>
          </Grid> */}

            {notWorkersOrders.length ? (
              notWorkersOrders.map((el) => {
                return (
                  <Grid
                    key={el.uid}
                    item
                    xs={1}
                    sx={{
                      borderRadius: 2,
                      width: widthItem,
                      marginBottom: 1,
                      marginLeft: 1,
                    }}
                  >
                    <WorkingItem
                      // init={props.init} setInit={props.setInit}
                      orderView={el} />
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={1}>
                <Box
                  data-testid="dustbin"
                  color="inherit"
                  sx={{
                    width: 90,
                    height: 90,
                    backgroundColor: '#F2f2f2',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: 1,
                    borderColor: grey[300],
                    margin: 1,
                    marginLeft: 2,
                    borderRadius: 1,
                  }}
                >EMPTY</Box>

                {/*<Typography>NOT CLEAR ORDERS</Typography>*/}
              </Grid>
            )}
          </Box>
          <ApiLeads />
        </>
      ) : null}
      {/* <Box>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <MyDatePicker
              {...props}
              // startDate={startDate}
              startDate={startDate}
              setStartDate={setStartDate}
              notWorkersOrdersTwoMonth={notWorkersOrdersTwoMonth}
              // getAllNotWorkerOrders={getAllNotWorkerOrders}
              // isNotWorkersPicker
              setNotWorkersOrdersTwoMonth={setNotWorkersOrdersTwoMonth}
              isMonth={isMonth}
              setIsMonth={setIsMonth}
            />
          </Grid>


          {notWorkersOrdersTwoMonth.length ? (
            notWorkersOrdersTwoMonth
              .filter((el) => (isMonth ? true : el.day === startDate.getDate()))
              .map((el) => {
                // console.log("el", el);
                // console.log(startDate.getDate());
                return (
                  <Grid key={el.uid} item xs={1}>
                    <WorkingItem orderView={el} />
                  </Grid>
                );
              })
          ) : (
            <Grid item xs={4}>
              <Typography>NOT CLEAR ORDERS</Typography>
            </Grid>
          )}
        </Grid>
      </Box> */}
    </Box>
  );
});
