import {
    Button,
    Paper,
    Grid,
    Typography,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    TextareaAutosize,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ManagerSettingItem from './ManagerSettingItem';
import { useStores } from "../../store";
import { MoonLoader } from "react-spinners";
import { red, green, grey } from "@mui/material/colors";
import { useState } from "react";
import { dataBaseName } from "../../constants";
import fbServices from "../../services/firebase";

export const ZipCodesField = () => {
    const {
        userStore: { user, setUser, changeUserInfo },
        snackbarStore: { open, msg, setMsg, active, desactive },
        // initScreenStore: { setInit, init },
        companyInfoStore: { companyInfo, setCompanyInfoStore },
    } = useStores()

    const [isLoading, setIsLoading] = useState(false)
    const [isOpenDialog, setOpenDialog] = useState(false);

    return (
        <Grid columns={2} container spacing={1} sx={{ pb: 2, display: 'flex', justifyContent: 'center' }}>
            <Grid item xs={2}>
                <Paper
                    onClick={async () => {
                        setOpenDialog(true)
                        // if (isDisable) return
                        // setIsLoading(true)
                        // try {
                        //     await handleHideNavLink(linkName, !navLinkIsShow(linkName))
                        // } catch (e) {
                        //     console.log('ERROR-handleHideNavLink', e)
                        // }
                        // setIsLoading(false)
                    }}
                    sx={{
                        display: 'flex',
                        // justifyContent: 'space-around',
                        alignItems: 'center',
                        cursor: 'pointer',
                        background: green[400],
                        minWidth: 137,
                    }}
                >
                    <Typography
                        sx={{ m: 1, fontSize: 13 }}
                        gutterBottom
                        variant="button"
                        component="div"
                    >
                        Zip code job filter:
                    </Typography>
                    {isLoading ? (<MoonLoader size={20} />) : (
                        <Typography
                            sx={{ m: 1, fontSize: 13 }}
                            gutterBottom
                            variant="button"
                            component="div"
                        >
                            {companyInfo.zipPack}
                            {/* {isDisable ? 'DISABLE' : navLinkIsShow(linkName) ? 'OFF' : 'ON'} */}
                        </Typography>
                    )}
                </Paper>
                {/* <div onClick={() => console.log('123')}>
                <TextField
                    // value={companySettings.company}
                    // onChange={(e) => handleChange(e.target.value, "company")}
                    label="Zip pack"
                    fullWidth
                    // required
                    hiddenLabel
                    size="small"
                    disabled={true}
                    variant="outlined"
                    
                />
                </div> */}
                {/* <ManagerSettingItem manager={user} title='MAP' linkName='map' isDisable /> */}
            </Grid>
            {isOpenDialog ? (
                <ZipDialog isOpenDialog={isOpenDialog} setOpenDialog={setOpenDialog} />
            ) : null}
        </Grid>
    )
}

const ZipDialog = ({ isOpenDialog, setOpenDialog, }) => {
    const {
        companyInfoStore: { companyInfo, setCompanyInfoStore },
    } = useStores();

    const [isLoading, setIsLoading] = useState(false)
    const [zipData, setZipData] = useState(companyInfo.zipPack || '')

    const handleApply = async () => {
        setIsLoading(true)
        try {
            await fbServices.updateDocItem("companies", companyInfo.company, {
                zipPack: zipData
            });

            await updateCompanyInfo();
        } catch (error) {
            
        }
        setIsLoading(false)
        setOpenDialog(false)
    }

    const updateCompanyInfo = async () => {
        const companyInfoSnap = await fbServices.getDocItem(
            dataBaseName .COMPANIES,
            companyInfo.company,
        );
        setCompanyInfoStore(companyInfoSnap)
    }

    const handleChange = (value) => {
        console.log('value', value);
        setZipData(value)
        // setCompanySettings({ ...companySettings, [name]: value });
      };

    return (
        <Dialog
            open={isOpenDialog}
            onClose={() => setOpenDialog(false)}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    ZIP CODE JOB FILTER
                </Box>
            </DialogTitle>
            <DialogContent
                // sx={{minHeight: '200px'}} 
                dividers>
                <TextField
                    fullWidth
                    // multiline
                    InputProps={{
                        inputComponent: TextareaAutosize,
                        minRows: 3,
                        // minWidth: '200px',
                        // maxRows: 2,
                    }}
                    // id={field.value}
                    label={"15001, 16002, 17001, ..."}
                    variant="outlined"
                // sx={{minHeight: '200px'}} 
                // size="small"
                value={zipData}
                onChange={(e) => handleChange(e.target.value)}
                />
                {/* <TextareaAutosize minRows={3} /> */}
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={() => setOpenDialog(false)}>FILTER ENABLE</Button> */}
                <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                <LoadingButton loading={isLoading} onClick={handleApply}>APPLY</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}