import { makeAutoObservable } from "mobx";

class workers {
  workers = [];

  constructor() {
    makeAutoObservable(this);
  }

  setWorkers = (workers) => {
    this.workers = workers;
  };

  updateWorkers = (newWorker) => {
    this.workers.push(newWorker)
  }

  workersMapSelect = []

  setWorkerMapSelect = (workers) => {
    this.workersMapSelect = workers;
  };
}

export default workers;
