import { useRef, forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import moment from "moment";
import { useStores } from "../../store";
import {
  collection,
  addDoc,
  doc,
  getDocs,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { queryMultyResolver, queryResolver } from "../../services/queryResolver";
import fbServices from "../../services/firebase";

import "react-datepicker/dist/react-datepicker.css";

const MyDatePicker = (props) => {
  // console.log("MyDatePicker", props);

  const {
    userStore: { user, setUser, changeUserInfo },
    companyInfoStore: { companyInfo, setCompanyInfoStore },
  } = useStores();

  const {
    startDate,
    setStartDate,
    notWorkersOrdersTwoMonth,
    isNotWorkersPicker,
    setNotWorkersOrdersTwoMonth,
    isMonth,
    setIsMonth,
    init,
      isAddOrder,
  } = props;
    // console.log("MyDatePicker", startDate);
    // console.log('1', typeof startDate)
    // console.log('2', new Date(startDate).getMonth())
    // console.log('3', Date.parse(startDate))
    // console.log('4', new Date(Date.parse(startDate)).getMonth())
    // console.log('5', new Date().parseISO(startDate))

  const [curentMonth, setCurentMonth] = useState(new Date(Date.parse(startDate)).getMonth());

  const ref = useRef();

  const getAllNotWorkerOrders = async (startDate) => {
    // TODO: получить заказы без мастера или с пустым таймлайном не старше 60 дней от указанной даты


    const companyOrders = [];
    const startShowDay = fbServices.Timestamp.fromDate(
      new Date(moment(startDate).subtract(60, "days").startOf("day").format("YYYY-MM-DD"))
    );
    // все заказы от указанного дня и старше
    const options = [
      {
        field: "dateCreate",
        operator: ">",
        value: startShowDay,
      },
      {
        field: "company",
        operator: "==",
        value: companyInfo.company,
      },
      {
        field: "workerUid",
        operator: "==",
        value: '',
      },
    ];

    const orders = await queryMultyResolver(`orders`, options);
    companyOrders.push(...orders);

    setNotWorkersOrdersTwoMonth(companyOrders);
    // setIsMonth(true)
  };

  useEffect(async () => {
    // isNotWorkersPicker && (await getAllNotWorkerOrders(startDate));
  }, [startDate, init]);

  // useEffect(() => {
  //   // console.log('startDate', startDate);
  //   console.log('notWorkersOrdersTwoMonth', notWorkersOrdersTwoMonth);
  // }, [startDate])

  // useEffect(() => {
  //   // console.log('startDate', startDate);
  //   console.log("notWorkersOrdersTwoMonth", notWorkersOrdersTwoMonth);
  // }, [notWorkersOrdersTwoMonth]);

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      size="small"
      onClick={onClick}
      ref={ref}
      variant="contained"
      sx={{ width: "100%", ...(isAddOrder ? {height: 38} : {}) }}
    >
      {/* {console.log('value', value)} */}
      {value}
    </Button>
  ));

  // const ordersDaysArray =
  //   notWorkersOrdersTwoMonth &&
  //   notWorkersOrdersTwoMonth.map((order) => order.day);

  const clearCount = (arr, date) => {
    if (arr.includes(date.getDate()) && date.getMonth() === curentMonth) {
      return arr.filter((el) => el === date.getDate()).length;
    }
  };

  const renderDayContents = (day, date) => {
    if (!isNotWorkersPicker) return <span>{day}</span>;

    return (
      <Box
        sx={{
          border: 1,
          borderColor: "grey.500",
          margin: "-0.166rem",
          height: 50,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {notWorkersOrdersTwoMonth.map((order) => order.day).includes(day) ? (
          <>
            <Typography>{day}</Typography>
            <Typography sx={{ color: "red", backgroundColor: "yellow" }}>
              {clearCount(
                notWorkersOrdersTwoMonth.map((order) => order.day),
                date
              )}
            </Typography>
          </>
        ) : (
          <>
            <Typography>{day}</Typography>
          </>
        )}
      </Box>
    );
  };

  const renderCustomHeader = (datePickerData) => {
    // getAllNotWorkerOrders
    // console.log("datePickerData", datePickerData);
    return (
      <Box
        sx={{
          // backgroundColor: "yellow",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <IconButton
          color="inherit"
          onClick={async () => {
            // setIsOpen(true);
            // console.log("datePickerData.monthDate", datePickerData.monthDate);
            const monthAgo = new Date(
              datePickerData.monthDate.setMonth(
                datePickerData.monthDate.getMonth() - 1
              )
            );
            setCurentMonth(monthAgo.getMonth());

            await getAllNotWorkerOrders(monthAgo);
            // console.log("notWorkersOrdersTwoMonth", notWorkersOrdersTwoMonth);
            setStartDate(monthAgo);
            setIsMonth(true);

            datePickerData.decreaseMonth();
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <Button
          variant="contained"
          sx={{ m: 1 }}
          onClick={() => {
            setIsMonth(true);
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            onClick={() => {
              setIsMonth(true)
              setStartDate(datePickerData.date)
            }}
          >
            {datePickerData.monthDate.toLocaleString("en-US", {
              month: "long",
              year: "numeric",
            })}
          </Typography>
        </Button>

        <IconButton
          color="inherit"
          onClick={async () => {
            // setIsOpen(true);
            const monthNext = new Date(
              datePickerData.monthDate.setMonth(
                datePickerData.monthDate.getMonth() + 1
              )
            );
            setCurentMonth(monthNext.getMonth());

            await getAllNotWorkerOrders(monthNext);
            // console.log("notWorkersOrdersTwoMonth", notWorkersOrdersTwoMonth);
            setStartDate(monthNext);
            setIsMonth(true);

            datePickerData.increaseMonth();
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    );
  };

  return isNotWorkersPicker ? (
    <DatePicker
      selected={startDate}
      onChange={(date) => {
        setStartDate(date);
        setIsMonth(false);
        window.localStorage.setItem("startDateNotWorker", date)
      }}
      customInput={<CustomInput ref={ref} />}
      withPortal
      dateFormat={isMonth ? "yyyy/MM" : "yyyy/MM/dd"}
      renderDayContents={renderDayContents}
      renderCustomHeader={renderCustomHeader}
    />
  ) : (
    <DatePicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      customInput={<CustomInput ref={ref} />}
      withPortal
      dateFormat="MMM/dd/yyyy"
      renderDayContents={renderDayContents}
    />
  );
};

export default MyDatePicker;
