import { useRef, forwardRef, useEffect, useState } from "react";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Container,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  CardActions,
  Rating,
  Popover,
} from "@mui/material";
import { widthItem } from ".";

const WorkerAvatar = ({ worker }) => {
  const [value, setValue] = useState(2);

  const noImg =
    "https://previews.123rf.com/images/urfandadashov/urfandadashov1806/urfandadashov180601827/150417827-photo-not-available-vector-icon-isolated-on-transparent-background-photo-not-available-logo-concept.jpg?fj=1";

  const src = worker.photoURL;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // return (
  //   <>
  //     {/* <img src={worker.photoURL} /> */}
  //     <p>{worker.email}</p>
  //     <p>{worker.displayName}</p>
  //   </>
  // );

  return (
    <Box
      sx={{
        display: "flex",
        maxWidth: 170,
      }}
    >
      <CardMedia
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={(e) => {
          handlePopoverOpen(e);
        }}
        onMouseLeave={handlePopoverClose}
        component="img"
        alt="img"
        // TODO: синхронизировать размер картинки с мобилкой
        // height="20"
        image={src ? src : noImg} // `https://source.unsplash.com/random?count=${index}`
        // sx={{objectFit: 'contain'}}
        sx={{
          width: widthItem - 5,
          // height: "80%",
          height: widthItem - 16,
          borderRadius: 1,
          margin: 1,
          // objectFit: "cover",
        }}
      />
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "11rem",
          marginTop: 5,
          marginBottom: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: 'space-between'
        }}
      >
        <Typography noWrap sx={{ fontSize: 12, maxWidth: "100%" }}>
          {worker.displayName}
        </Typography>
        <Typography noWrap sx={{ fontSize: 12, maxWidth: "100%" }}>
          {worker.email}
        </Typography>
        <Typography noWrap sx={{ fontSize: 12, maxWidth: "100%" }}>
          {worker.phoneNumber}
        </Typography>
      </div>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Card
          sx={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            p: 1,
          }}
        >
          <CardMedia
            component="img"
            alt="green iguana"
            // TODO: синхронизировать размер картинки с мобилкой
            height="140"
            image={src ? src : noImg} // `https://source.unsplash.com/random?count=${index}`
            sx={{borderRadius: 1}}
          />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              alignItems: "start",
            }}
          >
            <Typography noWrap gutterBottom variant="h6" component="div">
              {worker.displayName}
            </Typography>
            <Typography
              component={"span"}
              variant="body1"
              color="text.secondary"
            >
              <Divider />
              Email: {worker.email}
              <Divider />
              Phone: {worker.phoneNumber}
              <Divider />
            </Typography>
            <Rating name="read-only" value={value} readOnly />
          </CardContent>
        </Card>
      </Popover>
    </Box>
  );

  return (
    <Card
      sx={{
        display: "flex",
        // justifyContent: "center",
        alignItems: "center",
        m: 1,
      }}
    >
      <CardMedia
        component="img"
        alt="green iguana"
        // TODO: синхронизировать размер картинки с мобилкой
        height="120"
        image={src ? src : noImg} // `https://source.unsplash.com/random?count=${index}`
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
          alignItems: "start",
        }}
      >
        <Typography noWrap gutterBottom variant="h6" component="div">
          {worker.displayName}
        </Typography>
        <Typography component={"span"} variant="body1" color="text.secondary">
          <Divider />
          Email: {worker.email}
          <Divider />
          Phone: {worker.phone}
          <Divider />
        </Typography>
        <Rating name="read-only" value={value} readOnly />
      </CardContent>
    </Card>
  );

  if (!worker.photoURL) {
    return (
      <Box>
        <p>NOT PHOTO</p>
        <p>{worker.email}</p>
        <p>{worker.displayName}</p>
      </Box>
    );
  }
  return (
    <>
      <img src={worker.photoURL} />
      <p>{worker.email}</p>
      <p>{worker.displayName}</p>
    </>
  );
};

export default WorkerAvatar;
