import { makeAutoObservable } from "mobx";

class userStore {
  user = {};

  constructor() {
    makeAutoObservable(this);
  }

  setUser = (user) => {
    //   console.log('setUserName', user);
    this.user = user;
  };

  changeUserInfo = (data) => {
    this.user = {...this.user, ...data};
  }
}

export default userStore;