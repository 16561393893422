import { makeAutoObservable } from "mobx";

class initScreen {
  init = false;

  constructor() {
    makeAutoObservable(this);
  }

  setInit = () => {
    this.init = !this.init;
  };
}

export default initScreen;
