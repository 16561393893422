import { useEffect, useState, Fragment, useRef, forwardRef } from "react";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Container,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useStores } from "../../store";
import fbServices from "../../services/firebase";
import {
  collection,
  doc,
  setDoc,
} from "firebase/firestore";
import LoadingButton from "@mui/lab/LoadingButton";
import LoadingComponent from "../../Common/LoadingComponent";
import CompanyInfo from "./CompanyInfo";
import WorkerList from "./WorkerList";

const Settings = (props) => {
  const {
    userStore: { user, setUser, changeUserInfo },
    snackbarStore: { setMsg },
    workersStore: { updateWorkers },
    // initScreenStore: { setInit, init },
    managersStore: { managers, setManagersStore },
    techniciansStore: { technicians, setTechniciansStore },
    companyInfoStore: { companyInfo, setCompanyInfoStore },
  } = useStores();
  // console.log("Settings-user", user);

  const isAdmin = Object.values(user.companiesJobs)?.includes("admin");

  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) {
    return <LoadingComponent />;
  }

  // если не адимн компании дальше все отсечь
  // if (!isAdmin) {
  //   return null;
  // }

  // TODO: сделать фильтр по роли кому показывать настройки
  return (
    <Box sx={{ mt: 10 }}>
      <Container maxWidth="lg">
        <CompanyInfo />
        {/*<CompanyJobs />*/}
        <WorkerList />
        <WorkerList isWorker />

        {/* <SpecialFunctions /> */}
      </Container>
      <Box sx={{ mb: 10 }}></Box>
    </Box>
  );
};

export default Settings;

const CreateCustomer = (props) => {
  const {
    // initScreenStore: { setInit, init },
  } = useStores();

  const { worker, user } = props;
  const {
    userStore: { user: owner, setUser, changeUserInfo },
    snackbarStore: { setMsg },
    // workersStore: { updateWorkers },
  } = useStores();

  const [createdUser, setCreatedUser] = useState({
    displayName: user ? user.displayName : "",
    email: user ? user.email : "",
    password: user ? user.password : "",
    company: owner.company,
    phone: user ? user.phone : "",
    role: worker ? "worker" : "manager",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event, name) => {
    setCreatedUser({ ...createdUser, [name]: event.target.value });
  };
  return (
    <Container maxWidth="sm">
      <TextField
        value={createdUser.displayName}
        onChange={(e) => handleChange(e, "displayName")}
        variant="standard"
        label="Name"
        fullWidth
        required
        error
        helperText={createdUser.displayName ? "" : "Incorrect entry."}
      />
      <TextField
        value={createdUser.email}
        onChange={(e) => handleChange(e, "email")}
        variant="standard"
        label="Email"
        fullWidth
        disabled={!!user}
      />
      <TextField
        value={createdUser.password}
        onChange={(e) => handleChange(e, "password")}
        variant="standard"
        label="Password"
        fullWidth
        disabled={!!user}
      />
      <TextField
        value={createdUser.company}
        onChange={(e) => handleChange(e, "company")}
        variant="standard"
        label="Company Name"
        fullWidth
        disabled
      />
      <TextField
        value={createdUser.phone}
        onChange={(e) => handleChange(e, "phone")}
        variant="standard"
        label="Phone"
        fullWidth
      />
      <LoadingButton
        loading={isLoading}
        disabled={
          (!createdUser.company,
          !createdUser.email,
          !createdUser.displayName,
          !createdUser.password,
          !createdUser.phone,
          !createdUser.role)
        }
        onClick={async () => {
          setIsLoading(true);
          try {
            const result = user || (await fbServices.createUser(createdUser));
            if (result?.data?.status === 500) {
              throw new Error(result?.data?.err.message);
            }
            if (result) {
              try {
                const adminUsersRef = collection(
                  fbServices.db,
                  `${user.company}Users`
                );

                const newCustomerAdmin = await setDoc(
                  doc(adminUsersRef, result.data || result.uid),
                  { ...createdUser, uid: result.data || result.uid }
                );

                // setCreatedUser({
                //   displayName: "",
                //   email: "",
                //   password: "",
                //   company: user.company,
                //   phone: "",
                // });

                setMsg(user ? "Пользователь обновлен" : "Пользователь создан");
              } catch (error) {
                console.log("[Create Manager - error]", error);
                setMsg(error.message);
              } finally {
                // setInit(!init);
              }
            }
          } catch (error) {
            console.log("[Create Manager 2 try - error]", error);
            setMsg(error.message);
          } finally {
            setIsLoading(false);
          }
        }}
        variant="contained"
        fullWidth
        sx={{ my: 5 }}
      >
        {worker
          ? `${user ? "Update Worker" : "Create Worker"}`
          : `${user ? "Update Manager" : "Create Manager"}`}
        {/* {worker ? "Create Worker" : "Create Manager"} */}
      </LoadingButton>
    </Container>
  );
};

const AddManagerDialog = (props) => {
  // console.log('AddManagerDialog', props);
  const { worker, user } = props;

  // const {
  //   userStore: { user, setUser, changeUserInfo },
  // } = useStores();

  const { isOpenDialog, setOpenDialog } = props;
  return (
    <Dialog
      open={isOpenDialog}
      onClose={() => setOpenDialog(false)}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {worker
            ? `${user ? "Edit Worker" : "Add Worker"}`
            : `${user ? "Edit Manager" : "Add Manager"}`}
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <CreateCustomer {...props} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};




const SpecialFunctions = () => {
  return (
    <Paper>
      <Typography sx={{ m: 2 }} gutterBottom variant="h4" component="div">
        {/* TODO: вопрос */}
        Special Functions (где данные заполняем и как редактируем?)
      </Typography>
      <FormGroup
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          ml: 5,
        }}
        aria-label="position"
        row
      >
        <FormControlLabel
          // sx={{ width: "10%" }}
          value=""
          // key={el}
          // disabled={!!disabledTimeSlot[el]}
          // checked={!!timeLineDayWorker[el]}
          // onChange={(e) => handleChange(e, el)}
          control={
            <Checkbox
              size="small"
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          // label={el}
          label="GPS SHARING BETWEN TECHNISION."
          labelPlacement="end"
        />

        <FormControlLabel
          // sx={{ width: "10%" }}
          value=""
          // key={el}
          // disabled={!!disabledTimeSlot[el]}
          // checked={!!timeLineDayWorker[el]}
          // onChange={(e) => handleChange(e, el)}
          control={
            <Checkbox
              size="small"
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          // label={el}
          label="PARTS SHARING BETWEEN TECHNIHION."
          labelPlacement="end"
        />

        <FormControlLabel
          // sx={{ width: "10%" }}
          value=""
          // key={el}
          // disabled={!!disabledTimeSlot[el]}
          // checked={!!timeLineDayWorker[el]}
          // onChange={(e) => handleChange(e, el)}
          control={
            <Checkbox
              size="small"
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          // label={el}
          label="CREATE AND SEND INVOISE TO CASTOMERS"
          labelPlacement="end"
        />

        <FormControlLabel
          // sx={{ width: "10%" }}
          value=""
          // key={el}
          // disabled={!!disabledTimeSlot[el]}
          // checked={!!timeLineDayWorker[el]}
          // onChange={(e) => handleChange(e, el)}
          control={
            <Checkbox
              size="small"
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          // label={el}
          label="TECHNISION JOB STATISTIC AND ANALITIC."
          labelPlacement="end"
        />

        <FormControlLabel
          // sx={{ width: "10%" }}
          value=""
          // key={el}
          // disabled={!!disabledTimeSlot[el]}
          // checked={!!timeLineDayWorker[el]}
          // onChange={(e) => handleChange(e, el)}
          control={
            <Checkbox
              size="small"
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          // label={el}
          label="TECHNISION"
          labelPlacement="end"
        />

        <FormControlLabel
          // sx={{ width: "10%" }}
          value=""
          // key={el}
          // disabled={!!disabledTimeSlot[el]}
          // checked={!!timeLineDayWorker[el]}
          // onChange={(e) => handleChange(e, el)}
          control={
            <Checkbox
              size="small"
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          // label={el}
          label="hints \ training"
          labelPlacement="end"
        />
      </FormGroup>
    </Paper>
  );
};

const CompanyJobs = () => {
  const [companieJobsList, setCompanieJobsList] = useState([
    {
      name: "Appliance repair",
      isSelected: false,
      id: 1,
    },
    {
      name: "Pool Instalation",
      isSelected: false,
      id: 2,
    },
    {
      name: "Pool maitenance",
      isSelected: false,
      id: 3,
    },
    {
      name: "Hvac repair",
      isSelected: false,
      id: 4,
    },
    {
      name: "Cleaning",
      isSelected: false,
      id: 5,
    },
    {
      name: "Handyman",
      isSelected: false,
      id: 6,
    },
  ]);

  // const

  return (
    <Paper sx={{ pl: 2, mt: 1, mb: 1 }}>
      <Typography sx={{ m: 2 }} gutterBottom variant="h4" component="div">
        Company Jobs
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {companieJobsList.map((job, index) => {
          return (
            <Paper
              key={job.id}
              onClick={() => {
                const checkResault = companieJobsList.map((originJobItem, indexOrigin) => {
                  if (indexOrigin === index) {
                    return {...originJobItem, isSelected: originJobItem.isSelected ? false : true}
                  } else {
                    return originJobItem
                  }
                })
                setCompanieJobsList(checkResault)
              }}
              sx={{ m: 1, background: job.isSelected ? "blue" : "white" }}
            >
              <Typography
                sx={{ m: 1 }}
                gutterBottom
                variant="button"
                component="div"
              >
                {job.name}
              </Typography>
            </Paper>
          );
        })}
      </Box>
    </Paper>
  );
};
