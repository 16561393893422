import fbServices from "./firebase";
import moment from "moment";
import {queryMultyResolver} from "./queryResolver";
import {stores} from "../store";

const start = moment()
    .subtract(60, "days")
    .startOf("day")
    .format("YYYY-MM-DD");

const end = moment().add(60, "days").startOf("day").format("YYYY-MM-DD");

export const getNotWorkersOrders = async () => {
    // TODO: получить заказы без мастера или с пустым таймлайном не старше 60 дней от текущей даты

    const setNotWorkersOrdersTwoMonth = stores.ordersStore.setNotWorkersOrdersTwoMonth
    const companyInfo = stores.companyInfoStore.companyInfo

    const companyOrders = [];
    const startShowDay = fbServices.Timestamp.fromDate(
        new Date(moment(start).startOf("day"))
    );
    const endShowDay = fbServices.Timestamp.fromDate(
        new Date(moment(end).endOf("day"))
    );
    // все заказы от указанного дня и старше
    const options = [
        {
            field: "dateCreate",
            operator: ">",
            value: startShowDay,
        },
        {
            field: "dateCreate",
            operator: "<",
            value: endShowDay,
        },
        {
            field: "company",
            operator: "==",
            value: companyInfo.company,
        },
        {
            field: "workerUid",
            operator: "==",
            value: "",
        },
    ];

    const orders = await queryMultyResolver(`orders`, options);
    companyOrders.push(...orders);

    setNotWorkersOrdersTwoMonth(companyOrders);
    // console.log('11companyOrders', companyOrders)
}
