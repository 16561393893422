import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  forwardRef,
} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Container,
  Typography,
  Box,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  CardMedia,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Paper,
  Divider,
  TextareaAutosize,
  Grid,
  Modal,
  List,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import firebase from "../../services/firebase";
import LoadingButton from "@mui/lab/LoadingButton";
import { useStores } from "../../store";
import { TimesLine } from "./index";
import { getGeocode } from "../../services/apiService";
import {
  collection,
  addDoc,
  doc,
  getDocs,
  getDoc,
  deleteDoc,
  updateDoc,
  setDoc,
  query,
  where,
  arrayUnion,
} from "firebase/firestore";
import fbServices from "../../services/firebase";
import MyDatePicker from "./MyDatePicker";
// import { async } from "@firebase/util";
import { queryResolver, tempSearch } from "../../services/queryResolver";
import { pink, red, green, blue, grey, brown } from "@mui/material/colors";
import { customAlphabet } from "nanoid";
import { orderStatuses } from "../../constants";
import { useLocation } from "react-router-dom";
import moment from "moment";
import TimeLineCheckBox from "./TimeLineCheckBox";
import TimeLineWraper from "./TimeLineCheckBox";
import OrdersPhoto from "./OrdersPhoto";
import MainInfoComponent from "./MainInfoComponent";
import SelectionDialog from "./SelectionDialog";
import sendPush from "../../services/pushNotif";
import PartsInstalledModal from "./PartsInstalledModal";
import PaymentsModal from "./PaymentsModal";
import Popup from 'reactjs-popup';
import {getCompanyOrders} from "../../services/getCompanyOrders";
import {getNotWorkersOrders} from "../../services/getNotWorkersOrders";
import useLocalStorage from "../../hooks/useLocalStorageHook";

// генератор 6 значного номера
const nanoid = customAlphabet("ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890", 6);

const AddOrderDialog = (props) => {
  // console.log('AddOrderDialog', props)
  const {
    userStore: { user, setUser, changeUserInfo },
    companyInfoStore: { companyInfo },
    ordersStore: { orders, setorders, updateOrders },
  } = useStores();

  const curentLocation = useLocation();

  const { isOpenDialog, setOpenDialog, orderView, setInit, init, isSearch } =
    props;

  const titleHandler = () => {
    if (curentLocation.pathname.includes("payments")) {
      return "Payments Info";
    } else if (curentLocation.pathname.includes("parts")) {
      return "Parts Info";
    } else {
      return "Update";
    }
  };

  const title =
    orderView && typeof orderView !== "string" ? titleHandler() : "Create";

  const [numberOrderSearchValue, setNumberOrderSearchValue] = useState("");

  const [isOpenSelectionDialog, setOpenSelectionDialog] = useState(false);
  const [searchRsault, setSearchRsault] = useState([]);
  const [searchItem, setSearchItem] = useState(null);

  const searchForNumberOrder = async () => {
    console.log('searchForNumberOrder')
    const result = [];

    try {
      // Если запросили с пустым полем номеров то вывести ВСЕ заказы компании
      const srRsaultnNmber = await queryResolver(
        "orders",
          numberOrderSearchValue ? "number" : 'company',
          numberOrderSearchValue ? numberOrderSearchValue.toUpperCase() : companyInfo.company
      );
      srRsaultnNmber &&
        srRsaultnNmber.length &&
        result.push(
          ...srRsaultnNmber.filter(
            (item) => companyInfo.company === item.company
          ).sort((a, b) => b.dateCreate.toDate() - a.dateCreate.toDate())
        );
    } catch (error) {
      console.log("error-srRsaultnNmber", error);
    }
    setSearchRsault(result);
    setOpenSelectionDialog(true);
  };

  // const handleDelete = async () => {
  //   const companyOrdersRef = doc(
  //     fbServices.firestoreDb,
  //     `orders/${orderView.uid}`
  //   );
  //   const res = await deleteDoc(companyOrdersRef);
  //   setInit(!init);
  //   setOpenDialog(false);
  // };

  return (
    <Dialog
      open={isOpenDialog}
      onClose={() => setOpenDialog(false)}
      fullWidth={true}
      maxWidth={"md"}
    >
      <SelectionDialog
        isOpenSelectionDialog={isOpenSelectionDialog}
        setOpenSelectionDialog={setOpenSelectionDialog}
        searchRsault={searchRsault}
        setSearchItem={setSearchItem}
      />
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {user.company}:&nbsp;&nbsp;&nbsp;{!isSearch && title}{" "}
          {isSearch ? (
            <FormControl variant="outlined" size="small">
              <InputLabel htmlFor={"searchOrderNumber"}>
                Search order
              </InputLabel>
              <OutlinedInput
                id={"searchOrderNumber"}
                fullWidth
                value={numberOrderSearchValue}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline" : {
                    borderColor : "black",
                    borderWidth: 2,
                  },
                  "&:hover > .MuiOutlinedInput-notchedOutline" : {
                    borderColor : "black"
                  },
                }}
                onChange={(e) => setNumberOrderSearchValue(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={async () => searchForNumberOrder()}
                      edge="end"
                    >
                      <SearchIcon color='error' />
                    </IconButton>
                  </InputAdornment>
                }
                label={"phoneNumber"}
              />
            </FormControl>
          ) : typeof orderView !== "string" && orderView?.number ? (
            `ORDER: ${orderView?.number}`
          ) : (
            `ORDER: ${orderView?.uid || "---"}`
          )}
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <CreateOrder
          user={user}
          {...props}
          orderView={searchItem || orderView}
          isOpenSelectionDialog={isOpenSelectionDialog}
          setOpenSelectionDialog={setOpenSelectionDialog}
          searchRsault={searchRsault}
          setSearchItem={setSearchItem}
          setSearchRsault={setSearchRsault}
          setOpenDialog={setOpenDialog}
        />
      </DialogContent>
      <DialogActions>
        {/* TODO: контроль показывать делит только для orders*/}
        {/*{typeof orderView !== "string" && curentLocation.pathname === "/" && (*/}
        {/*  <Button onClick={() => handleDelete()}>Delete</Button>*/}
        {/*)}*/}
        <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrderDialog;

const CreateOrder = (props) => {
  const {
    // userStore: { user, setUser, changeUserInfo },
    snackbarStore: { open, msg, setMsg, active, desactive },
    // workersStore: { workers, setWorkers },
    ordersStore: { orders, setorders, updateOrders },
    managersStore: { managers, setManagersStore },
    techniciansStore: { technicians, setTechniciansStore },
    companyInfoStore: { companyInfo, setCompanyInfoStore },
  } = useStores();
  // console.log('companyInfo', companyInfo)

  // const [startDate] = useLocalStorage("startDate", new Date());

  const curentLocation = useLocation();

  const {
    user,
    worker,
    timeline,
    startDate = new Date(),
    orderView,
    setInit,
    init,
    setOpenDialog,
    hideTimeline,
    isSearch,
    isOpenSelectionDialog,
    setOpenSelectionDialog,
    searchRsault,
    setSearchItem,
    setSearchRsault,
  } = props;

  const isDisabled = false; //!(curentLocation.pathname === "/");

  const [order, setOrder] = useState({
    company: companyInfo.company,
    brend: "",
    type: "",
    serialNumber: "",
    adress: "",
    zipСode: "",
    phoneNumber: "",
    priceRepair: "",
    houseCost: "",
    // description: [],
    worker: worker?.displayName ? worker?.displayName : "",
    workerUid: worker?.uid ? worker?.uid : "",
    status: "new",
    owner: user.uid,
    tax: companyInfo.tax || "8%",
    timeLine: [],
    priceParts: [],
    jobDescription: '',

    dateCreate: "",
    day: "",
    deposit: "",
    depositData: "",
    depositDescription: "",
    depositIsVerified: "",
    depositMethod: "",
    labor: "",
    month: "",
    number: nanoid(), //
    payment: "",
    payment2: "",
    payment2IsVerified: "",
    paymentIsVerified: "",
    paymentMethod: "",
    paymentMethod2: "",
    paymentMethodData: "",
    paymentMethodData2: "",
    paymentMethodDescription: "",
    paymentMethodDescription2: "",
    total: "",
    uid: "",
    year: "",
    clientName: '',
    srf: '',
    fromTechnishion: '',

    ...(typeof orderView !== "string" ? orderView : {}),
  });
  const [partsModal, setPartsModal] = useState({ showModal: false });
  const [paymentsModal, setPaymentsModal] = useState(false);
  // console.log('main-paymentsModal', paymentsModal)
  const [tempModal, setTempModal] = useState(false)

  const pricePartsString =
    typeof order.priceParts === "object"
      ? order.priceParts.reduce((acc, item) => {
          if (!item.name) {
            return acc;
          }
          return acc + (acc ? ", " : "") + item.name;
        }, "")
      : "";
  const pricePartsTotal =
    typeof order.priceParts === "object"
      ? order.priceParts.reduce((acc, item) => {
          const qt = item.positionQuantity ? item.positionQuantity[order.number] || 1 : 1
          if (!item.sell) {
            return acc;
          }
          return acc + ((+item.sell || 1) * qt);
        }, 0)
      : 0;

  const [isLoading, setIsLoading] = useState(false);

  const [startDateNewOrder, setStartDateNewOrder] = useState(
    orderView?.dateCreate?.toDate() || new Date(startDate) || new Date()
  );

  const [timeLineDayWorker, setTimeLineDayWorker] = useState({});
  const [disabledTimeSlot, setDisabledTimeSlot] = useState({});

  useEffect(() => {
    if (Object.keys(timeLineDayWorker).length) {
      const newTimeLineOrder = Object.keys(timeLineDayWorker).filter((time) => {
        return !disabledTimeSlot[time] && timeLineDayWorker[time];
      });
      setOrder({ ...order, timeLine: newTimeLineOrder });
    }
  }, [timeLineDayWorker]);

  useEffect(() => {
    if (order.worker) {
      technicians.forEach((worker) => {
        if (worker.displayName === order.worker) {
          setOrder({ ...order, workerUid: worker.uid });
        }
      });
    }
  }, [order.worker]);

  useEffect(() => {
    getClearTimeLine();
  }, [order.workerUid, startDateNewOrder]);

  useEffect(() => {
    setOrder({ ...order, ...(typeof orderView !== "string" ? orderView : {}) });
    setStartDateNewOrder(
      orderView?.dateCreate?.toDate() || new Date(startDate) || new Date()
    );
  }, [orderView]);

  useEffect(() => {
    startDateNewOrder &&
      setOrder({
        ...order,
        year: startDateNewOrder?.getFullYear(),
        month: startDateNewOrder?.getMonth(),
        day: startDateNewOrder?.getDate(),
        dateCreate: startDateNewOrder,
      });
  }, [startDateNewOrder]);

  const getClearTimeLine = async () => {
    // надо получить все заказы мастера
    // TODO исправить queryResolver для массива запросов сравнения
    // TODO сделать для пустышек отключение проверки таймлайн
    try {
      const queryRef = collection(fbServices.firestoreDb, "orders");

      const qOrderDayWorker = query(
        queryRef,
        where("day", "==", startDateNewOrder.getDate()),
        where("month", "==", startDateNewOrder.getMonth()),
        where("workerUid", "==", order.workerUid)
      );
      const querySnapshot = await getDocs(qOrderDayWorker);
      const orderDayWorker = [];

      if (order.workerUid) {
        querySnapshot.forEach((doc) => {
          const item = doc.data();
          orderDayWorker.push({ ...item, uid: doc.id });
        });
      }

      const timeLineDayWorker = orderDayWorker
        .map((el) => {
          return el.timeLine;
        })
        .flat();
      const occupiedCell = companyInfo.timesLine.reduce(
        (a, v) => ({ ...a, [v]: null }),
        {}
      );
      timeLineDayWorker.forEach((el) => {
        occupiedCell[el] = true;
      });
      let disabledCell = { ...occupiedCell };
      if (orderView && typeof orderView !== "string") {
        if (Array.isArray(orderView.timeLine)) {
          orderView.timeLine.forEach((time) => {
            disabledCell[time] = false;
          });
        } else {
          disabledCell[orderView.timeLine] = false;
        }
      } else if (timeline) {
        occupiedCell[timeline] = true;
      }

      setDisabledTimeSlot(disabledCell);
      setTimeLineDayWorker(occupiedCell);
    } catch (error) {
      console.log("getClearTimeLine-err", error);
    }
  };

  const handleChange = (event, name) => {
    setOrder({ ...order, [name]: event.target.value });
  };

  const helpFieldHandler = () => {
    if (curentLocation.pathname === "/payments") {
      return (
          <Button onClick={() => {
            setPaymentsModal(true)
          }}
                  variant="contained"
                  size="small"
                  sx={{width: "100%", height: 38 }}
          >
        {/*<TextField*/}

        {/*  value={'Payment Details'}*/}
        {/*  // onChange={(e) => handleChange(e, "paymentMethod")}*/}
        {/*  label=""*/}
        {/*  fullWidth*/}
        {/*  // required*/}
        {/*  hiddenLabel*/}
        {/*  size="small"*/}
        {/*  disabled={true}*/}
        {/*  variant="outlined"*/}
        {/*  sx={{*/}
        {/*    "& .MuiInputBase-input.Mui-disabled": {*/}
        {/*      WebkitTextFillColor: "#000000",*/}
        {/*    },*/}
        {/*  }}*/}
        {/*/>*/}
            Payment Details
          </Button>
      );
    } else if (curentLocation.pathname === "/parts") {
      return (
        <TextField
          value={order.priceParts}
          // onChange={(e) => handleChange(e, "priceParts")}
          label="Price Parts2"
          fullWidth
          // required
          hiddenLabel
          size="small"
          disabled={isDisabled}
          variant="outlined"
        />
      );
    } else {
      return (
            <MyDatePicker
                startDate={startDateNewOrder}
                setStartDate={setStartDateNewOrder}
                isAddOrder
            />
      );
    }
  };

  const paymentsLine = () => {
    if (curentLocation.pathname === "/payments") {
      return (
        <Grid container spacing={1} columns={4} sx={{ mt: 0 }}>
          <Grid item md={1}>
            <FormControl fullWidth size="small">
              <TextField
                  value={pricePartsTotal}
                  label="Price Parts Amount"
                  fullWidth
                  hiddenLabel
                  size="small"
                  disabled={true}
                  variant="outlined"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
              />
            </FormControl>
          </Grid>
          {/*<Grid item md={1}>*/}
          {/*  <TextField*/}
          {/*    value={order.priceParts}*/}
          {/*    onChange={(e) => handleChange(e, "priceParts")}*/}
          {/*    label="Price Parts"*/}
          {/*    fullWidth*/}
          {/*    // required*/}
          {/*    hiddenLabel*/}
          {/*    size="small"*/}
          {/*    disabled={isDisabled}*/}
          {/*    variant="outlined"*/}
          {/*  />*/}
          {/*</Grid>*/}
          <Grid item md={1}>
            <TextField
              value={order.tax}
              onChange={(e) => handleChange(e, "tax")}
              label="TAX"
              fullWidth
              // required
              hiddenLabel
              size="small"
              disabled={isDisabled}
              variant="outlined"
            />
          </Grid>
          <Grid item md={1}>
            <TextField
              value={order.labor}
              onChange={(e) => handleChange(e, "labor")}
              label="Labor"
              fullWidth
              // required
              hiddenLabel
              size="small"
              disabled={isDisabled}
              variant="outlined"
            />
          </Grid>
          <Grid item md={1}>
            <TextField
              value={order.total}
              onChange={(e) => handleChange(e, "total")}
              label="Total"
              fullWidth
              // required
              hiddenLabel
              size="small"
              disabled={isDisabled}
              variant="outlined"
            />
          </Grid>
        </Grid>
      );
    } else return null;
  };

  // запрет на создание заказа с мастером без времени
  const createIsDisabled = () => {
    // if (!order.brend) return true
    if (!order.type) return true;
    if (!order.timeLine?.length) return true;
    if (order.workerUid) {
      return !order.timeLine?.length;
    }
    return false;
  };

  return (
    <>
      <Box>
        <MainInfoComponent
          handleChange={handleChange}
          isDisabled={isDisabled}
          order={order}
          setOrder={setOrder}
          isSearch={isSearch}
          isOpenSelectionDialog={isOpenSelectionDialog}
          setOpenSelectionDialog={setOpenSelectionDialog}
          searchRsault={searchRsault}
          setSearchItem={setSearchItem}
          setSearchRsault={setSearchRsault}
          setOpenDialog={setOpenDialog}
          setInit={setInit}
          init={init}
        />
        {pricePartsString && (curentLocation.pathname === "/payments") ? (
          <Grid container spacing={1} columns={3} sx={{ marginTop: 0 }}>
            <Grid item md={2}>
              <div
                onClick={() => {
                  if (typeof order.priceParts !== "object") {
                    return;
                  }

                  setPartsModal({
                    ...partsModal,
                    showModal: true,
                    priceParts: order.priceParts,
                    worker: order.worker,
                    orderNumber: order.number
                  });
                }}
              >
                <FormControl fullWidth size="small">
                  <TextField
                    value={pricePartsString}
                    // onChange={(e) => handleChange(e, "installedParts")}

                    label="Installed Parts"
                    fullWidth
                    // required
                    hiddenLabel
                    size="small"
                    disabled={true}
                    variant="outlined"
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item md={1}>
              <Button
                  variant="contained"
                  size="small"
                  sx={{width: "100%", height: 38 }}
                  onClick={() => {
                    if (typeof order.priceParts !== "object") {
                      return;
                    }

                    setPartsModal({
                      ...partsModal,
                      showModal: true,
                      priceParts: order.priceParts,
                      worker: order.worker,
                      orderNumber: order.number
                    });
                  }}
              >Parts Details</Button>
            </Grid>
          </Grid>
        ) : null}

        <Grid container spacing={1} columns={3} sx={{ mt: 0 }}>
          <Grid item md={1}>
            <FormControl fullWidth size="small">
              <InputLabel id="worker-select-label">Worker</InputLabel>
              <Select
                fullWidth
                labelId="worker-select-label"
                id="worker-select"
                value={order.worker}
                label="Worker"
                onChange={(e) => handleChange(e, "worker")}
                disabled={isDisabled}
              >
                <MenuItem value={undefined} disabled>
                  <em>select the worker</em>
                </MenuItem>
                {technicians.map((worker) => {
                  return (
                    <MenuItem key={worker.uid} value={worker.displayName}>
                      {worker.displayName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            md={1}
            sx={{ display: "flex", alignItems: "center", width: "100%" }}
          >
            <FormControl fullWidth size="small">
              <InputLabel id="status-select-label">Status</InputLabel>
              <Select
                fullWidth
                labelId="status-select-label"
                id="status-select"
                value={order.status}
                label="Status"
                onChange={(e) => handleChange(e, "status")}
                // disabled={isDisabled}
              >
                <MenuItem value={undefined} disabled>
                  <em>select the status</em>
                </MenuItem>
                {Object.values(orderStatuses).map((status) => {
                  return (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            md={1}
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box sx={{ width: "100%" }}>
              {
                // TODO: календарь только для ордерс
                helpFieldHandler()
              }
            </Box>
          </Grid>
        </Grid>
        {/*линия для вкладки payments*/}
        {paymentsLine()}

        <TimeLineCheckBox
          // orderView={orderView}
          timeLineDayWorker={timeLineDayWorker}
          setTimeLineDayWorker={setTimeLineDayWorker}
          disabledTimeSlot={disabledTimeSlot}
          // startedTimeOrder={timeline}
        />

        <LoadingButton
          style={{ marginTop: 10 }}
          loading={isLoading}
          disabled={createIsDisabled()}
          size="small"
          onClick={async () => {
            const orderForBase = {
              ...order,
              dateCreate: startDateNewOrder,
            };
            setIsLoading(true);

            try {
              if (order.adress && order.zipСode) {
                const geocode = await getGeocode(
                  `${order.zipСode} ${order.adress}`
                );
                if (geocode.data?.results?.length) {
                  orderForBase.latitude =
                    geocode.data.results[0].geometry.location.lat;
                  orderForBase.longitude =
                    geocode.data.results[0].geometry.location.lng;
                }
              }

              orderForBase.number = orderForBase.number || nanoid();

              // сохранение в коллекцию заказов
              let orderUid;

              if (orderView && typeof orderView !== "string") {
                const companyOrdersRef = doc(
                  fbServices.firestoreDb,
                  `orders/${orderView.uid}`
                );
                orderUid = orderView.uid;
                await setDoc(companyOrdersRef, orderForBase);
              } else {
                const companyOrdersRef = collection(
                  fbServices.firestoreDb,
                  `orders`
                );
                const newOrder = await addDoc(companyOrdersRef, orderForBase);
                orderUid = newOrder.id;
              }

              // отправить push мастеру или хозяину(если нет мастера или таймлайна)
              const admin =
                companyInfo.email &&
                (await firebase.getDocItem("users", companyInfo.email));
              const ownerToken = admin?.notifToken;
              const technicianToken = technicians.find(
                (technician) => technician.uid === orderView?.workerUid
              );

              // сохранить клиента

              if (orderForBase.phoneNumber) {
                const customerRef = doc(
                  fbServices.firestoreDb,
                  `customers/${orderForBase.phoneNumber}`
                );

                const customer = await setDoc(
                  customerRef,
                  {
                    phoneNumber: orderForBase.phoneNumber,
                    zipСode: order.zipСode,
                    adress: order.adress,
                    orders: arrayUnion(orderUid),
                  },
                  { merge: true }
                );
              }

              // отправить push
              const ignorePushStatus = [orderStatuses.JOB_CHECKED, orderStatuses.JOB_PAID]

              if (!ignorePushStatus.includes(orderForBase.status)) {
                await fbServices.sendPush({
                  notification: {
                    title: `DISPATCH MSG. \nNEW ORDER: ${order.number || ''}`,
                    body: `COMPANY: ${order.company} \nSTATUS: ${order.status} \nTYPE: ${order.type} BRAND: ${order.brend} \nADDRESS: ${order.adress}`,
                  },
                  token: orderView?.timeLine?.length && technicianToken
                      ? technicianToken.notifToken
                      : ownerToken,
                })
              }

              setOrder({
                company: user.company,
                brend: "",
                type: "",
                serialNumber: "",
                adress: "",
                zipСode: "",
                phoneNumber: "",
                priceRepair: "",
                houseCost: "",
                // description: "",
                worker: "",
                workerUid: "",
                owner: "", // user.uid,
                year: "", // startDate.getFullYear(),
                month: "", // startDate.getMonth(),
                day: "", // startDate.getDate(),
                timeLine: "", // TimesLine[index],
              });
              setMsg("Заказ сформирован");
            } catch (error) {
              console.log("error", error);
              setMsg(error.message);
            } finally {
              await getCompanyOrders({startDate});
              await getNotWorkersOrders()
              setIsLoading(false);
              setOpenDialog(false);
              // setInit(!init);
            }
          }}
          variant="contained"
          fullWidth
        >
          {orderView && typeof orderView !== "string" ? "Update" : "Create"}
        </LoadingButton>
        <OrdersPhoto
          photoLinksParts={order.photoLinksParts}
          photoLinksJob={order.photoLinksJob}
        />

        {/*// МОДАЛКИ*/}

        {partsModal.showModal ? (
            <PartsInstalledModal
                partsModal={partsModal}
                setPartsModal={setPartsModal}
                // number={order.number}
            />
        ) : null}

        {paymentsModal ? (
            <PaymentsModal
                paymentsModal={paymentsModal}
                setPaymentsModal={setPaymentsModal}
                order={order}
            />
        ) : null}
      </Box>
    </>
  );
};
