import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  forwardRef,
} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Container,
  Typography,
  Box,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  CardMedia,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Paper,
  Divider,
  TextareaAutosize,
  Grid,
  Modal,
  List,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { useStores } from "../../store";
import {green, red} from "@mui/material/colors";

const PartsInstalledModal = ({ partsModal, setPartsModal }) => {
  console.log('PartsInstalledModal', partsModal);
  const {
    userStore: { user, setUser, changeUserInfo },
    companyInfoStore: { companyInfo },
    ordersStore: { orders, setorders, updateOrders },
  } = useStores();

  const divStyleBlock = {
    border: "1px solid black",
    height: 40,
    borderRadius: 4,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "rgb(118, 118, 118)",
  };

  const totalTechAmount = () => {
    return partsModal.priceParts.reduce((acc, part) => {
      if (part.isTech) {
        return acc + +(part.sell || 0);
      } else {
        return acc;
      }
    }, 0);
  };

  const totalTechAmountBuy = () => {
    return partsModal.priceParts.reduce((acc, part) => {
      if (part.isTech) {
        return acc + +(part.price || 0);
      } else {
        return acc;
      }
    }, 0);
  };

  const totalOfficesAmount = () => {
    return partsModal.priceParts.reduce((acc, part) => {
      if (!part.isTech) {
        const qt = part.positionQuantity ? part.positionQuantity[partsModal.orderNumber] || 1 : 1
        return acc + (+(part.sell || 0) * qt);
      } else {
        return acc;
      }
    }, 0);
  };

  const totalOfficesAmountBuy = () => {
    return partsModal.priceParts.reduce((acc, part) => {
      if (!part.isTech) {
        const qt = part.positionQuantity ? part.positionQuantity[partsModal.orderNumber] || 1 : 1
        return acc + (+(part.price || 0) * qt);
      } else {
        return acc;
      }
    }, 0);
  };

  return (
    <Dialog
      open={partsModal.showModal}
      onClose={() =>
        setPartsModal({
          ...partsModal,
          showModal: false,
        })
      }
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          INSTALLED PARTS
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {partsModal.priceParts?.length
          ? partsModal.priceParts.map((stokItem, index) => {
              return (
                <Grid
                  container
                  spacing={1}
                  columns={14}
                  sx={{ mb: 1 }}
                  key={index}
                >
                  <Grid item md={1}>
                    <div style={divStyleBlock}># {index + 1}</div>
                  </Grid>

                  <Grid item md={4}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <InputLabel htmlFor={"PARTS"}>PARTS</InputLabel>
                      <OutlinedInput
                        id={"PARTS"}
                        fullWidth
                        value={stokItem.name}
                        // onChange={(e) => {
                        //   updateItem("name", e.target.value);
                        // }}
                        label={"PARTS"}
                        disabled={true}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item md={4}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <InputLabel htmlFor={"PARTS NUMBER"}>
                        PARTS NUMBER
                      </InputLabel>
                      <OutlinedInput
                        id={"PARTS NUMBER"}
                        fullWidth
                        value={stokItem.partsNumber}
                        label={"PARTS NUMBER"}
                        disabled={true}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item md={2}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <InputLabel htmlFor={"BUY"}>BUY</InputLabel>
                      <OutlinedInput
                          id={"BUY"}
                          fullWidth
                          value={stokItem.price}
                          disabled={true}
                          sx={{
                            background: red[200],
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#000000",
                            },
                          }}
                          label={"BUY"}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item md={2}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <InputLabel htmlFor={"SELL"}>SALE</InputLabel>
                      <OutlinedInput
                        id={"SELL"}
                        fullWidth
                        value={stokItem.sell}
                        disabled={true}
                        sx={{
                          background: green[200],
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                        label={"PRICE"}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item md={1}>
                    <div style={divStyleBlock}>
                      {stokItem.positionQuantity
                        ? stokItem.positionQuantity[partsModal.orderNumber] || 1
                        : 1}
                    </div>
                  </Grid>
                </Grid>
              );
            })
          : null}
        {totalOfficesAmount() ? (
          <Grid container spacing={1} columns={13} sx={{ mb: 1 }}>
            <Grid item md={5}>
              <div style={divStyleBlock}>TOTAL OFFICES</div>
            </Grid>
            <Grid item md={4}>
              <div style={{...divStyleBlock, background: red[200]}}>{totalOfficesAmountBuy()}</div>
            </Grid>
            <Grid item md={4}>
              <div style={{...divStyleBlock, background: green[200]}}>{totalOfficesAmount()}</div>
            </Grid>
          </Grid>
        ) : null}

        {totalTechAmount() ? (
          <Grid container spacing={1} columns={13} sx={{ mb: 1 }}>
            <Grid item md={5}>
              <div style={divStyleBlock}>TOTAL TECH</div>
            </Grid>
            <Grid item md={4}>
              <div style={{...divStyleBlock, background: red[200]}}>{totalTechAmountBuy()}</div>
            </Grid>
            <Grid item md={4}>
              <div style={{...divStyleBlock, background: green[200]}}>{totalTechAmount()}</div>
            </Grid>
          </Grid>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setPartsModal({
              ...partsModal,
              showModal: false,
            });
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PartsInstalledModal;
