import React, { useEffect, useState, Fragment, useRef, forwardRef } from "react";
import {
    Button,
    Paper,
    Grid,
    Typography,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Container,
    TextField, InputLabel, Select, MenuItem, FormControl,
} from "@mui/material";
import fbServices from "../../services/firebase";
import {grey} from "@mui/material/colors";

const SuperAdmin = () => {
    const [companies, setCompanies] = useState([])
    useEffect(() => {
        fbServices.getCollection('companies').then((res) => {
            console.log('res', res)
            setCompanies(res)
        }).catch((e) => console.log('getCollection-companies', e))
    }, [])
    return (
        <Box sx={{ m: 1, mt: 9, ml: 3, display: "flex", flexDirection: 'column' }}>
            <div>SuperAdmin</div>
            <div style={{position: 'relative'}}>
                {companies.length ? (
                    companies.map((company) => {
                        return (
                            <>
                                <div style={{
                                    fontSize: 25,
                                    fontWeight: 'bold',
                                    borderBottom: '1px solid black',
                                    borderTop: '2px solid black',
                                    position: 'sticky',
                                    top: 72,
                                    background: grey[200],
                                }}>{company.company}</div>
                                <pre>
                                {JSON.stringify(company, null, 2)}
                                    <br/>
                                <div style={{height: 20}} />
                            </pre>
                            </>
                        )
                    })
                ) : 'NOT'}
            </div>
        </Box>
    )
}

export default SuperAdmin
