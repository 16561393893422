import { Box, Container, Paper } from "@mui/material";
import { useEffect } from "react";
import { useStores } from "../../store";
import { customAlphabet } from "nanoid";
import firebase from "../../services/firebase";
import { dataBaseName } from "../../constants";

export function Api() {

    const {
        userStore: { user, setUser, changeUserInfo },
        snackbarStore: { setMsg },
        workersStore: { updateWorkers },
        // initScreenStore: { setInit, init },
        managersStore: { managers, setManagersStore },
        techniciansStore: { technicians, setTechniciansStore },
        companyInfoStore: { companyInfo, setCompanyInfoStore },
    } = useStores();

    const updateCompanyInfo = async () => {
        const companyInfoSnap = await firebase.getDocItem(
            dataBaseName.COMPANIES,
            companyInfo.company,
        );
        setCompanyInfoStore(companyInfoSnap)
    }

    useEffect(() => {
        if (!companyInfo.apiKey) {
            const nanoid = customAlphabet("ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890", 10);
            const companyApiKey = nanoid()
            firebase.updateDocItem("companies", companyInfo.company, {
                apiKey: companyApiKey,
            }).then(() => {
                updateCompanyInfo()
            })
        }
    }, [])

    const demoJson = {
        "company": companyInfo.company || "My company",
        "apiKey": companyInfo.apiKey || '****',
        "brend": "brand",
        "type": "type",
        "domainName": "domain",
        "typeOfJob": "aplaince repair",
        "zipСode": "zip",
        "serialNumber": "serialNumber or modelNumber",
        "jobDescription": "jobDescription",
        "fierstName": "first name",
        "lastName": "last name",
        "adress": "address"
    }


    return (
        <Box sx={{ mt: 10 }}>
            <Container maxWidth="lg">
                <Paper sx={{ pl: 2, pr: 2 }}>
                    <Box sx={{ m: 1, mt: 9, ml: 3, display: "flex", flexDirection: 'column' }}>
                        <Box
                            sx={{
                                minWidth: "70%",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div
                                style={{
                                    borderWidth: 1,
                                    borderColor: "black",
                                    borderStyle: "solid",
                                    padding: 4,
                                    borderRadius: 5,
                                    width: "100%",
                                    textAlign: "center",
                                    marginBottom: 5,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: 'column'
                                }}
                            >
                                <span>API LEADS FROM YOUR SITE</span>
                                <div style={{ display: 'flex' }}>
                                    <div
                                        style={{
                                            borderWidth: 1,
                                            borderColor: "black",
                                            borderStyle: "solid",
                                            padding: 4,
                                            borderRadius: 5,
                                            marginRight: 20,
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                        }}
                                    >
                                        <span>API KEY:</span>
                                    </div>

                                    <div
                                        style={{
                                            borderWidth: 1,
                                            borderColor: "black",
                                            borderStyle: "solid",
                                            padding: 4,
                                            borderRadius: 5,
                                            marginRight: 20,
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                        }}
                                    >
                                        <span>{companyInfo.apiKey || '****'}</span>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Box>


                    <Box
                        sx={{
                            minWidth: "70%",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        <div style={{
                            borderWidth: 1,
                            borderColor: "black",
                            borderStyle: "solid",
                            padding: 4,
                            borderRadius: 5,
                            width: "100%",
                            textAlign: "center",
                            marginBottom: 5,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: 'column'
                        }}>
                            JSON
                            <pre style={{
                                textAlign: 'left'
                            }}>
                                {JSON.stringify(demoJson, null, 2)}
                            </pre>
                        </div>
                    </Box>
                </Paper>
            </Container>
        </Box>

    )
}