export const dataBaseName = {
  USERS: "users",
  COMPANIES: "companies",
};

export const userWorkerStatus = {
  ADMIN: "admin",
  MANAGER: "manager",
  TECHNICIAN: "technician",
};

export const orderStatuses = {
  NEW: "new",
  AT_WORK: "atWork",
  JOB_ON_HOLD: "jobOnHold",
  NEED_OFFICE_ORDER_PARTS: "needOfficeOrderParts",
  PARTS_ORDERED: "partsOrdered",
  PARTS_ON_OFFICE: "partsOnOffice",
  PARTS_NOT_AVAILABLE: "partsNotAvailable",
  PARTS_IN_MY_CAR: "partsInMyCar",
  NEED_RESCHEDULER: "needRescheduler",
  JOB_DONE: "jobDone",
  // PARTS_IN_THE_OFFIS: "partsInTheOffis",
  CALL_BACK: "callBack",
  JOB_CHECKED: "jobChecked",
  JOB_PAID: "jobPaid",
  BLOCK_TIME: 'blockTime',
};

export const statusColors = {
  new: "#FFFFFF",
  atWork: "#3b82f6",
  jobOnHold: "#f87171",
  needOfficeOrderParts: "#fde68a",
  // partsInTheOffis: "#f97316",
  partsOrdered: "#fbbf24",
  partsOnOffice: "#fdba74",
  partsNotAvailable: "#ef4444",
  partsInMyCar: "#f97316",
  needRescheduler: "#b91c1c",
  jobDone: "#22c55e",
  rejection: "red", // TODO: delete not use ?
  callBack: "#ef4444",
  jobChecked: "#c4b5fd",
  jobPaid: "#5b21b6",
  blockTime: '#989898',
  undefined: "white",
};

export const priorityList = {
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low",
};
