import {Box, Button, Modal, TextField} from "@mui/material";
import React, {useState} from "react";
import firebase from "../services/firebase";
import fbServices from "../services/firebase";
import {useStores} from "../store";
import LoadingButton from "@mui/lab/LoadingButton";

const TextModal = ({openTextModal, setOpenTextModal, order}) => {
    console.log('TextModal', order)
    const {
        userStore: { user, setUser, changeUserInfo },
        companyInfoStore: { companyInfo },
        techniciansStore: { technicians, setTechniciansStore },
    } = useStores();
    const [value, setValue] = useState("");
    // console.log('value', value)
    const [isLoading, setIsLoading] = useState(false)

    const handleCloseTextModal = () => setOpenTextModal(false);

    const handleChange = (event, name) => {
        setValue(event.target.value);
    };
    return (
        <Modal
            open={openTextModal}
            onClose={handleCloseTextModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "40%",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    backgroundColor: "white",
                }}
            >
                <TextField
                    value={value}
                    onChange={(e) => handleChange(e, "description")}
                    variant="outlined"
                    label="Description"
                    fullWidth
                    multiline
                    size="small"
                    // disabled={true}
                    rows={3}
                    // ref={descriptionHeight}
                />
                <Box sx={{ display: "flex", mt: 1, justifyContent: "end" }}>
                    <Button
                        size="small"
                        // variant="contained"
                        sx={{marginRight: 1}}
                        onClick={() => {
                            setOpenTextModal(false)
                        }}
                    >
                        CANCEL
                    </Button>

                    <LoadingButton
                        loading={isLoading}
                        size="small"
                        variant="contained"
                        onClick={async () => {
                            // отправить push мастеру или хозяину(если нет мастера или таймлайна)
                            const technicianToken = technicians.find(
                                (technician) => technician.uid === order?.workerUid
                            );
                            setIsLoading(true)
                            console.log('sendPush')
                            try {
                                await fbServices.sendPush({
                                    notification: {
                                        title: `DISPATCH MSG. \nORDER: ${order.number || ''}`,
                                        body: `COMPANY: ${order.company} \nSTATUS: ${order.status} \nTYPE: ${order.type} BRAND: ${order.brend}\n${value}`,
                                    },
                                    token: technicianToken.notifToken,
                                })
                            } catch (e) {
                                console.log('ERROR-sendPush-msg', e)
                            }
                            setIsLoading(false)
                            setOpenTextModal(false)
                        }}
                    >
                        SEND
                    </LoadingButton>
                </Box>
            </Box>
        </Modal>
    )
}

export default TextModal
