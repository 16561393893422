import { useEffect, useState, Fragment, useRef, forwardRef } from "react";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Popover,
} from "@mui/material";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import IconButton from "@mui/material/IconButton";
import AddOrderDialog from "../AddOrder";
import { lineHeight, widthItem } from "./index";
import { useDrag, useDrop } from "react-dnd";
import { useStores } from "../../store";
import fbServices from "../../services/firebase";
import {
  collection,
  addDoc,
  doc,
  getDocs,
  getDoc,
  query,
  where,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import useSingleAndDoubleClick from "../../hooks/useSingleAndDoubleClick";
import { pink, red, green, grey } from "@mui/material/colors";
import { statusColors } from "../../constants";
import { useLocation } from "react-router-dom";
import {getNotWorkersOrders} from "../../services/getNotWorkersOrders";
import firebase from "../../services/firebase";

const WorkingItem = (props) => {
  const { orderView, isPeriodLine } = props;
  const [myOrderView, setMyOrderView] = useState(orderView);
  const curentLocation = useLocation();

  const {
    userStore: { user },
    snackbarStore: { setMsg },
    companyInfoStore: { companyInfo },
    techniciansStore: { technicians, setTechniciansStore },
  } = useStores();

  const [isOpenDialog, setOpenDialog] = useState(false);

  const style = {
    cursor: "move",
    float: "left",
  };

  const [{ isDragging }, drag] = useDrag(() => {
    return {
      type: "box",
      item: { myOrderView },
      end: async (item, monitor) => {
        console.log(
          "item-monitor",
          item,
          myOrderView,
          orderView,
          props,
          monitor
        );
        const dropResult = monitor.getDropResult();
        console.log("dropResult", dropResult);
        if (orderView && dropResult) {
          console.log("dropResult", dropResult);
          let updatedOrder = {};
          if (dropResult.isClearFooter) {
            console.log("dropResult", dropResult);
            updatedOrder = {
              ...orderView,
              worker: "",
              workerUid: "",
            };
          } else {
            updatedOrder = {
              ...orderView,
              worker: dropResult.worker.displayName,
              workerUid: dropResult.worker.uid,
              owner: user.uid,
              timeLine: [dropResult.timeline],
              month: dropResult.startDate.getMonth(),
              day: dropResult.startDate.getDate(),
              dateCreate: dropResult.startDate,
            };
          }

          console.log("updatedOrder", updatedOrder);
          // TODO: удалить если разные месяца
          try {
            const companyOrdersRefNew = doc(
              fbServices.firestoreDb,
              `orders/${orderView.uid}`
            );
            const res = await setDoc(companyOrdersRefNew, updatedOrder);
            await getNotWorkersOrders()
            setMsg("Заказ перенесен");

            const admin =
                companyInfo.email &&
                (await firebase.getDocItem("users", companyInfo.email));
            const ownerToken = admin?.notifToken;
            const technicianToken = technicians.find(
                (technician) => technician.uid === updatedOrder?.workerUid
            );

            await fbServices.sendPush({
              notification: {
                title: `Order ${updatedOrder.number} is rescheduled`,
                body: `You have message about order from the company ${
                    companyInfo?.company || ""
                } order ${updatedOrder.number} was rescheduled ${user.displayName}`,
              },
              token: technicianToken ? technicianToken.notifToken : ownerToken,
            })

            // отправить push
            // await sendPush({
            //   token:
            //       orderView?.timeLine?.length && technicianToken
            //           ? technicianToken.notifToken
            //           : ownerToken,
            //   title: `Order ${orderForBase.number} is ${
            //       orderView && typeof orderView !== "string"
            //           ? "Update"
            //           : "Create"
            //   }`,
            //   message: `You have received an order from the company ${
            //       companyInfo?.company || ""
            //   }`,
            // });

          } catch (error) {
            console.log("Перенос-error", error);
            setMsg(error.message);
          } finally {
            console.log("finally-dropResult", dropResult);
            dropResult.setInit(!dropResult.init);
          }
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    };
  });

  const opacity = isDragging ? 0.4 : 1;

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (target) => {
    setAnchorEl(target);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const callbackClick = (e) => {
    orderView && typeof orderView !== "string" && handlePopoverOpen(e);
  };

  const callbackDoubleClick = (e) => {
    orderView && typeof orderView !== "string" && setOpenDialog(true);
  };

  const onClick = useSingleAndDoubleClick(callbackClick, callbackDoubleClick);

  const fieldsHandler = () => {
    if (curentLocation.pathname.includes("payments")) {
      return paymantsFields;
    } else if (curentLocation.pathname.includes("parts")) {
      return partsFields;
    } else {
      return ordersFields;
    }
  };

  const fieldsPopoverHandler = () => {
    if (curentLocation.pathname.includes("payments")) {
      return paymantsPopoverFields;
    } else if (curentLocation.pathname.includes("parts")) {
      return partsPopoverFields;
    } else {
      return ordersPopoverFields;
    }
  };

  const itemCell = () => {
    if (isPeriodLine) {
      return null
    }
    if (orderView && typeof orderView !== "string") {
      return (
          <OrderCell
              orderView={orderView}
              fields={fieldsHandler()}
              drag={curentLocation.pathname === "/" ? drag : null}
              style={
                curentLocation.pathname === "/" ? { ...style, opacity } : {}
              }
          />
      )
    }
    if (curentLocation.pathname === "/") {
      return <ClearItem setOpenDialog={setOpenDialog} {...props} />
    }
    return null
  }

  return (
    <>
      {isOpenDialog ? (
        <AddOrderDialog
          {...props}
          isOpenDialog={isOpenDialog}
          setOpenDialog={setOpenDialog}
        />
      ) : null}
      <Paper
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseLeave={handlePopoverClose}
        onClick={(e) => {
          orderView.company === companyInfo.company &&
            onClick(e, e.currentTarget);
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: widthItem,
          overflow: "hidden",
          justifyContent: "center",
          border: orderView && typeof orderView !== "string" ? 0.5 : 0,
          borderColor: grey[700],
          // TODO контроль статусов по цвету
          backgroundColor: statusColors[orderView?.status],
          // minWidth: widthItem,
          width: isPeriodLine ? 0 : widthItem,
        }}
      >
        {itemCell()}
      </Paper>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {orderView && (
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              justifyContent: "center",
              minWidth: 250,
              border: 0.5,
              // TODO контроль статусов по цвету
              backgroundColor: statusColors[orderView?.status],
            }}
          >
            <OrderCell
              orderView={orderView}
              fields={fieldsPopoverHandler()}
              drag={curentLocation.pathname === "/" ? drag : null}
              style={
                curentLocation.pathname === "/" ? { ...style, opacity } : {}
              }
            />
          </Paper>
        )}
      </Popover>
    </>
  );
};

export default WorkingItem;

const ClearItem = (props) => {
  const curentLocation = useLocation();

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "box",
    drop: () => props,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));
  const isActive = canDrop && isOver;
  let backgroundColor = "#F2f2f2"; // "#F2f2f2"
  if (isActive) {
    backgroundColor = "darkgreen";
  } else if (canDrop) {
    backgroundColor = "darkkhaki";
  }

  if (!curentLocation.pathname === "/") {
    return (
      <Box
    style={{backgroundColor}}
    data-testid="dustbin"
    color="inherit"
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      border: 1,
      borderColor: grey[300],
    }}
    />
    );
  }

  return (
    <Box
      ref={drop}
      style={{ backgroundColor }}
      data-testid="dustbin"
      color="inherit"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
      onClick={() => props.setOpenDialog(true)}
    >
      <AddBoxTwoToneIcon sx={{ opacity: 0.3 }} />
    </Box>
  );
};

const OrderCell = ({ orderView, fields = [], drag = null, style = {} }) => {
  return (
    <Box ref={drag} style={{ ...style }} data-testid={`box`} sx={{ m: 1 }}>
      {fields.map((field) => {
        // TODO: mobx ругется на возможность выхода из массива, отключить в настройках мобх
        if (orderView && Array.isArray(orderView[field.value])) {
          return (
            <div key={field.name + field.value}>
              <Typography sx={{ fontSize: 10 }} noWrap>
                {field.name}:{" "}
                {(orderView[field.value] &&
                  orderView[field.value][0] &&
                  orderView[field.value][0]?.value) ||
                  ""}
              </Typography>
              <Divider />
            </div>
          );
        } else {
          return (
            <div key={field.name + field.value}>
              <Typography sx={{ fontSize: 10 }} noWrap>
                {field.name}: {orderView[field.value]}
              </Typography>
              <Divider />
            </div>
          );
        }
      })}
    </Box>
  );
};

const paymantsFields = [
  { name: "Parts", value: "priceParts" },
  { name: "TAX", value: "tax" },
  { name: "Labor", value: "labor" },
  { name: "Total", value: "total" },
];

const paymantsPopoverFields = [
  { name: "Parts", value: "priceParts" },
  { name: "Labor", value: "labor" },
  { name: "Total", value: "total" },
  { name: "Brand", value: "brend" },
  { name: "Type", value: "type" },
];

const ordersFields = [
  { name: "Brand", value: "brend" },
  { name: "Type", value: "type" },
  { name: "Address", value: "adress" },
  { name: "Descr", value: "jobDescription" },
];

const ordersPopoverFields = [
  { name: "ORDER", value: "number" },
  { name: "Brand", value: "brend" },
  { name: "Type", value: "type" },
  { name: "Address", value: "adress" },
  { name: "Descr", value: "description" },
];

const partsFields = [
  { name: "Parts", value: "priceParts" },
  { name: "Total", value: "total" },
  { name: "Status", value: "status" },
];

const partsPopoverFields = [
  { name: "Brand", value: "brend" },
  { name: "Parts", value: "priceParts" },
  { name: "SN", value: "serialNumber" },
];
