import {Grid, TextField} from "@mui/material";
import firebase from "../../services/firebase";
import fbServices from "../../services/firebase";
import {dataBaseName} from "../../constants";
import {useStores} from "../../store";
import {useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";

const WorkerSalary = ({managerItem}) => {
    const {
        snackbarStore: { setMsg },
        companyInfoStore: { companyInfo, setCompanyInfoStore },
    } = useStores();

    const workerData = companyInfo.workersData && companyInfo.workersData[managerItem.uid]
    const [salary, setSalary] = useState(workerData?.salary || 40)
    const [isLoading, setIsLoading] = useState(false)

    const handleSalary = async () => {
        setIsLoading(true)
        try {
            await firebase.updateDocItem("companies", companyInfo.company, {
                [`workersData.${managerItem.uid}.salary`]: salary
            });
            await updateCompanyInfo();
        } catch (e) {
            console.log('ERROR-handleSalary', e)
            setMsg(e.message);
        }
        setIsLoading(false)

    }

    const updateCompanyInfo = async () => {
        const companyInfoSnap = await fbServices.getDocItem(
            dataBaseName.COMPANIES,
            companyInfo.company,
        );
        setCompanyInfoStore(companyInfoSnap)
    }
    return (
        <>
            <Grid key={'salary'} item xs={2}>
                <TextField
                    fullWidth
                    id={'salary'}
                    label={'%'}
                    variant="outlined"
                    size="small"
                    value={salary}
                    onChange={(event) => setSalary(event.target.value)}
                />
            </Grid>
            <Grid key={'UPDATE'} item xs={2}>
                <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    fullWidth
                    onClick={async () => {
                        await handleSalary()
                    }}
                >
                    UPDATE %
                </LoadingButton>
            </Grid>
        </>
    )
}

export default WorkerSalary
