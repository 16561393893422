import { observer } from "mobx-react-lite";
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
} from "@mui/material";
import { useStores } from "../../store";
import IconButton from "@mui/material/IconButton";
import { queryResolver } from "../../services/queryResolver";
import SearchIcon from "@mui/icons-material/Search";
import firebase from "../../services/firebase";
import { useEffect, useState } from "react";
// import React from "@types/react";
import { MoonLoader } from "react-spinners";
import { cloneDeep, isEqual } from "lodash";
import delIcon from './del_alt_fill.svg'
import AddOrderDialog from "../AddOrder";

const Stocks = observer((props) => {
  const {
    userStore: { user, setUser, changeUserInfo },
    companyInfoStore: { companyInfo },
    ordersStore: { orders, setorders, updateOrders },
    techniciansStore: { technicians },
  } = useStores();

  // console.log('1', "2023-09-02T14:23:23.000Z")
  // console.log('2', new Date('2023-09-02T14:23:23.000Z').getMonth())

  const [stokList, setStokList] = useState([]);
  const [isAddItem, setIsAddItem] = useState(false);
  const [originalStock, setOriginalStock] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [stockForSave, setStockForSave] = useState([]);
  const [stockForDelete, setStockForDelete] = useState([]);
  const [filters, setFilters] = useState({
    spaces: companyInfo.company,
    value: null,
  });
  const [searchValue, setSearchValue] = useState("");

  const setStoksList = async () => {
    await updateStock();
    const allStok = await firebase.getInerCollection(
      "companies",
      companyInfo.company,
      "stock"
    );
    const cloneStock = cloneDeep(allStok);
    setOriginalStock(cloneStock);
    setStokList(allStok.sort((a, b) => b.dateCreate - a.dateCreate));
  };

  const updateStoksList = (item) => {
    const modifiedArray = stokList.map((obj) => {
      if (obj.id === item.id) {
        // Найден объект, который нужно обновить
        return { ...obj, ...item }; // Создание нового объекта с обновленными значениями
      }
      return obj; // Возврат неизмененного объекта
    });

    const changedObjects = modifiedArray.filter((modifiedObj) => {
      const originalObj = originalStock.find(
        (originalObj) => originalObj.id === modifiedObj.id
      );
      return !isEqual(originalObj, modifiedObj);
    });
    setIsChanged(!!changedObjects.length);
    setStockForSave(!!changedObjects.length ? changedObjects : []);

    setStokList(modifiedArray);
  };

  useEffect(() => {
    setStoksList();
  }, []);

  const addStockItem = async () => {
    setIsAddItem(true);
    try {
      await firebase.setInerCollection(
        "companies",
        companyInfo.company,
        "stock",
        {
          //   id: "",
          dateCreate: new Date(),
          name: "",
          partsNumber: "",
          price: "",
          sell: '',
          isDeletePart: false,
          positionQuantity: {
            office: 0,
          },
        }
      );
      await setStoksList();
    } catch (error) {
      console.log("ERROR-setInerCollection", error);
    }

    setIsAddItem(false);
  };

  const updateStock = async () => {
    if (!!stockForSave.length) {
      setIsAddItem(true);
      try {
        for (const item of stockForSave) {
          if (item.isDeletePart) {
            await firebase.deleteInerItem(
              "companies",
              companyInfo.company,
              "stock",
              item
            );
          } else {
            await firebase.updateInerItem(
              "companies",
              companyInfo.company,
              "stock",
              item
            );
          }
        }
      } catch (error) {
        console.log("ERROR-updateStock", error);
      }
      setIsChanged(false);
      setIsAddItem(false);
    }
  };

  return (
    <Box sx={{ m: 1, mt: 9, display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          minWidth: "70%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            borderWidth: 1,
            borderColor: "black",
            borderStyle: "solid",
            padding: 4,
            borderRadius: 5,
            width: "100%",
            textAlign: "center",
            marginBottom: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          STOCK:
          <FormControl style={{ minWidth: 300, marginLeft: 10 }} size="small">
            <InputLabel id="company-label">company</InputLabel>
            <Select
              fullWidth
              labelId="company-label"
              id="company"
              value={filters.spaces}
              label="company"
              onChange={(e) => {
                setFilters((prev) => ({ ...prev, spaces: e.target.value }));
              }}
            >
              {[
                { displayName: companyInfo.company, uid: companyInfo.company },
                ...technicians,
              ].map((worker) => {
                return (
                  <MenuItem key={worker.uid} value={worker.displayName}>
                    {worker.displayName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <Grid container spacing={1} columns={12} sx={{ mb: 1 }}>
          <Grid item md={5}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel htmlFor={"search"}>Search</InputLabel>
              <OutlinedInput
                // disabled={isDisabled}
                id={"search"}
                fullWidth
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={async (e) => {
                        // if (isSearch) {
                        //     const srRsaultPhoneNumber = await queryResolver(
                        //         "orders",
                        //         "adress",
                        //         order.adress
                        //     );
                        //     setSearchRsault(
                        //         srRsaultPhoneNumber.filter(
                        //             (item) => companyInfo.company === item.company
                        //         )
                        //     );
                        //     setOpenSelectionDialog(true);
                        // }
                      }}
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label={"123"}
              />
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <div
              style={{
                border: "1px solid black",
                height: 40,
                borderRadius: 3,
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={addStockItem}
            >
              {isAddItem ? <MoonLoader color="blue" size={20} /> : "+ PART"}
            </div>
          </Grid>
          <Grid item md={4}>
            <div
              onClick={updateStock}
              style={{
                border: "1px solid black",
                height: 40,
                borderRadius: 3,
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: isChanged ? "pointer" : "default",
                background: isChanged ? "green" : "grey",
              }}
            >
              {isAddItem ? <MoonLoader color="blue" size={20} /> : "UPDATE"}
            </div>
          </Grid>
        </Grid>

        {stokList
          .filter((stokItem) => {
            if (searchValue === "") return true;
            if (
              stokItem.name.includes(searchValue) ||
              stokItem.partsNumber.includes(searchValue)
            ) {
              return true;
            }
            return false;
          })
          // .sort((a, b) => {
          //   if (a.name === "" && b.name !== "") {
          //     return -1; // Первый объект с пустым полем должен быть раньше
          //   } else if (a.name !== "" && b.name === "") {
          //     return 1; // Второй объект с пустым полем должен быть раньше
          //   } else {
          //     return 0; // Оставляем объекты в текущем порядке
          //   }
          // })
          .map((stokItem, index) => {
            if (stokItem.isDeletePart) {
              return null;
            }

            return (
              <div key={index}>
                <StockItem
                  stokItem={stokItem}
                  index={index}
                  updateStoksList={updateStoksList}
                  filters={filters}
                />
              </div>
            );
          })}
      </Box>
    </Box>
  );
});

export default Stocks;

const StockItem = ({ stokItem, index, updateStoksList, filters }) => {
  const {
    techniciansStore: { technicians },
    companyInfoStore: { companyInfo },
    ordersStore: {orders},
  } = useStores();

  const [isLoading, setIsLoading] = useState(false);
  const [searchItem, setSearchItem] = useState(null)

  const updateItem = (fieldName, value) => {
    updateStoksList({ ...stokItem, [fieldName]: value });
  };

  const divStyleBlock = {
    border: "1px solid black",
    height: 40,
    borderRadius: 4,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "rgb(118, 118, 118)",
  };

  return (
    <>
      {filters.spaces === companyInfo.company && (
        <Grid container spacing={1} columns={23} sx={{ mb: 1 }}>
          <Grid item md={1}>
            <div style={divStyleBlock}># {index + 1}</div>
          </Grid>
          <Grid item md={4}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel htmlFor={"PARTS"}>PARTS</InputLabel>
              <OutlinedInput
                id={"PARTS"}
                fullWidth
                value={stokItem.name}
                onChange={(e) => {
                  updateItem("name", e.target.value);
                }}
                label={"PARTS"}
              />
            </FormControl>
          </Grid>
          <Grid item md={4}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel htmlFor={"PARTS NUMBER"}>PARTS NUMBER</InputLabel>
              <OutlinedInput
                id={"PARTS NUMBER"}
                fullWidth
                value={stokItem.partsNumber}
                onChange={(e) => {
                  updateItem("partsNumber", e.target.value);
                }}
                label={"PARTS NUMBER"}
              />
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel htmlFor={"BUY"}>BUY</InputLabel>
              <OutlinedInput
                id={"BUY"}
                fullWidth
                value={stokItem.price}
                onChange={(e) => {
                  updateItem("price", e.target.value);
                }}
                label={"BUY"}
              />
            </FormControl>
          </Grid>

          <Grid item md={3}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel htmlFor={"SALE"}>SALE</InputLabel>
              <OutlinedInput
                id={"SALE"}
                fullWidth
                //TODO: sell заменить на salle в 2х местах
                value={stokItem.sell}
                onChange={(e) => {
                  updateItem("sell", e.target.value);
                }}
                label={"SALE"}
              />
            </FormControl>
          </Grid>

          <Grid item md={1}>
            <div
              onClick={(e) =>
                stokItem.positionQuantity?.office > 0 &&
                updateItem("positionQuantity", {
                  ...(stokItem.positionQuantity || {}),
                  office: (stokItem.positionQuantity?.office || 0) - 1,
                })
              }
              style={{ ...divStyleBlock, cursor: "pointer" }}
            >
              -
            </div>
          </Grid>
          <Grid item md={1}>
            <div style={divStyleBlock}>
              {stokItem.positionQuantity?.office || 0}
            </div>
          </Grid>

          <Grid item md={1}>
            <div
              onClick={(e) =>
                updateItem("positionQuantity", {
                  ...(stokItem.positionQuantity || {}),
                  office: 1 + (stokItem.positionQuantity?.office || 0),
                })
              }
              style={{ ...divStyleBlock, cursor: "pointer" }}
            >
              +
            </div>
          </Grid>
          <Grid item md={4}>
            <FormControl fullWidth size="small">
              <InputLabel id="office-select-label">spaces</InputLabel>
              <Select
                fullWidth
                labelId="office-select-label"
                id="office-select"
                value={"office"}
                label="office"
                onChange={(e) => {
                  stokItem.positionQuantity?.office > 0 &&
                    updateItem("positionQuantity", {
                      ...(stokItem.positionQuantity || {}),
                      office: (stokItem.positionQuantity?.office || 0) - 1,
                      [e.target.value]:
                        (stokItem.positionQuantity[e.target.value] || 0) + 1,
                    });
                }}
              >
                {[{ displayName: "office", uid: "office" }, ...technicians].map(
                  (worker) => {
                    return (
                      <MenuItem key={worker.uid} value={worker.displayName}>
                        {worker.displayName}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={1}>
            <div
              onClick={(e) => {
                updateItem("isDeletePart", true);
              }}
              style={{ ...divStyleBlock, cursor: "pointer" }}
            >
              <img src={delIcon} />
            </div>
          </Grid>
        </Grid>
      )}

      {Object.keys(stokItem.positionQuantity).map((position) => {
        if (position === "office") {
          return null;
        }
        if (
          filters.spaces === companyInfo.company ||
          filters.spaces === position
        ) {
          return (
            <Grid
              key={position + index}
              container
              spacing={1}
              columns={23}
              sx={{ mb: 1 }}
            >
              <Grid item md={1}>
                <div style={divStyleBlock}># {index + 1}</div>
              </Grid>
              <Grid item md={4}>
                <div
                  style={{
                    ...divStyleBlock,
                    paddingLeft: 14,
                    justifyContent: "start",
                    overflow: "hidden",
                  }}
                >
                  {stokItem.name}
                </div>
              </Grid>
              <Grid item md={4}>
                <div
                  style={{
                    ...divStyleBlock,
                    paddingLeft: 14,
                    justifyContent: "start",
                    overflow: "hidden",
                  }}
                >
                  {stokItem.partsNumber}
                </div>
              </Grid>

              <Grid item md={3}>
                <div
                  style={{
                    ...divStyleBlock,
                    paddingLeft: 14,
                    justifyContent: "start",
                    overflow: "hidden",
                  }}
                >
                  {stokItem.price}
                </div>
              </Grid>

              <Grid item md={3}>
                <div
                  style={{
                    ...divStyleBlock,
                    paddingLeft: 14,
                    justifyContent: "start",
                    overflow: "hidden",
                  }}
                >
                  {stokItem.sell}
                </div>
              </Grid>

              <Grid item md={1}>
                <div
                  onClick={() =>
                    stokItem.positionQuantity?.[position] > 0 &&
                    updateItem("positionQuantity", {
                      ...(stokItem.positionQuantity || {}),
                      [position]:
                        (stokItem.positionQuantity?.[position] || 0) - 1,
                      office: (stokItem.positionQuantity?.office || 0) + 1,
                    })
                  }
                  style={{ ...divStyleBlock, cursor: "pointer" }}
                >
                  -
                </div>
              </Grid>
              <Grid item md={1}>
                <div style={divStyleBlock}>
                  {stokItem.positionQuantity?.[position] || 0}
                </div>
              </Grid>

              <Grid item md={1}>
                <div
                  onClick={(e) =>
                    stokItem.positionQuantity?.office > 0 &&
                    updateItem("positionQuantity", {
                      ...(stokItem.positionQuantity || {}),
                      [position]:
                        1 + (stokItem.positionQuantity?.[position] || 0),
                      office: (stokItem.positionQuantity?.office || 0) - 1,
                    })
                  }
                  style={{ ...divStyleBlock, cursor: "pointer" }}
                >
                  +
                </div>
              </Grid>
              <Grid item md={4}>
                {/* {isLoading ? <MoonLoader color="blue" size={20} /> : ( */}
                  <div onClick={async() => {
                    setIsLoading(true)
                    try {
                      const searchItem = await firebase.getItemInCollection('orders', 'number', position)
                      setSearchItem(searchItem[0])
                    } catch (error) {
                      console.log('getSearchOrderItem-ERROR', error);
                    }
                    setIsLoading(false)
                  }} style={{...divStyleBlock, cursor: 'pointer'}}>{position}</div>
                {/* )} */}

              </Grid>

              {searchItem ? (
                <AddOrderDialog
                  isOpenDialog={searchItem}
                  setOpenDialog={() => setSearchItem(null)}
                  orderView={searchItem}
                />
              ) : null}

              <Grid item md={1}>
                <div
                  onClick={(e) => {
                    const updatedObj = {
                      ...(stokItem.positionQuantity || {}),
                    };
                    delete updatedObj[position];
                    updateItem("positionQuantity", updatedObj);
                  }}
                  style={{ ...divStyleBlock, cursor: "pointer" }}
                >
                  <img src={delIcon} />
                </div>
              </Grid>
            </Grid>
          );
        }
        return null;
      })}
    </>
  );
};

const DenisIndastris = {
  startWT: "8.00",
  workers: {
    "den@gmail.com": "admin",
    "3@3.com": "manager",
    "pic@gmail.com": "technician",
    "1@1.com": "manager",
  },
  firstName: "Den2",
  tax: "8%",
  adminAsTechnician: true,
  confirmPassword: "111111",
  companyZip: "230000",
  endWT: "17.00",
  infoBlock: {
    1: {
      name: "1",
      isOn: true,
      priority: "Medium",
      description: "2222",
      photos: [],
      create: {
        seconds: 1673565505,
        nanoseconds: 181000000,
      },
    },
    medium1: {
      photos: [],
      priority: "Medium",
      create: {
        seconds: 1673630992,
        nanoseconds: 483000000,
      },
      name: "medium1",
      isOn: true,
      description: "medium1",
    },
    h1: {
      isOn: true,
      photos: [],
      description: "h1",
      priority: "High",
      create: {
        seconds: 1673631144,
        nanoseconds: 744000000,
      },
      name: "h1",
    },
    Ghbdtn: {
      isOn: false,
      priority: "Low",
      description: "123",
      name: "Ghbdtn",
      photos: [],
      create: {
        seconds: 1673630112,
        nanoseconds: 549000000,
      },
    },
  },
  worksPerHour: 1,
  password: "111111",
  company: "Denis Indastris",
  phoneNumber: "+375295878887",
  lastName: "Minz2",
  email: "den@gmail.com",
  timesLine: ["8.00", "9.00", "10.00", "11.00", "15.00", "16.00"],
  companyAddress: "Grodno",
};
