import React, { useEffect, useState, Fragment, useRef, forwardRef } from "react";
import {
    Button,
    Paper,
    Grid,
    Typography,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Container,
    TextField, InputLabel, Select, MenuItem, FormControl,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { observer } from "mobx-react-lite";
import {
    collection,
    addDoc,
    doc,
    getDocs,
    getDoc,
    query,
    where,
} from "firebase/firestore";
import { useStores } from "../../store";
import fbServices from "../../services/firebase";
import LoadingComponent from "../../Common/LoadingComponent";
import DatePicker, { format } from "react-datepicker";
import MyDatePicker from "../AddOrder/MyDatePicker";
import WorkerAvatar from "./WorkerAvatar";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import IconButton from "@mui/material/IconButton";
import WorkingItem from "./WorkingItem";
import {
    filterDoc,
    getMoreDate,
    queryMultyResolver,
    queryResolver,
} from "../../services/queryResolver";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import WorkerLine from "./WorkerLine";
import moment from "moment";
import { brown } from "@mui/material/colors";

const PaymentsPeriodLine = ({ dayCount, dayCountAfter, addDayToCurrentDateBefore, addDayToCurrentDate, techniciansFilter }) => {
    const {
        userStore: { user, setUser, changeUserInfo },
        snackbarStore: { open, msg, setMsg, active, desactive },
        // workersStore: { workers, setWorkers },
        ordersStore: { orders, setorders, updateOrders },
        managersStore: { managers, setManagersStore },
        techniciansStore: { technicians, setTechniciansStore },
        companyInfoStore: { companyInfo, setCompanyInfoStore },
    } = useStores();

    const payStartDay = moment(addDayToCurrentDateBefore((dayCountAfter * (-1)))).startOf('day')
    const payEndDay = moment(addDayToCurrentDate(dayCount - 1)).endOf('day')

    const periodOrders = orders.filter((order) => {
        const eventStartDate = moment(order.dateCreate.toDate())
        const isBetweenDay = eventStartDate.isBetween(payStartDay, payEndDay, null, '[]')
        return isBetweenDay;
    }).map((order) => {
        return order
    })

    const [isLoading, setIsLoading] = useState(false);

    return (
        <div>
            <div style={{ marginTop: 20, marginBottom: 10, padding: 1, fontWeight: 'bold', textAlign: 'center', background: brown[200] }}>
                TOTAL FOR SELECTED PERIOD
            </div>
            <div
                style={{ marginTop: 10, marginBottom: 5, padding: 1, fontWeight: 'bold', textAlign: 'center', background: brown[200] }}
            >TECH. PAYMENT FORMULA: (((deposit+payment1+payment2)*tax-tech parts-offis pats)/100×tech procent)+tech part</div>
            <div
                style={{ marginTop: 5, marginBottom: 20, padding: 1, fontWeight: 'bold', textAlign: 'center', background: brown[200] }}
            >TOTAL PAYMENT FORMULA:  (deposit+payment1+payment2)+tax</div>

            {technicians.filter((tech) => {
                if (techniciansFilter.worker === '') return true
                return techniciansFilter.worker === tech.displayName;
            }).map((technician) => {
                return (
                    <WorkerLine
                        key={technician.uid}
                        worker={technician}
                        startDate={new Date()}
                        workOrders={periodOrders.filter((order) => order.worker === technician.displayName)}
                        // setInit={setInit}
                        // init={init}
                        isPeriodLine
                    />
                )
            })}
        </div>
    )
}

export default PaymentsPeriodLine;
