import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  Typography,
  IconButton,
  AppBar,
  Badge,
  CssBaseline,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import fbServices from "./services/firebase";
import SignInSide from "./screens/Auth/SignInSide";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Snackbar } from "@mui/material";
import Box from "@mui/material/Box";
import { collection, addDoc, doc, getDocs, getDoc } from "firebase/firestore";
import { useStores } from "./store";
import Workers from "./screens/Workers";
import LoadingComponent from "./Common/LoadingComponent";
import Toolbar from "@mui/material/Toolbar";
import EventNoteIcon from "@mui/icons-material/EventNote";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import PaymentsIcon from "@mui/icons-material/Payments";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import SettingsIcon from "@mui/icons-material/Settings";
import Settings from "./screens/Settings";
import Maps from "./screens/Maps";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Payments from "./screens/Payments";
import { dataBaseName } from "./constants";
import { getCompanyWorkers } from "./services/getCompanyWorkersService";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import Wiki from "./screens/Wiki";
import Stocks from "./screens/Stoks";
import SuperAdmin from "./screens/SuperAdmin";
import { Api } from "./screens/Api";

const App = observer(() => {
  const {
    userStore: { user, setUser, changeUserInfo },
    snackbarStore: { setMsg },
    workersStore: { workers, setWorkers },
    companyInfoStore: { companyInfo, setCompanyInfoStore },
    managersStore: { managers, setManagersStore },
    techniciansStore: { technicians, setTechniciansStore },
  } = useStores();
  const [isLoading, setIsLoading] = useState(true);

  const getCompanyInfo = async (authUser) => {
    const companiJob = authUser.companiesJobs;

    try {
      if (!companiJob || !Object.keys(companiJob).length) {
        throw new Error(`You don't work for our companies`);
      }

      const admin = Object.keys(companiJob).filter(
        (compani) => companiJob[compani] === "admin"
      );
      const manager = Object.keys(companiJob).filter(
        (compani) => companiJob[compani] === "manager"
      );

      const companyInfoSnap = await fbServices.getDocItem(
        dataBaseName.COMPANIES,
        admin[0] ?? manager[0]
      );

      if (companyInfoSnap) {
        setCompanyInfoStore(companyInfoSnap);
      }
    } catch (error) {
      console.log("getCompanyInfo-error", error);
      setMsg(error.message);
      setCompanyInfoStore({});
      fbServices.signOut();
    }
  };

  const checkUser = async (user) => {
    if (!user) {
      setUser({});
      setIsLoading(false);
      return;
    }
    const { displayName, photoURL, uid, email } = user;
    try {
      const authUser = await fbServices.getDocItem("users", email);

      authUser && setUser(authUser);
      authUser && (await getCompanyInfo(authUser));
    } catch (error) {
      console.log("error", error); // TODO: настроить ошибку для показа что нет роли
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);

    if (!user.name) {
      fbServices.onAuthStateChanged(checkUser);
    }
  }, []);

  useEffect(() => {
    if (companyInfo.company) {
      getCompanyWorkers(
        companyInfo,
        setTechniciansStore,
        setManagersStore,
        setMsg
      );
    }
  }, [companyInfo]);

  const workersAccess = companyInfo.workersData && companyInfo.workersData[user.uid]

  return isLoading ? (
    <LoadingComponent />
  ) : (
    <BrowserRouter>
      <SnackbarMessage />
      {user?.displayName && companyInfo.company ? (
        <>
          <MainAppBar user={user} />
          <Routes>
            <Route path="/" element={<Workers />} />
            {workersAccess?.settings ? null : (<Route path="/settings" element={<Settings />} />)}
            {/*<Route path="/maps" element={<Maps />} />*/}
            {workersAccess?.payments ? null : (<Route path="/payments" element={<Workers />} />)} />)}
            {/*<Route path="/parts" element={<Workers />} />*/}
            {workersAccess?.stock ? null : (<Route path="/stocks" element={<Stocks />} />)}
            {/*<Route path="/wiki" element={<Wiki />} />*/}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/api" element={<Api />} />
            {user.email === 'kiber888@gmail.com' ? (
              <Route path="/superAdmin" element={<SuperAdmin />} />
            ) : null}
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="/" element={<SignInSide />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<SignInSide />} />
        </Routes>
      )}
    </BrowserRouter>
  );
});

// /privacy-policy

export default App;

const SnackbarMessage = observer(() => {
  const {
    userStore: { user, setUser, changeUserInfo },
    snackbarStore: { open, msg, setMsg, active, desactive },
    workersStore: { workers, setWorkers },
    managersStore: { managers, setManagersStore },
    techniciansStore: { technicians, setTechniciansStore },
  } = useStores();

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        desactive();
      }, 4000);
    }
  }, [open]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      onClose={() => desactive()}
      message={msg}
      key={msg}
      TransitionComponent={Slide}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          sx={{ p: 0.5 }}
          onClick={desactive}
        >
          <CloseIcon />
        </IconButton>
      }
    />
  );
});

const MainAppBar = ({ user }) => {
  // console.log('MainAppBar', user);
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("location", location);

  const {
    companyInfoStore: { companyInfo },
  } = useStores();

  const workersAccess = companyInfo.workersData && companyInfo.workersData[user.uid]

  if (!user?.companiesJobs) {
    return null;
  }

  const isAdmin = Object.values(user.companiesJobs)?.includes("admin");

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
      //  position="absolute"
      >
        <Toolbar>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            COMPANY: {user.company}
          </Typography>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            NAME: {user.displayName}
          </Typography>
          <IconButton
            sx={{ color: location.pathname === "/" ? "red" : "white" }}
            color="inherit"
            onClick={() => {
              navigate("/");
            }}
          >
            orders
            <EventNoteIcon />
          </IconButton>
          {/*<IconButton*/}
          {/*  sx={{ color: location.pathname === "/maps" ? "red" : "white" }}*/}
          {/*  color="inherit"*/}
          {/*  onClick={() => {*/}
          {/*    navigate("/maps");*/}
          {/*  }}*/}
          {/*>*/}
          {/*  map*/}
          {/*  <PinMaps*/}
          {/*    color={location.pathname === "/maps" ? "red" : "white"}*/}
          {/*  />*/}
          {/*</IconButton>*/}
          {/* <IconButton
            color="inherit"
            onClick={() => {
              navigate("/calendar");
            }}
          >
            calendar
            <CalendarMonthIcon />
          </IconButton> */}
          {/* <IconButton
            sx={{ color: location.pathname === "/parts" ? "red" : "white" }}
            color="inherit"
            onClick={() => {
              navigate("/parts");
            }}
          >
            parts
            <HomeRepairServiceIcon />
          </IconButton> */}
          {workersAccess?.payments ? null : (
            <IconButton
              sx={{
                color: location.pathname === "/payments" ? "red" : "white",
              }}
              color="inherit"
              onClick={() => {
                navigate("/payments");
              }}
            >
              payments
              <PaymentsIcon />
            </IconButton>
          )}

          {workersAccess?.stock ? null : (
            <IconButton
              sx={{
                color: location.pathname === "/stocks" ? "red" : "white",
              }}
              color="inherit"
              onClick={() => {
                navigate("/stocks");
              }}
            >
              stock
              <PaymentsIcon />
            </IconButton>
          )}

          {/*<IconButton*/}
          {/*  sx={{ color: location.pathname === "/wiki" ? "red" : "white" }}*/}
          {/*  color="inherit"*/}
          {/*  onClick={() => {*/}
          {/*    navigate("/wiki");*/}
          {/*  }}*/}
          {/*>*/}
          {/*  wiki*/}
          {/*  <LiveHelpIcon />*/}
          {/*</IconButton>*/}
          {workersAccess?.settings ? null : (
            <IconButton
              sx={{
                color: location.pathname === "/settings" ? "red" : "white",
              }}
              color="inherit"
              onClick={() => {
                navigate("/settings");
              }}
            >
              settings
              <SettingsIcon />
            </IconButton>
          )}

          {user.email === 'kiber888@gmail.com' && !workersAccess?.superAdmin ? (
            <IconButton
              sx={{
                color: location.pathname === "/superAdmin" ? "red" : "white",
              }}
              color="inherit"
              onClick={() => {
                navigate("/superAdmin");
              }}
            >
              SA
              {/*<SettingsIcon />*/}
            </IconButton>
          ) : null}

          {workersAccess?.apiPage ? null : (
            <IconButton
              sx={{
                color: location.pathname === "/api" ? "red" : "white",
              }}
              color="inherit"
              onClick={() => {
                navigate("/api");
              }}
            >
              api
              {/*<SettingsIcon />*/}
            </IconButton>
          )}


          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            color="inherit"
            onClick={() => {
              fbServices.signOut();
            }}
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
        {/* <Toolbar>123</Toolbar> */}
      </AppBar>
    </Box>
  );
};

const PinMaps = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color ?? "#fff"}
        fillRule="evenodd"
        d="M12.398 17.804C13.881 17.034 19 14.016 19 9A7 7 0 105 9c0 5.016 5.119 8.035 6.602 8.804a.855.855 0 00.796 0zM12 12a3 3 0 100-6 3 3 0 000 6z"
        clipRule="evenodd"
      ></path>
      <path
        stroke={color ?? "#fff"}
        strokeLinecap="round"
        strokeWidth="2"
        d="M18.062 16.5c.615.456.938.973.938 1.5s-.323 1.044-.938 1.5c-.614.456-1.498.835-2.562 1.098-1.064.263-2.271.402-3.5.402s-2.436-.139-3.5-.402-1.948-.642-2.562-1.098C5.323 19.044 5 18.527 5 18s.323-1.044.938-1.5"
      ></path>
    </svg>
  );
};
