import React from "react";
import UserStore from "./user.store";
import SnackbarStore from "./snackbar.store";
import WorkersStore from "./workers.store";
import OrdersStore from "./orders.store";
import InitScreen from "./initScreen.store";
import CompanyInfoStore from "./companyInfoStore";
import ManagersStore from "./managersStore";
import TechniciansStore from "./techniciansStore";
// import NoteStore from "./note.store";

class RootStore {
  constructor() {
    this.userStore = new UserStore(this);
    this.snackbarStore = new SnackbarStore(this);
    this.workersStore = new WorkersStore(this);
    this.ordersStore = new OrdersStore(this);
    this.initScreenStore = new InitScreen(this);
    this.companyInfoStore = new CompanyInfoStore(this);
    this.managersStore = new ManagersStore(this);
    this.techniciansStore = new TechniciansStore(this)
    // this.noteStore = new NoteStore(this)
  }
}

export const stores = new RootStore();

const StoresContext = React.createContext(stores);

export const useStores = () => React.useContext(StoresContext);
