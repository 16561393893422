import { LoadingButton } from "@mui/lab"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography } from "@mui/material"
import { green, grey } from "@mui/material/colors"
import { useState } from "react"

export const JobFilters = ({ isOpenDialog, setOpenDialog }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [isShowApplianceRepair, setIsShowApplianceRepair] = useState(false)
    const [isShowAirCondition, setIsShowAirCondition] = useState(false)

    const handleApply = () => { }

    return (
        <Dialog
            open={isOpenDialog}
            onClose={() => setOpenDialog(false)}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    JOB FILTERS
                </Box>
            </DialogTitle>
            <DialogContent
                // sx={{minHeight: '200px'}} 
                dividers>
                <Paper

                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        background: green[400],

                    }}
                >
                    <Typography
                        onClick={() => setIsShowApplianceRepair(!isShowApplianceRepair)}
                        sx={{ m: 1, fontSize: 13, width: '100%', textAlign: 'center', cursor: 'pointer' }}
                        gutterBottom
                        variant="button"
                        component="div"
                    >
                        APPLIANCE REPAIR
                    </Typography>
                    {isShowApplianceRepair ? (
                        <>
                            <Box sx={{ border: 1, width: '97%', padding: 1, borderRadius: 1, margin: 1 }}>
                                BRENDS
                                <Box sx={{ display: 'flex' }}>
                                    <Typography
                                        // onClick={() => setIsShowApplianceRepair(!isShowApplianceRepair)}
                                        sx={{ m: 1, fontSize: 13, textAlign: 'center', cursor: 'pointer', border: 1, p: 1, borderRadius: 1 }}
                                        gutterBottom
                                        variant="button"
                                        component="div"
                                    >
                                        BOSH
                                    </Typography>
                                    <Typography
                                        // onClick={() => setIsShowApplianceRepair(!isShowApplianceRepair)}
                                        sx={{ m: 1, fontSize: 13, textAlign: 'center', cursor: 'pointer', border: 1, p: 1, borderRadius: 1 }}
                                        gutterBottom
                                        variant="button"
                                        component="div"
                                    >
                                        WIRPOOL
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ border: 1, width: '97%', padding: 1, borderRadius: 1, margin: 1 }}>
                                TYPE OF JOBS
                                <Box sx={{ display: 'flex' }}>
                                    <Typography
                                        // onClick={() => setIsShowApplianceRepair(!isShowApplianceRepair)}
                                        sx={{ m: 1, fontSize: 13, textAlign: 'center', cursor: 'pointer', border: 1, p: 1, borderRadius: 1 }}
                                        gutterBottom
                                        variant="button"
                                        component="div"
                                    >
                                        REPAIR
                                    </Typography>
                                    <Typography
                                        // onClick={() => setIsShowApplianceRepair(!isShowApplianceRepair)}
                                        sx={{ m: 1, fontSize: 13, textAlign: 'center', cursor: 'pointer', border: 1, p: 1, borderRadius: 1 }}
                                        gutterBottom
                                        variant="button"
                                        component="div"
                                    >
                                        INSTALL
                                    </Typography>
                                </Box>
                            </Box>
                        </>
                    ) : null}
                </Paper>

                <Paper

                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        background: grey[400],

                    }}
                >
                    <Typography
                        onClick={() => setIsShowAirCondition(!isShowAirCondition)}
                        sx={{ m: 1, fontSize: 13, width: '100%', textAlign: 'center', cursor: 'pointer' }}
                        gutterBottom
                        variant="button"
                        component="div"
                    >
                        AIR CONDITION REPAIR
                    </Typography>
                    {isShowAirCondition ? (
                        <>
                            <Box sx={{ border: 1, width: '97%', padding: 1, borderRadius: 1, margin: 1 }}>
                                BRENDS
                                <Box sx={{ display: 'flex' }}>
                                    <Typography
                                        // onClick={() => setIsShowApplianceRepair(!isShowApplianceRepair)}
                                        sx={{ m: 1, fontSize: 13, textAlign: 'center', cursor: 'pointer', border: 1, p: 1, borderRadius: 1 }}
                                        gutterBottom
                                        variant="button"
                                        component="div"
                                    >
                                        123
                                    </Typography>
                                    <Typography
                                        // onClick={() => setIsShowApplianceRepair(!isShowApplianceRepair)}
                                        sx={{ m: 1, fontSize: 13, textAlign: 'center', cursor: 'pointer', border: 1, p: 1, borderRadius: 1 }}
                                        gutterBottom
                                        variant="button"
                                        component="div"
                                    >
                                        456
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ border: 1, width: '97%', padding: 1, borderRadius: 1, margin: 1 }}>
                                TYPE OF JOBS
                                <Box sx={{ display: 'flex' }}>
                                    <Typography
                                        // onClick={() => setIsShowApplianceRepair(!isShowApplianceRepair)}
                                        sx={{ m: 1, fontSize: 13, textAlign: 'center', cursor: 'pointer', border: 1, p: 1, borderRadius: 1 }}
                                        gutterBottom
                                        variant="button"
                                        component="div"
                                    >
                                        REPAIR
                                    </Typography>
                                    <Typography
                                        // onClick={() => setIsShowApplianceRepair(!isShowApplianceRepair)}
                                        sx={{ m: 1, fontSize: 13, textAlign: 'center', cursor: 'pointer', border: 1, p: 1, borderRadius: 1 }}
                                        gutterBottom
                                        variant="button"
                                        component="div"
                                    >
                                        INSTALL
                                    </Typography>
                                </Box>
                            </Box>
                        </>
                    ) : null}
                </Paper>

            </DialogContent>
            <DialogActions>
                {/* <Button onClick={() => setOpenDialog(false)}>FILTER ENABLE</Button> */}
                <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                <LoadingButton loading={isLoading} onClick={handleApply}>APPLY</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}