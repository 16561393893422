import { Box, Typography } from "@mui/material"

export const ApiLeads = () => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                // mb: 1,
                flexWrap: "wrap",
                pr: 2,
          pl: 2,
          borderTop: 1
            }}
        >
            <Typography sx={{ color: 'red' }}>API LEADS</Typography>
        </Box>
    )
}