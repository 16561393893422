import { makeAutoObservable } from "mobx";

class snackbar {
  open = false;
  msg = "";

  constructor() {
    makeAutoObservable(this);
  }

  active = () => {
    this.open = true;
  };

  setMsg = (msg) => {
    this.msg = msg;
    if(!msg) this.open = false;
    if(msg) this.open = true;
  };

  desactive = () => this.open = false;
}

export default snackbar;
