import axios from "axios";
// import {google} from 'googleapis';
// import {JWT} from 'google-auth-library';

const fcmUrl = "https://fcm.googleapis.com/fcm/send";
// const fcmUrl = 'POST https://fcm.googleapis.com/v1/projects/myjobs-b7f54/messages:send'

// const MESSAGING_SCOPE = 'https://www.googleapis.com/auth/firebase.messaging';
// const SCOPES = [MESSAGING_SCOPE];

const apiService = axios.create({
  baseURL: fcmUrl,
  responseType: "json",
});

const sendPush = async ({ token, title, message }) => {
  console.log("sendPush", token, title, message);
  // const accessToken = await getAccessToken();
  //   console.log('accessToken', accessToken)
  return apiService.post(
    "",
    {
      to: token,
      notification: {
        title,
        body: message,
        mutable_content: true,
        sound: "Tri-tone",
      },

      data: {
        url: "google.com",
        dl: "<deeplink action on tap of notification>",
      },
    },
    {
      headers: {
        // this key server firebase cloud
        Authorization:
          "key=AAAAFQYM8dk:APA91bHt3MjXikm676PU_E2z_GUpAcWuFbzmpmKHaEKq89HjKwIIQA-AC-q1BmPP-uH_sZP_ZK-46BA7AFXpgepCx8fmw4VWfl6M4iyhyfRfc0lujSQ2ZShtmzIf0Oj4g1-IWkymF9uB",
        "Content-Type": "application/json",
      },
    }
  );
};

export default sendPush;
